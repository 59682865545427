import React, { useState } from "react";
import "../styles/lounge.css";
import SideBar from "../elements/sidebar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoungeQuestion from "../elements/loungequestion";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { useGetsolveMyQueryQuery } from "../store/General/generalSlice";
import { useGetAllBlogsQuery } from "../store/General/generalSlice";
import { MdOutlineArticle } from "react-icons/md";
import { GetBadgeName, getAchievementSrc } from "../components/helpers";
import { FaLaptopMedical } from "react-icons/fa6";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { useGetLeaderBoardQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import config from "../config/APIEndpoints";
import ScoreBoardBanner from "../components/scoreboardbanner";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const ArrowupRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowupright.svg";
const Caseimg =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/case.png";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";

const LeaderBoardTopIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/leaderboardtop.svg";
const LeaderBoardPlayerIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/leaderboardplayer.svg";
const CrownIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crown.svg";
const liker1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/liker1.svg";
const liker2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/liker2.svg";
const speakericon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/speaker.svg";
const questionimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/question.svg";
const upvoteicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/upvoted.svg";
const downvoteicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/downvote.svg";
const refreshicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/refresh.svg";
const commenticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/comment.svg";
const forwardicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/forward.svg";
const quizicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/quiz.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

export default function Lounge() {
  const Api = config.getEndpoint();
  const { data: scoredata, isLoading: scoreloading } = useGetUserScoreQuery();
  const { uid } = useParams();

  const { data, isLoading: scoreLoading } = useGetsolveMyQueryQuery(uid);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const navigate = useNavigate();
  const [selectTimeline, setSelectTimeline] = useState("Week");

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );

  const {
    data: allBlogs,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetAllBlogsQuery();

  const {
    data: leaderBoardData,
    isSuccess: leaderBoardSuccess,
    isLoading: leaderBoardLoading,
  } = useGetLeaderBoardQuery();
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const toggleScoreboard = () => {
    setIsScoreboardVisible(!isScoreboardVisible);
  };
  return (
    <div className="homemain">
      <SideBar
        selected="Lounge"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Doctor's Lounge"
      />
      <ScoreBoardBanner
        isVisible={isScoreboardVisible}
        onClose={toggleScoreboard}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
              <div
                className="closesidebarbuttonmobileheader"
                onClick={toggleSidebar}
              >
                <img src={GripLine} alt="Arrow" />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Doctors Lounge</h3>
            </div>
          </div>
          <div className="homemainright" onMouseEnter={toggleScoreboard}>
            <button>
              <div className="homemainrightbuttoncontent">
                <img
                  src={getAchievementSrc(data?.score || 0)}
                  alt="Header Badge"
                />
                <span>
                  <b>{GetBadgeName(data?.score)}</b> Badge
                </span>
              </div>
              <div className="headerstreaks">
                <img src={fireicon} alt="Fire" />
                <p>{scoredata?.score}</p>
              </div>
            </button>
            <button>
              <img src={reloadicon} alt="Reload" />
            </button>
          </div>
        </div>
        <div className="loungebodycontent">
          <div className="loungebodycontentleft">
            {" "}
            <div className="dashboardcase">
              <div className="dashboardcaseleft">
                <h2>Case Of the Day</h2>
                <p className="dashboardp">
                  Lorem ipsum dolor sit amet consectetur. Diam elementum sed
                  etiam ultrices aliquet eu
                </p>
                <button
                  onClick={() => {
                    navigate("/cases");
                  }}
                >
                  <p>View Case</p>
                  <img src={ArrowupRight} alt="Link" />
                </button>
              </div>
              <div className="dashboardcaseright">
                <img src={Caseimg} alt="Case" />
              </div>
            </div>
            <div className="loungequestioncard">
              <div className="loungequestioncardtop">
                <img src={liker1} alt="Liked" />
                <img src={liker2} alt="Liked" />
                <p>Adam D’Angelo and Pete Lada upvoted · 2h</p>
              </div>
              <div className="loungecardspeaker">
                <div className="loungecardspeakerleft">
                  <img src={speakericon} alt="Speaker" />
                </div>
                <div className="loungecardspeakerright">
                  <div className="loungecardspeakerrighttop">
                    <h5>Pablo Garces</h5>
                    <h5>·</h5>
                    <button>Follow</button>
                  </div>
                  <div className="loungecardspeakerrightbottom">
                    <p>Product Designer at Quora · Updated Saturday</p>
                  </div>
                </div>
              </div>
              <div className="loungecardquestion">
                <h3>
                  This is a question or post title, what do you want to ask?
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard
                </p>
              </div>
              <img src={questionimage} alt="" className="questionimage" />
              <div className="loungequestioncardtop">
                <img src={liker1} alt="Liked" />
                <img src={liker2} alt="Liked" />

                <p>Adam D’Angelo and Pete Lada upvoted · 2h</p>
              </div>
              <div className="loungecardbottom">
                <div className="loungecardbottomleft">
                  <div className="upvotecontainer">
                    <div className="upvote">
                      <img src={upvoteicon} alt="Upvoted" />
                      <p>151</p>
                    </div>
                    <hr />
                    <div className="downvote">
                      <img src={downvoteicon} alt="Downvote" />
                    </div>
                  </div>
                  <img src={refreshicon} alt="Refresh" />
                  <img src={commenticon} alt="Comment" onClick={togglePopup} />
                  <LoungeQuestion
                    isVisible={isPopupVisible}
                    onClose={togglePopup}
                  />
                </div>
                <div className="loungecardbottomright">
                  <img src={forwardicon} alt="Forward" />
                </div>
              </div>
            </div>
          </div>
          <div className="loungebodycontentright">
            <div className="loungeleaderboard">
              <h4>Leaderboard</h4>
              <div className="loungeleaderboardtimeline">
                <button
                  className={
                    selectTimeline === "All Time" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("All Time")}
                >
                  All Time
                </button>
                <button
                  className={
                    selectTimeline === "Week" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("Week")}
                >
                  Week
                </button>
                <button
                  className={
                    selectTimeline === "Month" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("Month")}
                >
                  Month
                </button>
              </div>
              <div className="leaderboardbodytop">
                {leaderBoardData && leaderBoardData[1] && (
                  <div className="leaderboardbodytopleft">
                    <div className="leaderboardposition">2</div>
                    <div className="leaderboardcircleouter">
                      <div className="leaderboardcircleinner">
                        <img
                          src={
                            leaderBoardData[1]?.profile_pic
                              ? Api + leaderBoardData[1]?.profile_pic
                              : LeaderBoardTopIcon
                          }
                          alt="Profile"
                        />
                      </div>
                    </div>
                    <div className="leaderboardname">
                      Dr.
                      {" " + leaderBoardData[1]?.first_name}
                    </div>
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>
                        {leaderBoardData[1]?.score}
                        Pt
                      </p>
                    </div>
                  </div>
                )}

                {leaderBoardData && leaderBoardData[0] && (
                  <div className="leaderboardbodytopmiddle">
                    <div className="leaderboardcrown">
                      <img src={CrownIcon} alt="Crown" />
                    </div>
                    <div className="leaderboardcircleouter">
                      <div className="leaderboardcircleinner">
                        <img
                          src={
                            leaderBoardData[0]?.profile_pic
                              ? Api + leaderBoardData[0]?.profile_pic
                              : LeaderBoardTopIcon
                          }
                          alt="Profile"
                        />
                      </div>
                    </div>
                    <div className="leaderboardname">
                      Dr.
                      {" " + leaderBoardData[0]?.first_name}
                    </div>
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>
                        {leaderBoardData[0]?.score}
                        Pt
                      </p>
                    </div>
                  </div>
                )}

                {leaderBoardData && leaderBoardData[2] && (
                  <div className="leaderboardbodytopright">
                    <div className="leaderboardposition">3</div>
                    <div className="leaderboardcircleouter">
                      <div className="leaderboardcircleinner">
                        <img
                          src={
                            leaderBoardData[2]?.profile_pic
                              ? Api + leaderBoardData[2]?.profile_pic
                              : LeaderBoardTopIcon
                          }
                          alt="Profile"
                        />
                      </div>
                    </div>
                    <div className="leaderboardname">
                      Dr.
                      {" " + leaderBoardData[2]?.first_name}
                    </div>
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>
                        {leaderBoardData[2]?.score}
                        Pt
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="leaderboardplayers">
                {leaderBoardData &&
                  leaderBoardData
                    ?.filter((_, i) => i > 2)
                    .map((player, index) => {
                      return (
                        <div className="leaderboardplayerelement">
                          <div className="leaderboardplayerleftelement">
                            <div className="leaderboardplayerprofile">
                              <img
                                src={
                                  player?.profile_pic
                                    ? Api + player?.profile_pic
                                    : LeaderBoardPlayerIcon
                                }
                                alt="Profile"
                              />
                              <div className="leaderboardplayerposition">
                                <p>{index + 4}</p>
                              </div>
                            </div>
                            <p>
                              {player?.first_name} {player?.last_name}
                            </p>
                          </div>
                          <div className="leaderboardplayerrightelement">
                            {" "}
                            <div className="leaderboardscore">
                              <img src={fireicon} alt="Streak" />
                              <p>{player?.score} Pt</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div className="quizcase ">
          <div className="dashboardcaseleft quizcaseleft">
            <div className="quizcasetopleft">
              <h4>Get Started with Quiz</h4>
              <p className="dashboardp">
                Lorem ipsum dolor sit amet consectetur. Erat neque ut et quis
                scelerisque gravida tellus. Malesuada quis id quam morbi egestas
                eu aenean. Amet eget lacus.
              </p>
            </div>
            <button
              className="elementselector"
              onClick={() => {
                navigate("/clinical");
              }}
            >
              <p>Start Now!!</p>
            </button>
          </div>
          <div className="dashboardcaseright">
            <img src={quizicon} alt="Case" />
          </div>
        </div>
        <div className="loungebottom">
          {allBlogs &&
            allBlogs?.blogs?.map((blog) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(blog.content, "text/html");
              const firstImage = doc.querySelector("img")?.src || "";
              return (
                <div
                  onClick={() => {
                    navigate(`/blog/${blog.slug}`);
                  }}
                  style={{
                    backgroundImage: `url(${firstImage})`,
                    cursor: "pointer",
                  }}
                  className="loungebottomelement"
                >
                  <span className="blogPreviewIcon">
                    {!firstImage && <FaLaptopMedical size={200} />}
                  </span>
                  <div className="loungebottomelementoverlay">
                    <p>BLOG</p>
                    <h3>{blog.title}</h3>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
