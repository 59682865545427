import Cookies from "js-cookie";

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: Cookies.get("token"),
    refresh: Cookies.get("refresh"),
    user: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { refresh, token } = action.payload;
      state.token = token;
      state.refresh = refresh;
      Cookies.set("token", token, { expires: 55 });
      Cookies.set("refresh", refresh, { expires: 55 });
    },
    setRefresh: (state, action) => {
      const { token, user } = action.payload;
      state.token = token;
      Cookies.set("token", token);
    },
    logOut: (state, action) => {
      Cookies.remove("token");
      Cookies.remove("refresh");
      Object.assign(state, null);
    },
    registerUser: (state, action) => {
      state.user = action.payload;
    },
  },
});
const refreshstateSlice = createSlice({
  name: "refreshstate",
  initialState: true,
  reducers: {
    togglerefreshstate: (state) => !state,
  },
});
export const { togglerefreshstate } = refreshstateSlice.actions;

export const { setCredentials, logOut, setRefresh, registerUser } =
  authSlice.actions;

const authreducer = {
  auth: authSlice.reducer,
  refreshstate: refreshstateSlice.reducer,
};
export default authreducer;
