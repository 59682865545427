import React, { useState } from "react";
const Logo = "https://neuro-gen.s3.amazonaws.com/images/icons/logo.svg";
export default function PortfolioNavbar({ colortheme }) {
  return (
    <div className="portfolionavbar">
      <div className="portfolionavbarleft">
        <img src={Logo} alt="Logo" />
      </div>
      <div
        className={
          colortheme === "blue"
            ? "portfolionavbarcenter portfolionavbarcenterblue"
            : "portfolionavbarcenter"
        }
      >
        <ul>
          <li>Features</li>
          <li>Innovations</li>
          <li>Company</li>
          <li>Pricing</li>
        </ul>
      </div>
      <div className="portfolionavbarright">
        <button>Login</button>
      </div>
    </div>
  );
}
