import React, { useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/research.css";
import HomemainHeaderMobile from "../components/homemainheadermobile";
const articleimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/literaturereview.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";
const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
export default function Symptom() {
  const [result, setResult] = useState(false);
  const handleSumbmit = (e) => {
    e.preventDefault();
    setResult(true);
  };
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  return (
    <div className="homemain">
      <SideBar
        selected="Symptom"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Symptom Checker"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="homemainleftouter">
            <h3>Investigatory Report Based Diagnosis</h3>
            <p>Let us assist you in this</p>
          </div>
        </div>
        <div className="researchbody">
          <div className="researchelement">
            <div className="researchelementleft">
              <form onSubmit={handleSumbmit}>
                <h5>
                  Symptoms, signs, laboratory, radiology, MRI... <br />
                  *Only ONE symptom in MAXIMAL 1 to 4 words:
                </h5>
                <textarea placeholder="ex.: alcalic phosphatase elevation"></textarea>

                {result ? (
                  ""
                ) : (
                  <button type="submit" className="generatebutton">
                    Generate
                  </button>
                )}
              </form>
              {result ? (
                <div className="researchresult">
                  <h2>
                    Search Results: Mavacamten in Hypertrophic Obstructive
                    Cardiomyopathy (HOCM)
                  </h2>
                  <hr />
                  <div className="resultbody">
                    <h1>
                      Mavacamten in Hypertrophic Obstructive Cardiomyopathy
                      (HOCM)
                    </h1>

                    <h3>Abstract</h3>

                    <h4>Background:</h4>
                    <p>
                      Hypertrophic obstructive cardiomyopathy (HOCM) is a
                      genetic disorder characterized by asymmetric myocardial
                      hypertrophy, left ventricular outflow tract (LVOT)
                      obstruction, and diastolic dysfunction. Conventional
                      treatment options, including beta-blockers, calcium
                      channel blockers, and invasive interventions, often fail
                      to adequately control symptoms in all patients.
                      Mavacamten, a novel cardiac myosin inhibitor, has emerged
                      as a targeted therapeutic option aimed at reducing
                      hypercontractility and alleviating LVOT obstruction. This
                      study evaluates the efficacy and safety of mavacamten in
                      patients with symptomatic HOCM.
                    </p>

                    <h4>Methods:</h4>
                    <p>
                      A prospective, randomized, double-blind,
                      placebo-controlled trial was conducted involving 200
                      patients with symptomatic HOCM. Participants were
                      randomized to receive mavacamten or placebo for 30 weeks.
                      The primary endpoints were changes in LVOT gradient, peak
                      oxygen consumption (pVO2), and New York Heart Association
                      (NYHA) functional class. Secondary endpoints included
                      changes in N-terminal pro-brain natriuretic peptide
                      (NT-proBNP) levels, quality of life as assessed by the
                      Kansas City Cardiomyopathy Questionnaire (KCCQ), and
                      safety outcomes.
                    </p>

                    <h4>Results:</h4>
                    <p>
                      The mavacamten group showed a significant reduction in
                      LVOT gradient (mean reduction of 36 mmHg; p {"<"} 0.001)
                      compared to placebo. Additionally, patients in the
                      mavacamten group demonstrated an improvement in pVO2 (mean
                      increase of 2.5 mL/kg/min; p = 0.002) and NYHA functional
                      class (43% of patients improved by at least one class; p =
                      0.001). NT-proBNP levels decreased by 60% in the
                      mavacamten group compared to 20% in the placebo group (p{" "}
                      {"<"} 0.001). Mavacamten was well-tolerated, with no
                      significant differences in adverse events between the
                      treatment and placebo groups.
                    </p>

                    <h4>Conclusion:</h4>
                    <p>
                      Mavacamten is an effective and well-tolerated treatment
                      for symptomatic HOCM, offering significant improvements in
                      LVOT obstruction, exercise capacity, and functional
                      status. These findings support the use of mavacamten as a
                      first-line treatment for patients with HOCM who are not
                      adequately controlled by conventional therapies.
                    </p>

                    <h3>Introduction</h3>
                    <p>
                      Hypertrophic obstructive cardiomyopathy (HOCM) is a common
                      inherited cardiac disorder characterized by asymmetric
                      left ventricular hypertrophy, leading to dynamic left
                      ventricular outflow tract (LVOT) obstruction, diastolic
                      dysfunction, and increased risk of arrhythmias and sudden
                      cardiac death. The pathophysiology of HOCM involves
                      hypercontractility due to mutations in genes encoding
                      sarcomeric proteins, particularly beta-myosin heavy chain
                      and myosin-binding protein C. Traditional management
                      strategies include pharmacological therapies such as
                      beta-blockers, calcium channel blockers, and disopyramide,
                      as well as invasive procedures like septal myectomy and
                      alcohol septal ablation. However, these treatments are
                      often insufficient to relieve symptoms in all patients,
                      leading to a need for novel therapeutic approaches.
                    </p>
                    <p>
                      Mavacamten (formerly MYK-461) is a first-in-class
                      selective inhibitor of cardiac myosin ATPase, designed to
                      reduce excessive myosin-actin cross-bridge formation and
                      thereby decrease myocardial contractility. By targeting
                      the underlying hypercontractility, mavacamten offers a
                      novel approach to alleviating LVOT obstruction and
                      improving symptoms in patients with HOCM. This study aims
                      to evaluate the efficacy and safety of mavacamten in
                      patients with symptomatic HOCM.
                    </p>

                    <h3>Methods</h3>

                    <h4>Study Design:</h4>
                    <p>
                      This study was a prospective, randomized, double-blind,
                      placebo-controlled trial conducted at multiple centers
                      specializing in cardiomyopathy. A total of 200 patients
                      with symptomatic HOCM (NYHA Class II-III) and an LVOT
                      gradient of ≥30 mmHg were enrolled between January 2023
                      and December 2023.
                    </p>

                    <h4>Inclusion and Exclusion Criteria:</h4>
                    <p>
                      Inclusion criteria included a diagnosis of HOCM confirmed
                      by echocardiography, LVOT gradient ≥30 mmHg at rest or
                      with provocation, and symptomatic HOCM despite optimized
                      medical therapy. Exclusion criteria included prior septal
                      reduction therapy, severe comorbidities, and LVEF {"<"}
                      50%.
                    </p>

                    <h4>Randomization and Intervention:</h4>
                    <p>
                      Participants were randomly assigned in a 1:1 ratio to
                      receive either mavacamten (5 mg daily, titrated based on
                      response) or placebo for 30 weeks. Randomization was
                      stratified by NYHA class and baseline LVOT gradient.
                    </p>

                    <h4>Outcome Measures:</h4>
                    <p>
                      The primary endpoints were changes in LVOT gradient
                      (assessed by echocardiography), peak oxygen consumption
                      (pVO2) measured by cardiopulmonary exercise testing, and
                      NYHA functional class. Secondary endpoints included
                      changes in NT-proBNP levels, KCCQ score, and safety
                      outcomes (including adverse events, LVEF, and
                      cardiovascular events).
                    </p>

                    <h4>Statistical Analysis:</h4>
                    <p>
                      Data were analyzed using intention-to-treat principles.
                      Continuous variables were compared using the Student’s
                      t-test, while categorical variables were analyzed using
                      the chi-square test. A p-value of {"<"}0.05 was considered
                      statistically significant.
                    </p>

                    <h3>Results</h3>

                    <h4>Baseline Characteristics:</h4>
                    <p>
                      The baseline characteristics of the two groups were
                      comparable, with no significant differences in age, sex,
                      LVOT gradient, or NYHA functional class.
                    </p>

                    <h4>Primary Endpoints:</h4>
                    <ul>
                      <li>
                        LVOT Gradient: The mavacamten group experienced a
                        significant reduction in LVOT gradient, with a mean
                        reduction of 36 mmHg (p {"<"} 0.001) compared to a 6
                        mmHg reduction in the placebo group.
                      </li>
                      <li>
                        Peak Oxygen Consumption (pVO2): Patients treated with
                        mavacamten showed a mean increase in pVO2 of 2.5
                        mL/kg/min (p = 0.002), whereas the placebo group showed
                        a minimal increase of 0.5 mL/kg/min.
                      </li>
                      <li>
                        NYHA Functional Class: 43% of patients in the mavacamten
                        group improved by at least one NYHA class compared to
                        19% in the placebo group (p = 0.001).
                      </li>
                    </ul>

                    <h4>Secondary Endpoints:</h4>
                    <ul>
                      <li>
                        NT-proBNP Levels: Mavacamten treatment resulted in a 60%
                        reduction in NT-proBNP levels compared to a 20%
                        reduction in the placebo group (p {"<"} 0.001).
                      </li>
                      <li>
                        Quality of Life (KCCQ Score): The KCCQ score improved by
                        an average of 20 points in the mavacamten group,
                        indicating a significant improvement in quality of life
                        (p {"<"} 0.001).
                      </li>
                    </ul>

                    <h4>Safety Outcomes:</h4>
                    <p>
                      Mavacamten was generally well-tolerated, with no
                      significant differences in adverse events between the
                      mavacamten and placebo groups. A small number of patients
                      (4%) in the mavacamten group experienced mild, reversible
                      reductions in LVEF, which resolved with dose adjustment.
                    </p>

                    <h3>Discussion</h3>
                    <p>
                      The results of this study demonstrate that mavacamten is a
                      highly effective treatment for patients with symptomatic
                      HOCM, significantly reducing LVOT obstruction, improving
                      exercise capacity, and enhancing functional status. These
                      findings align with previous studies, such as the
                      EXPLORER-HCM trial, which also reported significant
                      benefits of mavacamten in reducing LVOT gradient and
                      improving symptoms. The reduction in NT-proBNP levels
                      further supports the role of mavacamten in alleviating
                      myocardial stress.
                    </p>
                    <p>
                      The safety profile of mavacamten was favorable, with
                      reversible reductions in LVEF being the most notable
                      adverse event. This highlights the importance of careful
                      monitoring and dose adjustment to avoid potential
                      complications. However, the overall benefit-risk profile
                      of mavacamten suggests that it could become a first-line
                      treatment option for patients with HOCM who are not
                      adequately controlled by traditional therapies.
                    </p>

                    <h3>Conclusion</h3>
                    <p>
                      Mavacamten represents a significant advancement in the
                      treatment of symptomatic HOCM, offering a novel mechanism
                      of action that directly targets the pathophysiology of the
                      disease. Its ability to reduce LVOT obstruction, improve
                      exercise capacity, and enhance quality of life underscores
                      its potential as a first-line treatment option for this
                      challenging condition. Future studies should focus on
                      long-term outcomes and the potential role of mavacamten in
                      combination with other therapies.
                    </p>

                    <h3>References</h3>
                    <p>
                      1. Elliott PM, Anastasakis A, Borger MA, et al. 2014 ESC
                      Guidelines on diagnosis and management of hypertrophic
                      cardiomyopathy: The Task Force for the Diagnosis and
                      Management of Hypertrophic Cardiomyopathy of the European
                      Society of Cardiology (ESC). Eur Heart J.
                      2014;35(39):2733-2779.
                    </p>
                    <p>
                      2. Olivotto I, Oreziak A, Barriales-Villa R, et al.
                      Mavacamten for treatment of symptomatic obstructive
                      hypertrophic cardiomyopathy (EXPLORER-HCM): a randomised,
                      double-blind, placebo-controlled, phase 3 trial. Lancet.
                      2020;396(10253):759-769.
                    </p>
                    <p>
                      3. Ho CY, Day SM, Colan SD, et al. The Mavacamten Study:
                      Clinical outcomes and implications for the management of
                      hypertrophic cardiomyopathy. J Am Coll Cardiol.
                      2021;77(25):3182-3194.
                    </p>
                  </div>
                  <div className="contentright">
                    <div className="elementselector">Download PDF</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {result ? (
              ""
            ) : (
              <div className="researchelementright">
                <img src={articleimage} alt="Article" />
              </div>
            )}
          </div>
        </div>
        {result ? (
          <div className="dashboardresponsefeedback">
            <p>Do you like the Response?</p>
            <img src={ThumbsUp} alt="Thumbs Up" />
            <img src={ThumbsDown} alt="Thumbs Down" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
