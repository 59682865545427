import { apiSlice } from "../apiSlice";

export const generalSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    solveMyQuery: builder.mutation({
      query: (query) => ({
        url: "api/v1/in-mind/",
        method: "POST",
        body: query,
      }),
    }),
    GetsolveMyQuery: builder.query({
      query: (uid) => ({
        url: `api/v1/get-in-mind/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Query"],
    }),
    GetDiagnosis: builder.mutation({
      query: (formdata) => ({
        url: `api/v1/diagnosis/`,
        method: "POST",
        body: formdata,
      }),
    }),
    GetExperAdvice: builder.mutation({
      query: (formdata) => ({
        url: `api/v1/expertadvice/`,
        method: "POST",
        body: formdata,
      }),
    }),

    GetsolveMyQuery: builder.query({
      query: (uid) => ({
        url: `api/v1/get-in-mind/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Query"],
    }),
    GetDiagnosis: builder.mutation({
      query: (formdata) => ({
        url: `api/v1/diagnosis/`,
        method: "POST",
        body: formdata,
      }),
    }),
    bookmarkQuery: builder.mutation({
      query: (id) => ({
        url: `api/v1/bookmarkinmind/${id}/`,
        method: "PUT",
      }),
    }),
    GetGeneratedDiagnosis: builder.query({
      query: (uid) => ({
        url: `api/v1/get-diagnosis/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Diagnosis"],
    }),
    GetAllBlogs: builder.query({
      query: () => ({
        url: `api/v1/allblogs/`,
        method: "GET",
      }),
      providesTags: ["Blogs"],
    }),
    GetOneBlog: builder.query({
      query: (slug) => ({
        url: `api/v1/blog/${slug}/`,
        method: "GET",
      }),
      providesTags: ["Blogs"],
    }),
    Addbookmark: builder.mutation({
      query: (body) => ({
        url: `api/v1/${body.page}/${body.id}/`,
        method: "PUT",
      }),
      invalidatesTags: ["Questions"],
    }),
  }),
});

export const {
  useSolveMyQueryMutation,
  useGetsolveMyQueryQuery,
  useGetGeneratedDiagnosisQuery,
  useGetDiagnosisMutation,
  useBookmarkQueryMutation,
  useGetExperAdviceMutation,
  useGetAllBlogsQuery,
  useGetOneBlogQuery,
  useAddbookmarkMutation,
} = generalSlice;
