import React, { useState } from "react";
import ClinicalCongratsQBank from "../elements/clinicalcongratsqbank";
import ClinicalCongratsLearning from "../elements/clinicalcongratsslearning";
import ClinicalCongratsScore from "../elements/clinicalcongratsscore";
import SymptomChecker from "../elements/symptomchecker";
import LoadingScreen from "../elements/loading";
import SubjectsPopup from "../elements/subjectspopup";

export default function PopupTesting() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <div>
      <button onClick={togglePopup}>Open Popup</button>
      <LoadingScreen loaderLine="" />
      <SubjectsPopup isVisible={isPopupVisible} onClose={togglePopup} />
    </div>
  );
}
