import React, { useState } from "react";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { useNavigate } from "react-router-dom";
import ScoreBoardBanner from "./scoreboardbanner";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

const mobileback =
  "https://neuro-gen.s3.amazonaws.com/images/icons/mobileback.svg";
export default function HomemainHeaderMobile({
  onToggle,
  isSidebarCollapsed,
  header,
}) {
  const navigate = useNavigate();
  const { data, isLoading } = useGetUserScoreQuery();
  const handleNavigation = (path) => {
    if (path === "Profile") {
      navigate("/profile");
    }
  };

  return (
    <div className="mobileheader">
      <div className="homemainleft">
        <div className="homemainleftinner">
          <div
            className="closesidebarbuttonmbobileheader"
            onClick={() => {
              if (window.history.length <= 2) {
                navigate("/dashboard");
              } else {
                navigate(-1);
              }
            }}
          >
            <img src={mobileback} alt="Arrow" />
          </div>
        </div>
      </div>
      <div className="homemaincenter">
        <h2>{header}</h2>
      </div>
      <div className="homemainright">
        <div onClick={onToggle}>
          <img src={GripLine} alt="Griplines" />
        </div>
      </div>
    </div>
  );
}
