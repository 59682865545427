import React, { useState } from "react";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import ClinicalCongrats from "../elements/clinicalcongrats";
import ClinicalTryAgain from "../elements/clinicaltryagain";
import ClinicalCongratsScore from "../elements/clinicalcongratsscore";
import ClinicalCongratsLearning from "../elements/clinicalcongratsslearning";
import HomemainHeaderMobile from "../components/homemainheadermobile";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const TimeIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/time.svg";
export default function ClinicalDetailed() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionVisible, setQuestionVisible] = useState(false);
  const [isManagementPlanSubmitted, setIsManagementPlanSubmitted] =
    useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );

  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Clinical Case"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clincalbody">
          <div className="clinicalprogress">
            <h5>Case Progress</h5>
            <div className="clinicalprogressestimate">
              <h4>75% to Complete</h4>
              <div className="clinicalprogressestimatetime">
                <img src={TimeIcon} alt="Time" />
                <p>39min</p>
              </div>
            </div>
            <div className="clinicalprogresspercentage">
              <div className="clinicalprogressbar"></div>
            </div>
          </div>
          <div className="clinicalbodyelements">
            <div className="clinicalelementheader">
              <div className="clinicalimageheader">
                <img src={AnatomyIcon} alt="Subject Icon" />
                <h4>Case 1:</h4>
              </div>
              <div className="clinicalheaderrow">
                <div className="elementselector">
                  Team Up and Solve this Case
                </div>
                <div className="elementselector">Previous</div>
                <div className="elementselector">Next</div>
                <div className="elementselector">Skip</div>
              </div>
            </div>

            <hr />
            <div className="clinicalelementbody">
              <h4>56 Yr Old Man with Sudden Onset of Severe Chest Pain</h4>
              <div className="clinicalelementbodyheader">
                Patient Information:
              </div>
              <br />
              <ul>
                <li>Name: Mr. Rajesh Kumar</li>
                <li>Age: 56 years</li>
                <li>Sex: Male</li>
                <li>Occupation: Retired Bank Manager</li>
                <li>Chief Complaint: Sudden onset of severe chest pain</li>
              </ul>
              <div className="clinicalelementbodyheader headerbodybottom">
                <strong>Description:</strong>
              </div>

              <p>
                History of Present Illness:
                <br />
                Mr. Rajesh Kumar presents to the emergency department with a
                sudden, excruciating chest pain that started 2 hours ago. The
                pain is sharp, tearing, and radiates to his back. He also feels
                light-headed and short of breath. There’s no history of trauma
                or physical exertion.
              </p>
              <br />
              <div className="clinicalelementbodyheader">
                Past Medical History:
              </div>
              <ul>
                <li>Hypertension for 10 years (poorly controlled)</li>
                <li>Type 2 Diabetes Mellitus for 5 years</li>
                <li>Hyperlipidemia</li>
                <li>No history of smoking or alcohol use</li>
              </ul>
              <div className="clinicalelementbodyheader">
                Physical Examination:
              </div>
              <ul>
                <li>
                  Vitals: BP: 190/110 mmHg (right arm), 170/100 mmHg (left arm);
                  HR: 110 bpm; RR: 22/min; Temp: 98.6°F
                </li>
                <li>
                  Cardiovascular: Diminished pulses in the left arm, diastolic
                  murmur over the aortic area
                </li>
              </ul>
              <div className="clinicalelementbodyheader">Investigations:</div>
              <ul>
                <li>ECG: Sinus tachycardia with nonspecific ST changes</li>
                <li>Chest X-ray: Widened mediastinum</li>
                <li>
                  CT Angiography of the chest: Abnormal findings in the
                  ascending aorta
                </li>
              </ul>
            </div>
            <button
              className="profilesubmit clinicalmanagebutton"
              onClick={() => setQuestionVisible(true)}
            >
              Manage the Patient
            </button>
          </div>
          {questionVisible && (
            <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <div className="clinicalimageheader">
                  <h4>Question 1</h4>
                </div>
                <div className="clinicalheaderrow">
                  <div className="elementselector">Next</div>
                  <div className="elementselector">Skip</div>
                </div>
              </div>
              <hr />
              <div className="clinicalelementbody">
                <div className="clinicalelementbodyheader headerbodybottom">
                  <strong>
                    Considering Mr. Verma’s clinical presentation and physical
                    findings, outline your diagnostic strategy and the immediate
                    steps you would take to stabilize the patient. Discuss the
                    rationale for these steps and the key aspects of further
                    management.
                  </strong>
                </div>
                <form onSubmit={handleSubmit}>
                  <textarea
                    required
                    defaultValue={`Given Mr. Verma’s symptoms, my first thought would be that he might be suffering from a serious condition like an aortic dissection, especially considering the sudden onset of sharp chest pain radiating to his back. The unequal blood pressure readings in his arms and the widened mediastinum on his chest X-ray further support this suspicion.\n\nDiagnostic Strategy:\nFirst, I would ensure a quick and thorough physical examination and immediately order a CT angiography of the chest. This imaging test is critical as it can confirm whether or not there’s a dissection and determine its extent.\n\nImmediate Stabilization Steps:\nThe priority here is to stabilize his condition while awaiting definitive treatment. I would start by lowering his blood pressure to reduce the stress on his aorta. IV beta-blockers, like esmolol or labetalol, would be my first choice because they help control both heart rate and blood pressure. Since pain can worsen his condition by increasing his heart rate and blood pressure, I would also administer IV opioids for pain relief.\n\nFurther Management:\nIf the CT confirms a Type A dissection, I’d immediately get the surgical team involved because this type requires urgent surgery. The goal would be to repair the affected section of the aorta before it ruptures. After surgery, he would need close monitoring in the ICU with a focus on blood pressure control and regular follow-ups to ensure the aorta heals properly.\n\nOverall, the key in managing Mr. Verma’s condition is rapid diagnosis, aggressive blood pressure control, and timely surgical intervention.`}
                  ></textarea>

                  <div className="submitrowright">
                    <button
                      type="submit"
                      className="clinicalmcqsubmit"
                      disabled={isSubmitted}
                      onClick={togglePopup}
                    >
                      Submit
                    </button>
                    <ClinicalCongratsLearning
                      isVisible={isPopupVisible}
                      onClose={togglePopup}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
          {isSubmitted && (
            <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <div className="clinicalimageheader">
                  <h4>Evaluation</h4>
                </div>
                <div className="clinicalheaderrow">
                  <div className="elementselector">Total Score: 36/40</div>
                  <div
                    className="elementselector"
                    onClick={() => setIsManagementPlanSubmitted(true)}
                  >
                    View Management Plan
                  </div>
                </div>
              </div>
              <hr />
              <div className="clinicalelementbodyevaluation">
                <textarea
                  value={`Overall Score: 36/40\n\nLeadership: 8/10: The student demonstrates a proactive approach with a clear, structured plan. Slightly more emphasis on team coordination could enhance leadership.\n\nClinical Knowledge: 9/10: Strong understanding of aortic dissection, diagnostic strategies, and management. Mentioning differential diagnoses could show even broader knowledge.\n\nEmotional Intelligence: 8/10: The response shows concern for patient comfort with pain management. Acknowledging patient and family communication could further elevate emotional intelligence.\n\nDecision-Making: 9/10: Logical, evidence-based decisions are made, prioritizing the patient’s immediate needs. Briefly considering potential complications would strengthen this further.`}
                ></textarea>
              </div>
            </div>
          )}
          {isManagementPlanSubmitted && (
            <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <div className="clinicalimageheader">
                  <h4>Management Plan</h4>
                </div>
                <div className="clinicalheaderrow">
                  <div className="elementselector">Download</div>
                </div>
              </div>
              <hr />
              <div className="clinicalelementbodyevaluation">
                <textarea
                  value={`Overall Score: 36/40\n\nLeadership: 8/10: The student demonstrates a proactive approach with a clear, structured plan. Slightly more emphasis on team coordination could enhance leadership.\n\nClinical Knowledge: 9/10: Strong understanding of aortic dissection, diagnostic strategies, and management. Mentioning differential diagnoses could show even broader knowledge.\n\nEmotional Intelligence: 8/10: The response shows concern for patient comfort with pain management. Acknowledging patient and family communication could further elevate emotional intelligence.\n\nDecision-Making: 9/10: Logical, evidence-based decisions are made, prioritizing the patient’s immediate needs. Briefly considering potential complications would strengthen this further.`}
                ></textarea>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
