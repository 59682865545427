import { apiSlice } from "../apiSlice";

export const QBApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    Getsubject: builder.query({
      query: () => ({
        url: "api/v1/getsubjects/",
      }),
      providesTags: ["QB"],
    }),
    GetQbank: builder.mutation({
      query: (data) => ({
        url: "api/v1/questionbank/",
        method: "POST",
        body: data,
      }),
      providesTags: ["QBank"],
    }),
    GetGeneratedQBank: builder.query({
      query: (id) => ({
        url: `api/v1/qbank/${id}`,
        method: "GET",
      }),
      providesTags: ["GenQbank"],
    }),
    submitQBank: builder.mutation({
      query: ({ uid, data }) => ({
        url: `api/v1/submitanswer/${uid}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["GenQbank"],
    }),
  }),
});

export const {
  useGetsubjectQuery,
  useGetQbankMutation,
  useSubmitQBankMutation,
  useGetGeneratedQBankQuery,
} = QBApiSlice;
