import React from "react";
import "../styles/termsandcondition.css";
import { useNavigate } from "react-router-dom";
export default function CancellationPolicy() {
  const navigate = useNavigate();
  return (
    <div className="loginmain">
      <div className="conditionscontentbody">
        <div
          className="conditionsbodyheader"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back To Dashboard
        </div>
        <h1>Cancellation and Refund Policy</h1>
        <p class="last-updated">Last updated: 12/09/24</p>

        <p>
          At NeuroGEN, we are committed to providing top-quality Al-driven
          medical services. Please note that we have a{" "}
          <strong>No Cancellation</strong> and <strong>No Refund policy</strong>{" "}
          for all products and services purchased through our website.
        </p>

        <h2>1. No Cancellation</h2>
        <p>
          Once a subscription, membership, or service is purchased on the
          NeuroGEN website, it cannot be canceled. We encourage users to review
          the details of each service carefully before making a purchase.
        </p>

        <h2>2. No Refunds</h2>
        <p>
          All sales are final, and no refunds will be issued for any purchases
          made on the NeuroGEN website. This applies to both subscription-based
          services and one-time purchases.
        </p>

        <h2>3. Service Access</h2>
        <p>
          In the event of service outages, maintenance, or technical issues, we
          will work to resolve them promptly. However, this does not entitle
          users to a refund or cancellation of their subscription.
        </p>

        <h2>4. Compliance with Applicable Laws</h2>
        <p>
          Where applicable law mandates cancellation or refund options, we will
          comply with those specific legal requirements.
        </p>

        <h4>
          If you have any questions or need assistance, please contact our
          support team at <u>neuroverse.healthcare@gmail.com</u> or{" "}
          <u>9582131358</u>.
        </h4>

        <p>Thank you for choosing NeuroGEN for your healthcare needs.</p>
      </div>
    </div>
  );
}
