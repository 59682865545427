import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useEmilresetpassowrdMutation } from "../store/profile/UserApiSlice";
import { toast } from "react-toastify";
const ViewIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpassword.svg";
const ViewOpen =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpasswordopen.svg";
export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [resetcall, resetcallstate] = useEmilresetpassowrdMutation();
  const { uid, token } = useParams();
  const [viewState, setViewState] = useState("password");
  const [viewState2, setViewState2] = useState("password");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      await resetcall({
        email: values.email,
      }).then((resp) => {
        if (resp?.error?.data) {
          for (const key in resp?.error?.data) {
            if (resp?.error?.data.hasOwnProperty(key)) {
              resp?.error?.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`);
              });
            }
          }
        } else {
          toast.success("Sent reset password email!");
          navigate("/");
        }
      });

      // nextStage();
      // doneStage();
    },
  });

  return (
    <div className="loginmain">
      <form onSubmit={formik.handleSubmit}>
        <div className="loginform flex flex-col bg-white rounded-lg p-5 border-gray-100">
          <div className="flex flex-col mb-5">
            <label htmlFor="email">Email</label>
            <div className="inputdiv flex flex-col">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="p-2"
                required
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error text-red-500  w-full items-start">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>

          <button type="submit" className="mt-5">
            {" "}
            {resetcallstate?.isLoading ? "submitting..." : "Continue"}
          </button>
        </div>
      </form>
    </div>
  );
};
export default ForgotPassword;
