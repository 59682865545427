import React, { useState } from "react";
import "../styles/quickddx.css";
const clipicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/clip.svg";
const FreeTrialIcon = "https://neuro-gen.s3.amazonaws.com/images/freepopup.svg";
const FreeTrialText =
  "https://neuro-gen.s3.amazonaws.com/images/freetrialtext.svg";

const crossicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crossicon.svg";
export default function FreeTrial({ isVisible, onClose }) {
  

  if (!isVisible) return null;

  

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="freetrialpopup" onClick={(e) => e.stopPropagation()}>
        <img
          src={crossicon}
          alt="Close"
          onClick={onClose}
          className="closeiconpopup"
        />
        <img src={FreeTrialIcon} alt="Free Trial" />
        <div className="freetrialbody">
          <button className="introofferbutton">Introductory Offer</button>
          <p>Get 7 Days</p>
          <img src={FreeTrialText} alt="Free Trial" />
          <p>worth Rs.999 </p>
          <button>Claim This Offer</button>
        </div>
      </div>
    </div>
  );
}
