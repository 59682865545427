import React, { useEffect, useState } from "react";
import SideBar from "../elements/sidebar";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import "../styles/research.css";
import { Formik } from "formik";
import { useGetExperAdviceMutation } from "../store/General/generalSlice";
import { toast } from "react-toastify";
const expertadviceimages =
  "https://neuro-gen.s3.amazonaws.com/images/icons/expertadvice.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";

const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

export default function ExpertAdvice() {
  const [getExperAdvice, {
    isSuccess: isExperAdviceSuccess,
    isError: isExperAdviceError,
  }] = useGetExperAdviceMutation();

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );


  useEffect(() => {
    if (isExperAdviceSuccess) {
      toast.success("Query submitted successfully");
    }

    if (isExperAdviceError) {
      toast.error("Failed to submit query");
    }
  }, [isExperAdviceError, isExperAdviceSuccess]);

  return (
    <div className="homemain">
      <SideBar
        selected="Expert"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Expert Advice"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="homemainleftouter">
            <h3>Consult Our Expert Medical Team:</h3>
            <p>Confused? We have got you covered, </p>
          </div>
        </div>
        <div className="researchbody">
          <div className="researchelement">
            <div className="researchelementleft">
              <Formik
                initialValues={{
                  file_uploads: [],
                  query: "",
                  describe: "",
                  urgency_level: "",
                }}
                onSubmit={async (values, action) => {
                  try {
                    const formData = new FormData();
                    formData.append("query", values.query);
                    formData.append("describe", values.describe);
                    formData.append("urgency_level", values.urgency_level);
                    values.file_uploads.forEach((file) => {
                      formData.append("file_uploads", file);
                    });
                    await getExperAdvice(formData);
                  } catch (error) {
                    console.error("Failed to submit query", error);
                  } finally {
                    action.setSubmitting(false);
                  }
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, setValues, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <h5>Ask your Query :</h5>
                    <input
                      type="text"
                      name="query"
                      onChange={handleChange}
                      value={values.query}
                      placeholder="Hey, I’m stuck & need help with my eisenmenger case!"
                      required
                    />
                    <h5>Describe your Case / Query for tailored advice :</h5>
                    <textarea
                      name="describe"
                      onChange={handleChange}
                      value={values.describe}
                      placeholder="Critical! Our 17-year-old patient from Rajasthan suffers from worsening breathlessness, syncope, and pink frothy cough. Severe anemia, Pan-Systolic Murmur, and DORV with VSD found on echo. Vitals are alarming. Seeking urgent advice on management!"></textarea>
                    <div className="clinicalbodyoptions expertoptions">
                      <h5>Urgency Level</h5>

                      <div className="clinicalbodyoption">
                        <input
                          type="radio"
                          name="urgency_level"
                          id="option1"
                          value="high"
                          onChange={() => setFieldValue("urgency_level", "high")}
                          checked={values.urgency_level === "high"}
                          required
                        />
                        <label htmlFor="option1">Emergency (ASAP)</label>
                      </div>
                      <div className="clinicalbodyoption">
                        <input
                          type="radio"
                          name="urgency_level"
                          id="option2"
                          value="mid"
                          onChange={() => setFieldValue("urgency_level", "mid")}
                          checked={values.urgency_level === "mid"}
                        />
                        <label htmlFor="option2">
                          High Priority (Within 24 hrs)
                        </label>
                      </div>
                      <div className="clinicalbodyoption">
                        <input
                          type="radio"
                          name="urgency_level"
                          id="option3"
                          value="low"
                          onChange={() => setFieldValue("urgency_level", "low")}
                          checked={values.urgency_level === "low"}
                        />
                        <label htmlFor="option3">Standard (24 to 48 hrs)</label>
                      </div>
                    </div>
                    <h5>Add Lab & Imaging Files</h5>
                    <p>Image Upload/ PDF Upload</p>
                    <div className="fileupload">
                      <input
                        type="file"
                        id="file"
                        accept=".pdf, image/*"
                        onChange={(event) => {
                          setValues(pre => ({ ...pre, file_uploads: [...pre.file_uploads, ...event.target.files] }));
                        }}
                        style={{ display: "none" }}
                      />

                      <label htmlFor="file" style={{ cursor: "pointer" }}>
                        Upload File
                        <img src={UploadIcon} alt="Upload" />
                      </label>

                      {values.file_uploads.length > 0 && (
                        <div>
                          {Array.from(values.file_uploads).map((file, index) => (
                            <div key={index}>{file.name} <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setFieldValue("file_uploads", values.file_uploads.filter((_, i) => i !== index));
                              }}

                              className="text-red-500">X</span></div>
                          ))}
                        </div>
                      )}
                    </div>

                    <button disabled={isSubmitting} type="submit" className="generatebutton">
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <div className="researchelementright">
              <img src={expertadviceimages} alt="Article" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
