import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const handleDownload = async (elementRef) => {
  const element = elementRef.current;

  if (!element) {
    console.error("Element reference is not defined.");
    return;
  }

  // Save the original width to restore later
  const originalWidth = element.style.width;

  try {
    // Temporarily set the element's width to 1920px to simulate larger screen rendering
    element.style.width = "1920px";

    // Set scale factor to 1, since we've already set the desired width
    const canvas = await html2canvas(element, { scale: 1 });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Define padding for the PDF
    const padding = 5; // 10mm padding around the content
    const pdfWidth = 190; // A4 width in mm
    const pdfHeight = 280; // A4 height in mm
    const imgWidth = pdfWidth - 2 * padding; // Adjust image width for padding
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height with respect to the new width
    let heightLeft = imgHeight;
    let position = padding; // Start position considering the padding

    // Load the watermark image
    const watermarkImg = new Image();
    watermarkImg.src = `/IMG_0922.PNG`;

    // Wait for the watermark image to load
    await new Promise((resolve, reject) => {
      watermarkImg.onload = resolve;
      watermarkImg.onerror = (err) => {
        console.error("Watermark image failed to load:", err);
        reject(err);
      };
    });

    const watermarkCanvas = document.createElement("canvas");
    const watermarkCtx = watermarkCanvas.getContext("2d");

    // Set the watermark size
    const watermarkWidth = pdfWidth * 0.8;
    const watermarkHeight =
      (watermarkImg.height * watermarkWidth) / watermarkImg.width;

    watermarkCanvas.width = pdfWidth;
    watermarkCanvas.height = pdfHeight;

    // Draw the watermark with reduced opacity on the canvas
    watermarkCtx.globalAlpha = 0.1; // Set watermark opacity (0.1 = 10%)
    watermarkCtx.drawImage(watermarkImg, 0, 0, watermarkWidth, watermarkHeight);

    // Convert the watermark canvas to data URL
    const watermarkData = watermarkCanvas.toDataURL("image/png");

    // Add the content image to the PDF
    pdf.addImage(imgData, "PNG", padding, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight - 2 * padding;

    const watermarkX = (pdfWidth - watermarkWidth + 25) / 2;
    const watermarkY = (pdfHeight - watermarkHeight + 50) / 2;

    // Create a separate canvas for watermark with reduced opacity
    pdf.addImage(
      watermarkData,
      "PNG",
      watermarkX,
      watermarkY,
      watermarkWidth,
      watermarkHeight,
      undefined,
      "SLOW"
    );
    // Add more pages if content exceeds one page and apply the watermark
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + padding;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", padding, position, imgWidth, imgHeight);

      // Apply watermark on each new page
      pdf.addImage(
        watermarkImg.src,
        "PNG",
        watermarkX,
        watermarkY,
        watermarkWidth,
        watermarkHeight,
        undefined,
        "FAST"
      );

      heightLeft -= pdfHeight - 2 * padding;
    }

    pdf.save("download_result.pdf");
  } catch (error) {
    console.error("Failed to generate PDF:", error);
  }
};

const Achievement1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/1.svg";
const Achievement2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/2.svg";
const Achievement3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/3.svg";
const Achievement4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/4.svg";
const Achievement5 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/5.svg";
const Achievement6 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/6.svg";
const Achievement7 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/7.svg";
const Achievement8 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/8.svg";
const Achievement9 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/9.svg";
const Achievement10 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/10.svg";
const getAchievementSrc = (score) => {
  if (score < 50) return Achievement1;
  if (score < 100) return Achievement2;
  if (score < 250) return Achievement3;
  if (score < 500) return Achievement4;
  if (score < 1000) return Achievement5;
  if (score < 1250) return Achievement6;
  if (score < 1500) return Achievement7;
  if (score < 1750) return Achievement8;
  if (score < 2000) return Achievement9;
  return Achievement10;
};
const GetBadgeName = (score) => {
  if (score < 50) return "Novice";
  if (score < 100) return "Apprentice";
  if (score < 250) return "MedExpert";
  if (score < 500) return "Scholar";
  if (score < 1000) return "Master Blaseter";
  if (score < 1250) return "Disease Conqueror";
  if (score < 1500) return "Hero";
  if (score < 1750) return "Wizard";
  if (score < 2000) return "Luminary";
  if (score < 2500) return "Beacom of Medicine";
};
export default handleDownload;
export { getAchievementSrc, GetBadgeName };
