import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useResetpaswordMutation } from "../store/profile/UserApiSlice";
import { toast } from "react-toastify";
const ViewIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpassword.svg";
const ViewOpen =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpasswordopen.svg";
export const ResetPassword = () => {
  const navigate = useNavigate();
  const [resetcall, resetcallstate] = useResetpaswordMutation();
  const { uid, token } = useParams();
  const [viewState, setViewState] = useState("password");
  const [viewState2, setViewState2] = useState("password");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(12, "Password must be at most 12 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      await resetcall({
        uid,
        token,
        new_password: values.password,
        re_new_password: values.password,
      }).then((resp) => {
        if (resp?.error?.data) {
          for (const key in resp?.error?.data) {
            if (resp?.error?.data.hasOwnProperty(key)) {
              resp?.error?.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`);
              });
            }
          }
        } else {
          toast.success("reset passwrod successfully");
          navigate("/login");
        }
      });

      // nextStage();
      // doneStage();
    },
  });
  const handleView = () => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
      setViewState("text");
    } else {
      password.type = "password";
      setViewState("password");
    }
  };
  const handleView2 = () => {
    const password = document.getElementById("confirmpassword");
    if (password.type === "password") {
      password.type = "text";
      setViewState2("text");
    } else {
      password.type = "password";
      setViewState2("password");
    }
  };

  return (
    <div className="loginmain bg-white">
      <form onSubmit={formik.handleSubmit}>
        <div className="loginform bg-white">
          <div className="flex flex-col">
            <label htmlFor="password">Password</label>
            <div className="inputdiv flex">
              <input
                type="password"
                id="password"
                name="password"
                maxLength={12}
                placeholder="Enter your Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
              />
              {viewState === "text" ? (
                <img src={ViewOpen} alt="View" onClick={handleView} />
              ) : (
                <img src={ViewIcon} alt="View" onClick={handleView} />
              )}
            </div>{" "}
            {formik.touched.password && formik.errors.password ? (
              <div className="error text-red-500  w-full items-start">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <br />
          <div className="flex flex-col">
            <label htmlFor="confirmPassword"> Confirm Password</label>
            <div className="inputdiv flex">
              <input
                type="password"
                id="confirmpassword"
                name="confirmPassword"
                maxLength={12}
                placeholder="Confirm your Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                required
              />
              {viewState2 === "text" ? (
                <img src={ViewOpen} alt="View" onClick={handleView2} />
              ) : (
                <img src={ViewIcon} alt="View" onClick={handleView2} />
              )}
            </div>{" "}
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="error text-red-500 w-full items-start">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>

          <button type="submit">
            {" "}
            {resetcallstate?.isLoading ? "submitting..." : "Continue"}
          </button>
        </div>
      </form>
    </div>
  );
};
export default ResetPassword;
