import React from "react";
const achievementImg =
  "https://neuro-gen.s3.amazonaws.com/images/achievements.svg";
export default function ScoreBoardBanner({ isVisible, onClose }) {
  if (!isVisible) return null;

  return (
    <div className="scoreboardbanner">
      <div className="scoreboardbannerbody">
        <img src={achievementImg} alt="Achievements" />
        <div className="scoreboardbannerbodycontent">
          <h2>Earn Achievement Badges</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Id donec elementum est a
            fusce libero amet eget consectetur. Quisque sapien tristique
            pharetra.
          </p>
          <div className="contentright">
            <button className="text-blue-400" onClick={onClose}>
              Remind me later
            </button>
            <button className="elementselectorfilled">Show Me How</button>
          </div>
        </div>
      </div>
      <div className="topleftarrow"></div>
    </div>
  );
}
