import React, { useState, useEffect } from "react";
import SideBar from "../elements/sidebar";
import "../styles/profile.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../store/authSlice";
import config from "../config/APIEndpoints";
import {
  useGetProfileQuery,
  useGetSubscriptionsQuery,
  useUpdateProfileMutation,
} from "../store/profile/UserApiSlice";
import {
  useGetBookmarkQuery,
  useGetHistoryQuery,
} from "../store/History/History";
import { useFormik } from "formik";
import * as Yup from "yup";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { toast } from "react-toastify";
import app_api from "../config/Api";
import EmptyHistory from "../elements/emptyhistory";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const ProfileIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profilevectro.svg";
const membershipTick =
  "https://neuro-gen.s3.amazonaws.com/images/icons/membershiptick.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
export const DetailsSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(null);
  const roles = [
    "Medical Aspirant",
    "Medical Student",
    "Intern",
    "Junior Resident",
    "Senior Resident",
    "Medical Researcher",
    "Consultant",
    "Clinical Specialist",
    "Fellowship",
    "Gene Scientist",
    "Clinical Psychologist",
    "Nutritionist",
    "Medical Educator",
    "Healthcare IT Professional",
    "Paramedic",
    "Public Health Professional",
    "Healthcare administrator",
    "Others",
  ];
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
  const [file, setFile] = useState(null);
  const { data, isLoading, refetch } = useGetProfileQuery();
  const [updateprofile, setupdateprofile] = useUpdateProfileMutation();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    setFile(event.target.files[0]);
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
    }
  };

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleChangeSpecialisation = (e) => {
    setSelectedSpecialisation(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      specialization: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      role: Yup.string().required("Please select your role"),
      specialization: Yup.string().required(
        "Please select your specialisation"
      ),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      if (file) formData.append("profile_pic", file);
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);
      formData.append("role", values.role);
      formData.append("specialization", values.specialization);
      const resp = await updateprofile(formData);
      if (resp?.data) toast.success("updated!");
      // Submit the formData to your API endpoint here
    },
  });
  useEffect(() => {
    formik.setValues({
      firstName: data?.profile?.first_name,
      lastName: data?.profile?.last_name,
      email: data?.profile?.email,
      phoneNumber: data?.profile?.phone_number,
      role: data?.profile?.role,
      specialization: data?.profile?.specialization,
    });
  }, [data]);
  return (
    <>
      <div className="profilebodyelement">
        <div className="profilerow">
          <div className="profileleft">
            <label htmlFor="upload-photo">
              <img
                src={
                  filePreview
                    ? filePreview
                    : data?.profile?.profile_pic
                    ? `${config.getEndpoint()}${data?.profile?.profile_pic}`
                    : ProfileIcon
                }
                alt="Profile"
                className="upload-icon"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "2px solid #ccc",
                }}
              />
            </label>
            <input
              type="file"
              id="upload-photo"
              accept="image/png"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <div className="profileright">
            <h3>Upload Your Photo</h3>
            <p>Your photo should be in PNG format</p>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="profileinput">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error text-red-500">
                {formik.errors.firstName}
              </div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error text-red-500">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              disabled={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error text-red-500">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Current Role</label>
            <div className="inputdivselect">
              <select
                id="role"
                name="role"
                value={formik.values.role}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleChange(e);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>
                  Select your role
                </option>
                {roles.map((role, index) => (
                  <option key={index} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.role && formik.errors.role ? (
              <div className="error text-red-500">{formik.errors.role}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>specialization</label>
            <input
              type="text"
              name="specialization"
              value={formik.values.specialization}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.specialization && formik.errors.specialization ? (
              <div className="error text-red-500">
                {formik.errors.specialization}
              </div>
            ) : null}
          </div>
          <button
            onClick={formik.handleSubmit}
            type="submit"
            className="profilesubmit"
          >
            {setupdateprofile?.isLoading ? "Saving..." : "Save"}
          </button>
        </form>
      </div>
      {/* <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Switch to Dark Mode</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div> */}
      <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Change Password</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <button className="changepasswordprofile">Change</button>
          </div>
        </div>
      </div>
      <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Logout</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <button
              className="logoutprofile"
              onClick={() => {
                dispatch(logOut());
                navigate("/");
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export const MembershipSection = () => {
  const [selectedItem, setSelectedItem] = useState("Monthly");
  const user = useSelector((state) => state.auth.user);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const { data: profileData, refetch } = useGetProfileQuery();

  const paymentInit = (data) => {
    const options = {
      key: process.env.REACT_APP_RZP_KEY_ID,
      amount: data.amount,
      subscription_id: data.subId,
      currency: data.currency,
      name: "NeuroGen",
      description: "",
      image: "/logo.png",
      handler: async function (response) {
        try {
          window.location.reload();
          toast.success("Payment Successful");
        } catch (error) {
          toast.error("Payment Failed");
        }
      },
      prefill: {
        name: `${profileData?.profile?.first_name} ${profileData?.profile?.last_name}`,
        email: profileData?.profile?.email,
        contact: profileData?.profile?.phone_number,
      },
      theme: {
        color: "#0D304F",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const [initPayment, setInitPayment] = useState(false);

  const initSubscription = async (id, amount, currency) => {
    try {
      setInitPayment(true);
      const res = await app_api.post("/api/v1/createsubscription/", {
        plan_id: id,
      });
      paymentInit({
        amount: Number(amount),
        currency: currency,
        subId: res.data.subscription.id,
      });
    } catch (error) {
      toast.error("Payment Failed");

      console.log(error);
    } finally {
      setInitPayment(false);
    }
  };

  const { data: dummyPlans, isLoading: loadingPlans } =
    useGetSubscriptionsQuery();

  return (
    <>
      <div className="membershiptop">
        <button
          onClick={() => setSelectedItem("Yearly")}
          className={selectedItem === "Yearly" ? "selectedmembership" : ""}
        >
          Yearly
        </button>
        <button
          onClick={() => setSelectedItem("Monthly")}
          className={selectedItem === "Monthly" ? "selectedmembership" : ""}
        >
          Monthly
        </button>
      </div>
      <div className="membershiprow">
        {dummyPlans?.plans
          ?.filter((plan) => {
            if (selectedItem === "Monthly") {
              return plan.price_time_unit === "MONTH";
            } else {
              return plan.price_time_unit === "YEAR";
            }
          })
          ?.map((plan, index) => (
            <div key={index} className="membershipcard">
              {plan.popular && (
                <div className="popularcardpick">
                  <p>Most Popular</p>
                </div>
              )}
              <div className="membershipcardtop">
                <h4>{plan.plan_name}</h4>
                <p>Best For Personal Use</p>
              </div>
              <div className="membershippricing">
                <h3>
                  <h3>₹ {plan.price}</h3>
                </h3>
                {
                  {
                    Yearly: <p>/Year</p>,
                    Monthly: <p>/month</p>,
                  }[selectedItem]
                }
              </div>

              <div className="membershipcardbottom">
                <h5>All starter features, plus:</h5>
                <ul>
                  <li>
                    <img src={membershipTick} alt="Get" />
                    <p>Lorem ipsum dolor sit amet</p>
                  </li>
                  <li>
                    <img src={membershipTick} alt="Get" />
                    <p>Lorem ipsum dolor sit amet</p>
                  </li>
                  <li>
                    <img src={membershipTick} alt="Get" />
                    <p>Lorem ipsum dolor sit amet</p>
                  </li>
                  <li>
                    <img src={membershipTick} alt="Get" />
                    <p>Lorem ipsum dolor sit amet</p>
                  </li>
                  <li>
                    <img src={membershipTick} alt="Get" />
                    <p>Lorem ipsum dolor sit amet</p>
                  </li>
                </ul>
              </div>
              <button
                onClick={() =>
                  initSubscription(plan.plan_id, plan.price, plan.plan_currency)
                }
                type="button"
                disabled={initPayment}
              >
                Get Started
              </button>
            </div>
          ))}
      </div>
    </>
  );
};
const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString)?.toLocaleDateString(undefined, options);
};
export const HistorySection = ({ handleHistoryAllClick }) => {
  const { data, isLoading } = useGetHistoryQuery();
  const [casestudy, setCasestudy] = useState();
  const [casequestion, setCasequestion] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [qbank, setQbank] = useState();
  const [qbankquestion, setQbankquestion] = useState();
  const [research, setResearch] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const groupedcasequestion = data?.casequestion?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasequestion(groupedcasequestion);

    const groupedcasestudy = data?.casestudy?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasestudy(groupedcasestudy);

    const groupeddiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setDiagnosis(groupeddiagnosis);

    const groupedqbank = data?.qbank?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setQbank(groupedqbank);

    const groupedqbankquestion = data?.qbankquestion?.reduce((acc, item) => {
      const date = formatDate(item.allocated_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setQbankquestion(groupedqbankquestion);

    const groupedresearch = data?.research?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setResearch(groupedresearch);

    const groupedsearch = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setSearch(groupedsearch);
  }, [data]);
  return (
    <>
      {/* <EmptyHistory /> */}
      <div className="historybody">
        <div className="historysections">
          <div
            className={`historyelement ${
              search ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Search</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Search");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {search &&
              Object.entries(search).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        className="cursor-pointer"
                        onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                        key={item.uuid}
                      >
                        {item.query}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              diagnosis ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Diagnose</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Diagnose");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {diagnosis &&
              Object.entries(diagnosis).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        className="cursor-pointer"
                        onClick={() => navigate(`/diagnose?id=${item?.uuid}`)}
                        key={item.uuid}
                      >
                        {item?.quick_query || item?.chief_complaints}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              casestudy ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Case Study</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Case Study");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {casestudy &&
              Object.entries(casestudy).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item, index) => (
                      <p
                        style={{
                          overflow: "hidden",
                        }}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/qbank-question?id=${item.casemaster?.uuid}&source=case`
                          )
                        }
                        key={item.uuid}
                      >
                        {console.log({ item })}
                        {item.id}:{item.casemaster.topics[0]},
                        {item.casemaster.board[0]},
                        {item.casemaster.question_type},
                        {item.casemaster.question_count} Questions,{" "}
                        {item.status}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              casequestion ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Case Question</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Case Question");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {casequestion &&
              Object.entries(casequestion).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>{item.question}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              qbank ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Qbank</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Qbank");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {qbank &&
              Object.entries(qbank).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/qbank-question?id=${item?.uuid}&source=QBANK`
                          )
                        }
                        key={item.uuid}
                      >
                        <p>
                          {index + 1}- No of Question : {item.no_of_question}{" "}
                          {item.subjects.map((d) => d.name).join(", ")}{" "}
                          {item.mode}{" "}
                          {item.subjects.map((d) => d.name).join(", ")}{" "}
                          {item.topics.map((d) => d.name).join(", ")}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              qbankquestion ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Qbank Question</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Qbank Question");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {qbankquestion &&
              Object.entries(qbankquestion).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>{item.question.question}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              research ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Research</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleHistoryAllClick("Research");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {research &&
              Object.entries(research).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        onClick={() => {
                          if (item?.research_type == "literature") {
                            navigate(`/research-literature?id=${item?.uuid}`);
                          } else if (item?.research_type == "article") {
                            navigate(`/research-article?id=${item?.uuid}`);
                          } else if (item?.research_type == "casereport") {
                            navigate(`/research-report?id=${item?.uuid}`);
                          }
                        }}
                        key={item.uuid}
                      >
                        {item.title ? item.title : item.topic}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            |
          </div>
        </div>
      </div>
    </>
  );
};
export const AllSearch = ({ searchTerm, groupres, BookmarkData }) => {
  const [casestudy, setCasestudy] = useState();
  const [casequestion, setCasequestion] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [qbank, setQbank] = useState();
  const [qbankquestion, setQbankquestion] = useState();
  const [research, setResearch] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const groupedcasequestion = BookmarkData?.casequestion?.reduce(
      (acc, item) => {
        const date = formatDate(item.created_at);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      },
      {}
    );
    setCasequestion(groupedcasequestion);

    const groupedcasestudy = BookmarkData?.casestudy?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasestudy(groupedcasestudy);

    const groupeddiagnosis = BookmarkData?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setDiagnosis(groupeddiagnosis);

    const groupedqbank = BookmarkData?.qbank?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setQbank(groupedqbank);

    const groupedqbankquestion = BookmarkData?.qbankquestion?.reduce(
      (acc, item) => {
        const date = formatDate(item.allocated_at);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      },
      {}
    );

    setQbankquestion(groupedqbankquestion);

    const groupedresearch = BookmarkData?.research?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setResearch(groupedresearch);

    const groupedsearch = BookmarkData?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setSearch(groupedsearch);
  }, [BookmarkData]);
  return (
    <>
      <div className="historybody">
        <div className="historyelement">
          <div className="historyelementheader">
            <div className="historyelementleft">
              <h2>{searchTerm}</h2>
            </div>
            <div className="historyelementright">
              {/* <button className="elementselector">Clear History</button> */}
            </div>
          </div>
          <hr />
          {searchTerm == "Search" &&
            search &&
            Object.entries(search).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                      key={item.uuid}
                    >
                      {item.query}
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Diagnose" &&
            diagnosis &&
            Object.entries(diagnosis).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                      key={item.uuid}
                    >
                      {item?.quick_query || item?.chief_complaints}
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Case Study" &&
            casestudy &&
            Object.entries(casestudy).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      style={{
                        overflow: "hidden",
                      }}
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/qbank-question?id=${item.casemaster?.uuid}&source=case`
                        )
                      }
                      key={item.uuid}
                    >
                      {item.id}:{item.casemaster.topics[0]},
                      {item.casemaster.board[0]},{item.casemaster.question_type}
                      ,{item.casemaster.question_count} Questions, {item.status}
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Case Question" &&
            casequestion &&
            Object.entries(casequestion).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>{item.question}</p>
                  ))}
                </div>
              </div>
            ))}
          {searchTerm == "Qbank" &&
            qbank &&
            Object.entries(qbank).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                      onClick={() =>
                        navigate(
                          `/qbank-question?id=${item?.uuid}&source=QBANK`
                        )
                      }
                      key={item.uuid}
                    >
                      <p>
                        {index + 1}- No of Question: {item.no_of_question}{" "}
                        {item.subjects.map((d) => d.name).join(", ")}{" "}
                        {item.mode}{" "}
                        {item.subjects.map((d) => d.name).join(", ")}{" "}
                        {item.topics.map((d) => d.name).join(", ")}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Qbank Question" &&
            qbankquestion &&
            Object.entries(qbankquestion).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>{item.question.question}</p>
                  ))}
                </div>
              </div>
            ))}
          {searchTerm == "Research" &&
            research &&
            Object.entries(research).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      onClick={() => {
                        if (item?.research_type == "literature") {
                          navigate(`/research-literature?id=${item?.uuid}`);
                        } else if (item?.research_type == "article") {
                          navigate(`/research-article?id=${item?.uuid}`);
                        } else if (item?.research_type == "casereport") {
                          navigate(`/research-report?id=${item?.uuid}`);
                        }
                      }}
                      key={item.uuid}
                    >
                      {item.title ? item.title : item.topic}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export const HistoryViewAll = ({ searchTerm, groupres, BookmarkData }) => {
  const { data, isLoading } = useGetHistoryQuery();
  const [casestudy, setCasestudy] = useState();
  const [casequestion, setCasequestion] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [qbank, setQbank] = useState();
  const [qbankquestion, setQbankquestion] = useState();
  const [research, setResearch] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const groupedcasequestion = data?.casequestion?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasequestion(groupedcasequestion);

    const groupedcasestudy = data?.casestudy?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasestudy(groupedcasestudy);

    const groupeddiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setDiagnosis(groupeddiagnosis);

    const groupedqbank = data?.qbank?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setQbank(groupedqbank);

    const groupedqbankquestion = data?.qbankquestion?.reduce((acc, item) => {
      const date = formatDate(item.allocated_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setQbankquestion(groupedqbankquestion);

    const groupedresearch = data?.research?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setResearch(groupedresearch);

    const groupedsearch = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setSearch(groupedsearch);
  }, [data]);
  return (
    <>
      <div className="historybody">
        <div className="historyelement">
          <div className="historyelementheader">
            <div className="historyelementleft">
              <h2>{searchTerm}</h2>
            </div>
            <div className="historyelementright">
              {/* <button className="elementselector">Clear History</button> */}
            </div>
          </div>
          <hr />
          {searchTerm == "Search" &&
            search &&
            Object.entries(search).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <div
                      className="cursor-pointer"
                      onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                      key={item.uuid}
                    >
                      {item.query}
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Diagnose" &&
            diagnosis &&
            Object.entries(diagnosis).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate(`/diagnose?id=${item?.uuid}`)}
                      key={item.uuid}
                    >
                      {item?.quick_query || item?.chief_complaints}
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Case Study" &&
            casestudy &&
            Object.entries(casestudy).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      style={{
                        overflow: "hidden",
                      }}
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/qbank-question?id=${item.casemaster?.uuid}&source=case`
                        )
                      }
                      key={item.uuid}
                    >
                      {item.id}:{item.casemaster.topics[0]},
                      {item.casemaster.board[0]},{item.casemaster.question_type}
                      ,{item.casemaster.question_count} Questions, {item.status}
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Case Question" &&
            casequestion &&
            Object.entries(casequestion).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>{item.question}</p>
                  ))}
                </div>
              </div>
            ))}
          {searchTerm == "Qbank" &&
            qbank &&
            Object.entries(qbank).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/qbank-question?id=${item?.uuid}&source=QBANK`
                        )
                      }
                      key={item.uuid}
                    >
                      <p>
                        {index + 1}- No of Question : {item.no_of_question}{" "}
                        {item.subjects.map((d) => d.name).join(", ")}{" "}
                        {item.mode}{" "}
                        {item.subjects.map((d) => d.name).join(", ")}{" "}
                        {item.topics.map((d) => d.name).join(", ")}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {searchTerm == "Qbank Question" &&
            qbankquestion &&
            Object.entries(qbankquestion).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>{item.question.question}</p>
                  ))}
                </div>
              </div>
            ))}
          {searchTerm == "Research" &&
            research &&
            Object.entries(research).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p
                      onClick={() => {
                        if (item?.research_type == "literature") {
                          navigate(`/research-literature?id=${item?.uuid}`);
                        } else if (item?.research_type == "article") {
                          navigate(`/research-article?id=${item?.uuid}`);
                        } else if (item?.research_type == "casereport") {
                          navigate(`/research-report?id=${item?.uuid}`);
                        }
                      }}
                      key={item.uuid}
                    >
                      {item.title ? item.title : item.topic}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export const Bookmark = () => {
  return (
    <div className="historybody bookmarkbody">
      <div className="historyelement">
        <div className="historyelementheader">
          <div className="historyelementleft">
            <h2>Research</h2>
          </div>
          <div className="historyelementright">
            <button className="elementselectorfilled">View all</button>
          </div>
        </div>
        <hr />
        <h5>18/08/2024</h5>
        <div className="searchresulthistory">
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
          <h5>17/08/2024</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
        </div>
      </div>
      <div className="historyelement">
        <div className="historyelementheader">
          <div className="historyelementleft">
            <h2>Expert Advice</h2>
          </div>
          <div className="historyelementright">
            <button className="elementselectorfilled">View all</button>
          </div>
        </div>
        <hr />
        <h5>18/08/2024</h5>
        <div className="searchresulthistory">
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
          <h5>17/08/2024</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
        </div>
      </div>
      <div className="historyelement">
        <div className="historyelementheader">
          <div className="historyelementleft">
            <h2>Bookmark cases</h2>
          </div>
          <div className="historyelementright">
            <button className="elementselectorfilled">View all</button>
          </div>
        </div>
        <hr />
        <h5>18/08/2024</h5>
        <div className="searchresulthistory">
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
          <h5>17/08/2024</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
        </div>
      </div>
      <div className="historyelement">
        <div className="historyelementheader">
          <div className="historyelementleft">
            <h2>Completed Cases</h2>
          </div>
          <div className="historyelementright">
            <button className="elementselectorfilled">View all</button>
          </div>
        </div>
        <hr />
        <h5>18/08/2024</h5>
        <div className="searchresulthistory">
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
          <h5>17/08/2024</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
            consectetur tortor phasellus mauris justo
          </p>
        </div>
      </div>
    </div>
  );
};

export const AllBookmark = ({ data, handleViewAllClick }) => {
  const [casestudy, setCasestudy] = useState();
  const [casequestion, setCasequestion] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [qbank, setQbank] = useState();
  const [qbankquestion, setQbankquestion] = useState();
  const [research, setResearch] = useState();
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const groupedcasequestion = data?.casequestion?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasequestion(groupedcasequestion);

    const groupedcasestudy = data?.casestudy?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setCasestudy(groupedcasestudy);

    const groupeddiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setDiagnosis(groupeddiagnosis);

    const groupedqbank = data?.qbank?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setQbank(groupedqbank);

    const groupedqbankquestion = data?.qbankquestion?.reduce((acc, item) => {
      const date = formatDate(item.allocated_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setQbankquestion(groupedqbankquestion);

    const groupedresearch = data?.research?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setResearch(groupedresearch);

    const groupedsearch = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    setSearch(groupedsearch);
  }, [data]);
  return (
    <>
      <div className="historybody">
        <div className="historysections">
          <div
            className={`historyelement ${
              search ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Search</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Search");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {search &&
              Object.entries(search).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        className="cursor-pointer"
                        onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                        key={item.uuid}
                      >
                        {item.query}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div
            className={`historyelement ${
              diagnosis ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Diagnosis</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Diagnose");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {diagnosis &&
              Object.entries(diagnosis).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        className="cursor-pointer"
                        onClick={() => navigate(`/aisearch/${item?.uuid}`)}
                        key={item.uuid}
                      >
                        {item?.quick_query || item?.chief_complaints}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div
            className={`historyelement ${
              casestudy ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Case Study</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Case Study");
                  }}
                >
                  {" "}
                  View all{" "}
                </button>
              </div>
            </div>
            <hr />
            {casestudy &&
              Object.entries(casestudy).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        style={{
                          overflow: "hidden",
                        }}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/qbank-question?id=${item.casemaster?.uuid}&source=case`
                          )
                        }
                        key={item.uuid}
                      >
                        {item.id}:{item.casemaster.topics[0]},
                        {item.casemaster.board[0]},
                        {item.casemaster.question_type},
                        {item.casemaster.question_count} Questions,{" "}
                        {item.status}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              casequestion ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Case Question</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Case Question");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {casequestion &&
              Object.entries(casequestion).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>{item.question}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              qbank ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Qbank</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Qbank");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {qbank &&
              Object.entries(qbank).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                        onClick={() =>
                          navigate(
                            `/qbank-question?id=${item?.uuid}&source=QBANK`
                          )
                        }
                        className="cursor-pointer"
                        key={item.uuid}
                      >
                        <p>
                          {index + 1}- No of Question : {item.no_of_question}{" "}
                          {item.subjects.map((d) => d.name).join(", ")}{" "}
                          {item.mode}{" "}
                          {item.subjects.map((d) => d.name).join(", ")}{" "}
                          {item.topics.map((d) => d.name).join(", ")}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              qbankquestion ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Qbank Question</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Qbank Question");
                  }}
                >
                  {" "}
                  View all{" "}
                </button>
              </div>
            </div>
            <hr />
            {qbankquestion &&
              Object.entries(qbankquestion).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>{item.question.question}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`historyelement ${
              research ? "max-h-[30rem] overflow-hidden" : ""
            }`}
          >
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Research</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Research");
                  }}
                >
                  {" "}
                  View all{" "}
                </button>
              </div>
            </div>
            <hr />
            {research &&
              Object.entries(research).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p
                        onClick={() => {
                          if (item?.research_type == "literature") {
                            navigate(`/research-literature?id=${item?.uuid}`);
                          } else if (item?.research_type == "article") {
                            navigate(`/research-article?id=${item?.uuid}`);
                          } else if (item?.research_type == "casereport") {
                            navigate(`/research-report?id=${item?.uuid}`);
                          }
                        }}
                        key={item.uuid}
                      >
                        {item.title ? item.title : item.topic}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default function Profile() {
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [selectedItem, setSelectedItem] = useState("Details");
  const [selectedSearch, setSelectedSearch] = useState("");
  const handleViewAllClick = (searchType) => {
    setSelectedItem("ViewAllBookmark");
    setSelectedSearch(searchType);
  };
  const handleHistoryAllClick = (searchType) => {
    setSelectedItem("ViewAll");
    setSelectedSearch(searchType);
  };
  const [groupres, setgroupres] = useState();
  const [diagnosis, setdiagnosis] = useState();
  const { data, isLoading } = useGetHistoryQuery();
  const { data: bookmarks } = useGetBookmarkQuery();

  useEffect(() => {
    const groupedResults = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setgroupres(groupedResults);

    const groupdiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setdiagnosis(groupdiagnosis);
  }, [data]);

  const params = new URLSearchParams(window.location.search);
  const mode = params.get("page");

  useEffect(() => {
    console.log(mode);
    if (mode?.toLocaleLowerCase() == "history") {
      setSelectedItem("History");
    }
  }, [mode]);

  return (
    <div className="homemain">
      <SideBar
        selected="Profile"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Profile"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        {" "}
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="profileheader">
            <p onClick={() => navigate("/home")}>Back to Dashboard </p>
            <h3>Edit Profile</h3>
          </div>
        </div>
        <div className="profilesections">
          <ul>
            <li
              className={selectedItem === "Details" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("Details")}
            >
              <p>Details</p>
            </li>
            <li
              className={selectedItem === "Membership" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("Membership")}
            >
              <p>Membership</p>
            </li>
            <li
              className={selectedItem === "History" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("History")}
            >
              <p>History</p>
            </li>
            <li
              className={selectedItem === "Bookmark" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("Bookmark")}
            >
              <p>Bookmark</p>
            </li>
          </ul>
        </div>
        <div className="profilebody">
          {
            {
              Details: <DetailsSection />,
              Membership: <MembershipSection />,
              History: (
                <HistorySection handleHistoryAllClick={handleHistoryAllClick} />
              ),
              Bookmark: <Bookmark />,
              ViewAll: (
                <HistoryViewAll
                  searchTerm={selectedSearch}
                  BookmarkData={bookmarks}
                  groupres={groupres}
                  diagnosis={diagnosis}
                />
              ),
              ViewAllBookmark: (
                <AllSearch
                  searchTerm={selectedSearch}
                  BookmarkData={bookmarks}
                  groupres={groupres}
                  diagnosis={diagnosis}
                />
              ),
              Bookmark: (
                <AllBookmark
                  handleViewAllClick={handleViewAllClick}
                  data={bookmarks}
                />
              ),
            }[selectedItem]
          }
        </div>
      </div>
    </div>
  );
}
