import React, { useEffect, useState } from "react";
import "../styles/clinicalhome.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import CompletedCases from "../components/completedcases";
import { useNavigate } from "react-router-dom";
import { GiComputerFan } from "react-icons/gi";
import {
  useCheckRunningTestQuery,
  useCreateCaseStudyMutation,
  useGetAllCaseStudyQuery,
  useGetUserScoreQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { toast } from "react-toastify";
import ScoreBoardBanner from "../components/scoreboardbanner";
import AchievementsRow from "../elements/achievementsrow";
import LoadingScreen from "../elements/loading";
const MedicalRecordicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/medicalrecord.svg";
const RandomIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/random.svg";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const BiochemistryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/biochemistry.svg";
const PathologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pathology.svg";
const PharmacologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pharmacology.svg";
const PhysiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/physiology.svg";
const MicrobiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/microbiology.svg";
const ImmunologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/immunology.svg";
const PublicIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/public.svg";

const GeneralMedicineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/generalmedicine.svg";

const SurgeryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/surgery.svg";

const CardioIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/cardio.svg";
const EndocrineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/endocrine.svg";
const GasIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gas.svg";
const BloodIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/blood.svg";
const OncologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/oncology.svg";
const PaediatricsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/paedtrics.svg";

const GynacIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gynac.svg";

const MuscleIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/muscle.svg";

const NeurologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neurology.svg";

const ReproductionIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/reproduction.svg";

const RespiratoryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/respiratory.svg";

const RenalIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/renal.svg";

const GapLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/line.svg";

const aiimsicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/aiims.svg";
const usmleicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/usmle.svg";
const plabicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/plab.svg";
const neeticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neet.svg";
const mcqicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/mcq.svg";
const detailedicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/detailed.svg";
const TickIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/stepdone.svg";
const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
export default function ClinicalHome() {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const { data: score, isLoading: scoreLoading } = useGetUserScoreQuery();
  const [stateDone, setStateDone] = useState(0);
  const [stateDone2, setStateDone2] = useState(0);
  const [stateDone3, setStateDone3] = useState(0);
  const [stateDone4, setStateDone4] = useState(0);
  const navigate = useNavigate();

  const navigateTo = () => {
    if (selectedValue === 1) {
      navigate(`/clinicalcase/mcq`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (selectedValue === 2) {
      navigate(`/clinicalcase/detailed`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      alert("Please select a question type");
    }
  };
  useEffect(() => {
    if (selectedSubject !== "") {
      setStateDone(1);
    }
  }, [selectedSubject]);
  useEffect(() => {
    if (selectedExam !== "") {
      setStateDone2(1);
    }
  }, [selectedExam]);
  useEffect(() => {
    if (selectedValue !== 0) {
      setStateDone3(1);
    }
  }, [selectedValue]);
  useEffect(() => {
    if (selectedQuestion !== 0) {
      setStateDone4(1);
    }
  }, [selectedQuestion]);
  useEffect(() => {
    console.log(score?.score);
  });
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [shouldFetch, setShouldFetch] = useState(false);
  const testscore = 180;
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldFetch(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  const { data: runningdata, isLoading: runningLoading } =
    useCheckRunningTestQuery({
      skip: !shouldFetch,
    });
  const [createcase, createcasestate] = useCreateCaseStudyMutation();
  const { data, isLoading } = useGetAllCaseStudyQuery();
  useEffect(() => {
    if (runningdata?.running) {
      navigate(`/clinicalcase/questions/${runningdata?.data[0].uuid}`);
    }
  }, [runningdata]);
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);

  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Clinical Case"
      />

      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        {createcasestate?.isLoading ? (
          <LoadingScreen />
        ) : (
          <div className="clinicalbody">
            <div className="clinicalbodyelements clinicalintro">
              <div className="flexrow">
                <img src={MedicalRecordicon} alt="Medical Record" />
                <h4>Welcome to Clinical Case Simulator</h4>
              </div>
              <hr />
              <p>
                Master the Art of Diagnosis, Experience hands-on medical
                training through NeuroGEN’s Clinical Case Simulation. Engage in
                realistic, interactive scenarios that challenge your diagnostic
                skills, clinical reasoning, and decision-making abilities.
                <br />
                Track your progress, earn achievement badges, and elevate your
                expertise in real-world medicine.
              </p>
              <br />

              <p>
                <strong>Transform Learning. Master Medicine.</strong>
              </p>
            </div>
            <div className="clinicalbodyelements desktoponly">
              <div className="clinicalbodytext">
                <div className="clinicalelementleftheader">
                  {stateDone === 0 ? (
                    <div className="clinicalnumber">1</div>
                  ) : (
                    <img
                      src={TickIcon}
                      alt="Tick"
                      className="clinicaltickimage"
                    />
                  )}
                </div>
                <div className="clinicalelementheader">
                  <h6>Select Subject</h6>
                </div>
              </div>
              <div className="clinicalrowwhole">
                <div
                  className={
                    stateDone === 0
                      ? "clinicalrowline"
                      : "clinicalrowline clinicalrowlineselected"
                  }
                ></div>
                <div className="clinicalrow">
                  <div
                    className={
                      selectedSubject === "Random"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Random")}
                  >
                    <img src={RandomIcon} alt="Random" />
                    <p>Random</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Anatomy"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Anatomy")}
                  >
                    <img src={AnatomyIcon} alt="Anatomy" />
                    <p>Anatomy</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Physiology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Physiology")}
                  >
                    <img src={PhysiologyIcon} alt="Physiology" />
                    <p>Physiology</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Biochemistry"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Biochemistry")}
                  >
                    <img src={BiochemistryIcon} alt="Biochemistry" />
                    <p>Biochemistry</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Pharmacology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Pharmacology")}
                  >
                    <img src={PharmacologyIcon} alt="Pharmacology" />
                    <p>Pharmacology</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Pathology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Pathology")}
                  >
                    <img src={PathologyIcon} alt="Pathology" />
                    <p>Pathology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Microbiology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Microbiology")}
                  >
                    <img src={MicrobiologyIcon} alt="Microbiology" />
                    <p>Microbiology</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Immunology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Immunology")}
                  >
                    <img src={ImmunologyIcon} alt="Immunology" />
                    <p>Immunology</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Public"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Public")}
                  >
                    <img src={PublicIcon} alt="Public Health Sciences" />
                    <p>Public Health Sciences</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "General"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("General")}
                  >
                    <img src={GeneralMedicineIcon} alt="General Medicine" />
                    <p>General Medicine</p>
                  </div>
                  <div
                    className={
                      selectedSubject === "Surgery"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Surgery")}
                  >
                    <img src={SurgeryIcon} alt="Surgery" />
                    <p>Surgery</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Cardiology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Cardiology")}
                  >
                    <img src={CardioIcon} alt="Cardiology" />
                    <p>Cardiology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Endocrinology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Endocrinology")}
                  >
                    <img src={EndocrineIcon} alt="Endocrinology" />
                    <p>Endocrinology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Gastroenterology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Gastroenterology")}
                  >
                    <img src={GasIcon} alt="Gastroenterology" />
                    <p>Gastroenterology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Blood"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Blood")}
                  >
                    <img src={BloodIcon} alt="Blood" />
                    <p>Blood</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Oncology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Oncology")}
                  >
                    <img src={OncologyIcon} alt="Oncology" />
                    <p>Oncology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Paediatrics"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Paediatrics")}
                  >
                    <img src={PaediatricsIcon} alt="Paediatrics" />
                    <p>Paediatrics</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Gynaecology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Gynaecology")}
                  >
                    <img src={GynacIcon} alt="Gynaecology" />
                    <p>Gynaecology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Muscle"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Muscle")}
                  >
                    <img src={MuscleIcon} alt="Muscle" />
                    <p>Muscle</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Neurology"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Neurology")}
                  >
                    <img src={NeurologyIcon} alt="Neurology" />
                    <p>Neurology</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Reproduction"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Reproduction")}
                  >
                    <img src={ReproductionIcon} alt="Reproduction" />
                    <p>Reproduction</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Respiratory"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Respiratory")}
                  >
                    <img src={RespiratoryIcon} alt="Respiratory" />
                    <p>Respiratory</p>
                  </div>

                  <div
                    className={
                      selectedSubject === "Renal"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedSubject("Renal")}
                  >
                    <img src={RenalIcon} alt="Renal" />
                    <p>Renal</p>
                  </div>
                </div>
              </div>
              <div className="clinicalbodytext">
                <div className="clinicalelementleftheader">
                  {stateDone2 === 0 ? (
                    <div className="clinicalnumber">2</div>
                  ) : (
                    <img
                      src={TickIcon}
                      alt="Tick"
                      className="clinicaltickimage"
                    />
                  )}
                </div>
                <div className="clinicalelementheader">
                  <h6>Select Exam Type</h6>
                </div>
              </div>
              <div className="clinicalrowwhole">
                <div
                  className={
                    stateDone2 === 0
                      ? "clinicalrowline"
                      : "clinicalrowline clinicalrowlineselected"
                  }
                ></div>
                <div className="clinicalrow clinicalrowexams">
                  <div
                    className={
                      selectedExam === "AIIMS/ INI-CET"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("AIIMS/ INI-CET")}
                  >
                    <img src={aiimsicon} alt="AIIMS" />
                    <p>AIIMS/ INI-CET</p>
                  </div>
                  <div
                    className={
                      selectedExam === "USMLE"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("USMLE")}
                  >
                    <img src={usmleicon} alt="USMLE" />
                    <p>USMLE</p>
                  </div>

                  <div
                    className={
                      selectedExam === "PLAB"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("PLAB")}
                  >
                    <img src={plabicon} alt="PLAB" />
                    <p>PLAB</p>
                  </div>
                  <div
                    className={
                      selectedExam === "NEET-PG"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("NEET-PG")}
                  >
                    <img src={neeticon} alt="NEET" />
                    <p>NEET-PG</p>
                  </div>
                  <div
                    className={
                      selectedExam === "ALL"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("ALL")}
                  >
                    <img src={RandomIcon} alt="All" />
                    <p>All</p>
                  </div>
                </div>
              </div>
              <div className="clinicalbodytext">
                <div className="clinicalelementleftheader">
                  {stateDone3 === 0 ? (
                    <div className="clinicalnumber">3</div>
                  ) : (
                    <img
                      src={TickIcon}
                      alt="Tick"
                      className="clinicaltickimage"
                    />
                  )}
                </div>
                <div className="clinicalelementheader">
                  <h6>Select Question Type</h6>
                </div>
              </div>
              <div className="clinicalrowwhole">
                <div
                  className={
                    stateDone3 === 0
                      ? "clinicalrowline"
                      : "clinicalrowline clinicalrowlineselected"
                  }
                ></div>
                <div className="clinicalrow clinicalrowtype clinicalrowexams">
                  <div
                    className={
                      selectedValue === 1
                        ? "clinicalsubjectselected"
                        : "clinicalsubject clinicalsubjectbuttons"
                    }
                    onClick={() => setSelectedValue(1)}
                  >
                    <img src={mcqicon} alt="MCQ" />
                    <p>MCQ</p>
                  </div>
                  <div
                    className={
                      selectedValue === 2
                        ? "clinicalsubjectselected"
                        : "clinicalsubject clinicalsubjectbuttons"
                    }
                    onClick={() => {
                      setSelectedValue(2);
                      setSelectedQuestion(5);
                      setStateDone4(1);
                    }}
                  >
                    <img src={detailedicon} alt="Elaborative Type" />
                    <p>Elaborative Type</p>
                  </div>
                </div>
              </div>
              {selectedValue === 1 && (
                <>
                  {" "}
                  <div className="clinicalbodytext">
                    <div className="clinicalelementleftheader">
                      {stateDone4 === 0 ? (
                        <div className="clinicalnumber">4</div>
                      ) : (
                        <img
                          src={TickIcon}
                          alt="Tick"
                          className="clinicaltickimage"
                        />
                      )}
                    </div>

                    <div className="clinicalelementheader">
                      <h6>Select Question Number</h6>
                    </div>
                  </div>
                  <div className="clinicalrowwhole">
                    <div
                      className={
                        stateDone4 === 0
                          ? "clinicalrowline clinicalrowlinesmall"
                          : "clinicalrowline clinicalrowlineselected clinicalrowlinesmall"
                      }
                    ></div>
                    <div className="clinicalrow clinicalrowquestions">
                      <div
                        className={
                          selectedQuestion === 1
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(1)}
                      >
                        1
                      </div>
                      <div
                        className={
                          selectedQuestion === 2
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(2)}
                      >
                        2
                      </div>
                      <div
                        className={
                          selectedQuestion === 3
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(3)}
                      >
                        3
                      </div>
                      <div
                        className={
                          selectedQuestion === 4
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(4)}
                      >
                        4
                      </div>
                      <div
                        className={
                          selectedQuestion === 5
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(5)}
                      >
                        5
                      </div>
                      <div
                        className={
                          selectedQuestion === 6
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(6)}
                      >
                        6
                      </div>
                      <div
                        className={
                          selectedQuestion === 7
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(7)}
                      >
                        7
                      </div>
                      <div
                        className={
                          selectedQuestion === 8
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(8)}
                      >
                        8
                      </div>
                      <div
                        className={
                          selectedQuestion === 9
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(9)}
                      >
                        9
                      </div>
                      <div
                        className={
                          selectedQuestion === 10
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(10)}
                      >
                        10
                      </div>
                      <div
                        className={
                          selectedQuestion === 11
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(11)}
                      >
                        11
                      </div>
                      <div
                        className={
                          selectedQuestion === 12
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(12)}
                      >
                        12
                      </div>
                      <div
                        className={
                          selectedQuestion === 13
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(13)}
                      >
                        13
                      </div>
                      <div
                        className={
                          selectedQuestion === 14
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(14)}
                      >
                        14
                      </div>
                      <div
                        className={
                          selectedQuestion === 15
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(15)}
                      >
                        15
                      </div>
                      <div
                        className={
                          selectedQuestion === 16
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(16)}
                      >
                        16
                      </div>
                      <div
                        className={
                          selectedQuestion === 17
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(17)}
                      >
                        17
                      </div>
                      <div
                        className={
                          selectedQuestion === 18
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(18)}
                      >
                        18
                      </div>
                      <div
                        className={
                          selectedQuestion === 19
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(19)}
                      >
                        19
                      </div>
                      <div
                        className={
                          selectedQuestion === 20
                            ? "clinicalquestionselected"
                            : "clinicalquestion"
                        }
                        onClick={() => setSelectedQuestion(20)}
                      >
                        20
                      </div>
                    </div>
                  </div>
                </>
              )}

              {stateDone === 1 &&
                stateDone2 === 1 &&
                stateDone3 === 1 &&
                stateDone4 === 1 && (
                  <button
                    className="clinicalbodyelementbutton"
                    onClick={async () => {
                      const created = await createcase({
                        topics: [selectedSubject],
                        board: [selectedExam],
                        question_type: selectedValue == 1 ? "MCQ" : "EXPLAIN",
                        question_count:
                          selectedValue == 1 ? selectedQuestion : 5,
                      });
                      if (created?.error) {
                        const utcDate = created?.error?.data?.next;
                        const istDate = convertUTCtoIST(utcDate);
                        toast.error(
                          `${created?.error?.data?.error} please try after ${istDate}!`
                        );
                      } else {
                        navigate(
                          `/clinicalcase/questions/${created?.data.uuid}`
                        );
                      }
                    }}
                  >
                    {createcasestate?.isLoading ? (
                      <GiComputerFan className="animate-spin" />
                    ) : (
                      "Start the Case Study"
                    )}
                  </button>
                )}
            </div>

            <div className="clinicalbodyelements achievementsrowmobile">
              <div className="clinicalelementheader">
                <h4>Achievements</h4>
              </div>
              <hr />
              <AchievementsRow UserScore={score?.score} />
            </div>
            <div className="clinicalbodyelements mobileonly">
              <div className="clinicalelementheader">
                <h4>Select Exam Type</h4>
              </div>
              <hr />
              <div className="clinicalrowwhole">
                <div className="clinicalrowmobile ">
                  <div
                    className={
                      selectedExam === "AIIMS/ INI-CET"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("AIIMS/ INI-CET")}
                  >
                    <img src={aiimsicon} alt="AIIMS" />
                    <p>AIIMS/ INI-CET</p>
                  </div>
                  <div
                    className={
                      selectedExam === "USMLE"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("USMLE")}
                  >
                    <img src={usmleicon} alt="USMLE" />
                    <p>USMLE</p>
                  </div>

                  <div
                    className={
                      selectedExam === "PLAB"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("PLAB")}
                  >
                    <img src={plabicon} alt="PLAB" />
                    <p>PLAB</p>
                  </div>
                  <div
                    className={
                      selectedExam === "NEET-PG"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("NEET-PG")}
                  >
                    <img src={neeticon} alt="NEET" />
                    <p>NEET-PG</p>
                  </div>
                  <div
                    className={
                      selectedExam === "ALL"
                        ? "clinicalsubjectselected"
                        : "clinicalsubject"
                    }
                    onClick={() => setSelectedExam("ALL")}
                  >
                    <img src={RandomIcon} alt="All" />
                    <p>All</p>
                  </div>
                </div>
              </div>
              {selectedExam !== "" && (
                <button
                  className="elementselector w-full mt-2"
                  onClick={() => {
                    navigate("/clinicalcase/mobile");
                  }}
                >
                  Continue
                </button>
              )}
            </div>
            <div className="clinicalcasesrow">
              <div className="clinicalbodyelements">
                <div className="clinicalelementheader">
                  <h4>Completed Cases</h4>
                </div>
                <hr />
                <div className="clinicalcolumn">
                  {data?.data
                    ?.filter(
                      (rec) =>
                        rec?.case_studies?.length > 0 &&
                        rec?.case_studies[0]?.status == "COMPLETE"
                    )
                    .map((rec) => (
                      <CompletedCases
                        header={`Case Study for ${rec?.board[0]},${rec?.topics[0]},${rec?.question_type}`}
                        body={`${rec?.case_studies[0]?.content}`}
                      />
                    ))}
                  {}
                </div>
              </div>

              {data?.data?.filter(
                (rec) =>
                  rec?.case_studies?.length > 0 &&
                  rec?.case_studies[0]?.status == "COMPLETE" &&
                  rec?.case_studies[0]?.bookmark
              )?.length > 0 && (
                <div className="clinicalbodyelements">
                  <div className="clinicalelementheader">
                    <h4>Bookmarked Cases</h4>
                  </div>
                  <hr />
                  <div className="clinicalcolumn">
                    {data?.data
                      ?.filter(
                        (rec) =>
                          rec?.case_studies?.length > 0 &&
                          rec?.case_studies[0]?.status == "COMPLETE" &&
                          rec?.case_studies[0]?.bookmark
                      )
                      .map((rec) => (
                        <CompletedCases
                          header={`Case Study for ${rec?.board[0]},${rec?.topics[0]},${rec?.question_type}`}
                          body={`${rec?.case_studies[0]?.content}`}
                        />
                      ))}
                    {/* <CompletedCases
                  header="65 Year Old Man with Sudden Onset of Shortness of Breath"
                  body="A 65 year old man presents to the emergency department complaining of four days of shortness of breath."
                />
                <CompletedCases
                  header="72 Year Old Woman with Worsening Shortness of Breath"
                  body="A 72 year old woman presents to the outpatient clinic complaining of worsening shortness of breath over the past week."
                /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
