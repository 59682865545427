import React, { useState } from 'react'
import SideBar from '../elements/sidebar'
import HomemainHeaderMobile from '../components/homemainheadermobile';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOneBlogQuery } from '../store/General/generalSlice';

function Blog() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
        window.innerWidth < 1000 ? true : false
    );

    const { slug } = useParams();

    const toggleSidebar = () => {
        setIsSidebarCollapsed((prevState) => !prevState);
    };

    const GripLine =
        "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

    const OpenIcon =
        "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
    const CloseIcon =
        "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";

    const navigate = useNavigate();


    const {
        data: blogData,
        error: blogError,
        isLoading: blogIsLoading,
    } = useGetOneBlogQuery(slug);

    return (
        <div className="homemain">
            <SideBar
                selected="Lounge"
                isCollapsed={isSidebarCollapsed}
                onToggle={toggleSidebar}
            />
            <HomemainHeaderMobile
                onToggle={toggleSidebar}
                isSidebarCollapsed={isSidebarCollapsed}
                header="Doctor's Lounge"
            />
            <div
                className="homeright"
                style={{
                    marginLeft: isSidebarCollapsed ? "110px" : "370px",
                }}
            >
                <div className="homemainheader">
                    <div className="homemainleft">
                        <div className="homemainleftinner">
                            <div className="closesidebarbutton" onClick={toggleSidebar}>
                                <img
                                    src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                                    alt="Arrow"
                                />
                            </div>
                            <div
                                className="closesidebarbuttonmobileheader"
                                onClick={toggleSidebar}
                            >
                                <img src={GripLine} alt="Arrow" />
                            </div>
                        </div>
                        <div className="homemainleftouter">

                            <h3>
                                {blogData?.blog?.title}
                            </h3>
                        </div>
                    </div>

                </div>
                <div dangerouslySetInnerHTML={
                    { __html: blogData?.blog?.content }
                } />
            </div>
        </div>
    )
}

export default Blog