import React, { useState } from "react";
import "../styles/login.css";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { setCredentials } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoginMutation } from "../store/profile/UserApiSlice";
import config from "../config/APIEndpoints";
import { toast } from "react-toastify";
const AppleLogo = "https://neuro-gen.s3.amazonaws.com/images/icons/apple.svg";
const GoogleLogo = "https://neuro-gen.s3.amazonaws.com/images/icons/google.svg";
const ViewIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpassword.svg";

const ViewOpen =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpasswordopen.svg";
export const LoginMain = ({ onForgotPassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logincall, loginstate] = useLoginMutation();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [password, setPassword] = useState("");
  const [viewState, setViewState] = useState("password");
  const [email, setEmail] = useState("");
  const token = Cookies.get("token");
  if (token) {
    navigate("/dashboard");
  }
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      const mylogin = await logincall({
        email: values.email,
        password: values.password,
      })
        .then((resp) => {
          if (resp.error) {
            toast.error(resp.error.data.detail);
          } else {
            dispatch(
              setCredentials({
                refresh: resp?.data?.refresh,
                token: resp?.data?.access,
              })
            );
            navigate("/dashboard");
          }
        })
        .catch((err) => console.log({ err }));
      console.log(mylogin);
      // nextStage();
      // doneStage();
    },
  });

  const handleView = () => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
      setViewState("text");
    } else {
      password.type = "password";
      setViewState("password");
    }
  };
  const handleConfirmView = () => {
    const password = document.getElementById("confirmPassword");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  const navigateToSignUp = () => {
    navigate("/register/email");
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .post(`${config.getEndpoint()}/api/v1/auth/google/`, {
          access_token: codeResponse.access_token,
          backend: "google-oauth2",
          grant_type: "convert_token",
          client_id: process.env.CLIENT,
          client_secret: process.env.SECRET,
        })
        .then((res) => {
          dispatch(
            setCredentials({
              refresh: res?.data?.refresh_token,
              token: res?.data?.access_token,
            })
          );
          navigate("/dashboard");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <>
      <div className="loginheader">
        <h2>Welcome</h2>
        <p>Login to NeuroLAB AI to Enter the Dashboard</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="loginform">
          <div className="flex flex-col">
            <label htmlFor="email">Email</label>
            <div className=" flex flex-col">
              <input
                type="email"
                id="email"
                name="email"
                className="w-full p-2 border border-gray-300 outline-none focus:ring-0"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error text-red-500 w-full items-start">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <br />
          <div className="flex flex-col">
            <label htmlFor="password">Password</label>
            <div className="inputdiv flex">
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your Password"
                className="w-full p-2 border border-gray-300 outline-none focus:ring-0"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
              />
              {viewState === "text" ? (
                <img src={ViewOpen} alt="View" onClick={handleView} />
              ) : (
                <img src={ViewIcon} alt="View" onClick={handleView} />
              )}
            </div>{" "}
            {formik.touched.password && formik.errors.password ? (
              <div className="error text-red-500  w-full items-start">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <br />

          <button type="submit">Login</button>
        </div>
      </form>
      <div className="logindivider">
        <hr />
        <p>Or</p>
        <hr />
      </div>
      <div className="loginwith">
        <button onClick={login}>
          <img src={GoogleLogo} alt="Google Logo" />
          <p>Continue with Google</p>
        </button>
      </div>
      <div className="loginfooter">
        <p>Don't have an account?</p>
        <p className="signupbutton" onClick={navigateToSignUp}>
          Sign Up
        </p>
      </div>
      <div className="loginfooter">
        <p>Forgot passowrd?</p>
        <p className="signupbutton" onClick={() => navigate("/forgotpassowrd")}>
          Reset
        </p>
      </div>
    </>
  );
};
export const ForgotPassword = ({ onForgotPassword }) => {
  return (
    <>
      <div className="loginheader">
        <h2>Create New Password</h2>
        <p>Enter your email below to reset your password</p>
      </div>
      <form onSubmit={onForgotPassword}>
        <div className="loginform">
          <label htmlFor="email">Email</label>
          <div className="inputdiv">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <button type="submit">Continue</button>
        </div>
      </form>
    </>
  );
};
export const ResetPassword = () => {
  const navigate = useNavigate();
  const NavigateToLogin = () => {
    navigate("/");
  };
  return (
    <>
      <div className="loginheader">
        <h2>Create New Password</h2>
        <p>Enter your new password</p>
      </div>
      <form onSubmit={NavigateToLogin}>
        <div className="loginform">
          <label htmlFor="password">Password</label>
          <div className="inputdiv">
            <input
              type="password"
              id="password"
              name="password"
              maxLength={12}
              placeholder="Enter your Password"
              required
            />
          </div>
          <br />
          <label htmlFor="password">Password</label>
          <div className="inputdiv">
            <input
              type="password"
              id="password"
              name="password"
              maxLength={12}
              placeholder="Enter your Password"
              required
            />
          </div>
          <button type="submit">Continue</button>
        </div>
      </form>
    </>
  );
};
export default function Login() {
  const [stateLogin, setStateLogin] = useState("Login");
  const handleForgotPassword = () => {
    setStateLogin("Forgot");
  };
  const handleResetPassword = () => {
    setStateLogin("Reset");
  };

  return (
    <div className="loginmain">
      <div className="loginbox">
        {stateLogin === "Login" ? (
          <LoginMain onForgotPassword={handleForgotPassword} />
        ) : (
          ""
        )}
        {stateLogin === "Forgot" ? (
          <ForgotPassword onForgotPassword={handleResetPassword} />
        ) : (
          ""
        )}
        {stateLogin === "Reset" ? <ResetPassword /> : ""}
      </div>
    </div>
  );
}
