import React from "react";

const Achivement1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/1.svg";
const Achivement2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/2.svg";
const Achivement3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/3.svg";
const Achivement4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/4.svg";
const Achivement5 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/5.svg";
const Achivement6 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/6.svg";
const Achivement7 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/7.svg";
const Achivement8 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/8.svg";
const Achivement9 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/9.svg";
const Achivement10 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/10.svg";

const GapLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/line.svg";

export default function AchievementsRow({ UserScore }) {
  const achievements = [
    {
      scoreThreshold: 50,
      imgSrc: Achivement1,
      alt: "Achievement 1",
      title: "Novice",
    },
    {
      scoreThreshold: 100,
      imgSrc: Achivement2,
      alt: "Achievement 2",
      title: "Apprentice",
    },
    {
      scoreThreshold: 250,
      imgSrc: Achivement3,
      alt: "Achievement 3",
      title: "MedExpert",
    },
    {
      scoreThreshold: 500,
      imgSrc: Achivement4,
      alt: "Achievement 4",
      title: "Scholar",
    },
    {
      scoreThreshold: 1000,
      imgSrc: Achivement5,
      alt: "Achievement 5",
      title: "Master Blaster",
    },
    {
      scoreThreshold: 1250,
      imgSrc: Achivement6,
      alt: "Achievement 6",
      title: "Disease Conqueror",
    },
    {
      scoreThreshold: 1500,
      imgSrc: Achivement7,
      alt: "Achievement 7",
      title: "Hero",
    },
    {
      scoreThreshold: 1750,
      imgSrc: Achivement8,
      alt: "Achievement 8",
      title: "Wizard",
    },
    {
      scoreThreshold: 2000,
      imgSrc: Achivement9,
      alt: "Achievement 9",
      title: "Luminary",
    },
    {
      scoreThreshold: 2500,
      imgSrc: Achivement10,
      alt: "Achievement 10",
      title: "Beacon Of Medicine",
    },
  ];
  return (
    <div className="tooltipextender">
      <div className="clinicalrow displayeverywhere clinicalrownolimits">
        {achievements.map((achievement, index) => (
          <React.Fragment key={index}>
            <div className="clinicalachievement">
              <img
                src={achievement.imgSrc}
                alt={achievement.alt}
                className={`${
                  UserScore >= achievement.scoreThreshold
                    ? "opacity-100 scale-125"
                    : "grayscale opacity-50"
                }`}
              />
              <div className="achievementtool">
                <div className="toptriangle"></div>
                <p>
                  {achievement.title} - (
                  {index > 0 ? achievements[index - 1].scoreThreshold : 0},
                  {achievement.scoreThreshold})
                </p>
              </div>
            </div>
            {index < achievements.length - 1 && (
              <div className="achievementgap">
                <img
                  src={GapLine}
                  alt="Gap Line"
                  className={
                    UserScore < achievements[index + 1].scoreThreshold
                      ? "grayscale"
                      : ""
                  }
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
