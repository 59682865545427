import React, { useState, useEffect, useRef } from "react";
import "../styles/diagnose.css";
import SideBar from "../elements/sidebar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import QuickDDX from "../elements/quickddx";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useAddbookmarkMutation,
  useGetDiagnosisMutation,
  useGetGeneratedDiagnosisQuery,
} from "../store/General/generalSlice";
import config from "../config/APIEndpoints";
import { toast } from "react-toastify";
import LoadingScreen from "../elements/loading";
import handleDownload from "../components/helpers";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { useLocation } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";

const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";
const TickIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/stepdone.svg";
const step1selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step1selected.svg";
const step2selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2selected.svg";
const step3selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3selected.svg";
const step4selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4selected.svg";
const step2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2.svg";
const step3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3.svg";
const step4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4.svg";
const infoicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/infocircle.svg";
const chaticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/chat.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

function Skeleton() {
  return (
    <div className="diagnosebodyelement flex flex-row gap-2 h-[30rem]">
      <div class="bg-gray-100 p-4 rounded-lg skeleton  w-full h-full animate-pulse">
        <div class="h-6 bg-gray-200 rounded w-1/4 mb-4 animate-pulse"></div>
        <div class="space-y-4 animate-pulse">
          <div class="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-5/6 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-2/3 animate-pulse"></div>
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg skeleton  w-full h-full animate-pulse">
        <div class="h-6 bg-gray-200 rounded w-1/4 mb-4 animate-pulse"></div>
        <div class="space-y-4 animate-pulse">
          <div class="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-5/6 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-2/3 animate-pulse"></div>
        </div>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg skeleton  w-full h-full animate-pulse">
        <div class="h-6 bg-gray-200 rounded w-1/4 mb-4 animate-pulse"></div>
        <div class="space-y-4 animate-pulse">
          <div class="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-5/6 animate-pulse"></div>
          <div class="h-6 bg-gray-200 rounded w-2/3 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
export const HistoryElement = ({
  finalstage,
  nextState,
  savechanges,
  currentstate,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const formik = useFormik({
    initialValues: {
      chief_complaints: "",
      duration_time: "Days",
      duration_days: "",
      symptoms_and_history: "",
    },
    validationSchema: Yup.object().shape({
      chief_complaints: Yup.string().required("Chief Complaint(s) is required"),
      duration_time: Yup.string().typeError("Time period must be a string"),
      duration_days: Yup.number().typeError("Duration days must be a number"),
      symptoms_and_history: Yup.string(),
    }),
    onSubmit: (values) => {
      // console.log(formik.errors);
      savechanges(values);
      nextState(); // Replace with your submit function
    },
  });
  useEffect(() => {
    formik.setValues({
      chief_complaints: currentstate?.chief_complaints,
      duration_time: currentstate?.duration_time,
      duration_days: currentstate?.duration_days,
      symptoms_and_history: currentstate?.symptoms_and_history,
    });
  }, [currentstate]);
  return (
    <>
      <div className="diagnosebodyelement">
        <div className="diagnosebodyelementheader">
          <h4>History of Present Illness</h4>
        </div>
        <hr />
        <div className="diagnosebodycontent">
          <p className="diagnosewarning">
            Enter information related to your case
          </p>
          <div className="diagnosequick">
            <div className="diagnosequickleft">
              <img src={infoicon} alt="Info" />
              <p>
                <strong>Quick DDx: </strong>Don’t have time to fill out all of
                the fields? Describe some of the key information instead.
              </p>
            </div>
            <div className="diagnosequickright" onClick={togglePopup}>
              <button>
                <img src={chaticon} alt="Chat" />
                Try Quick DDx
              </button>
              <QuickDDX
                isVisible={isPopupVisible}
                onClose={togglePopup}
                finalstage={finalstage}
              />
            </div>
          </div>
          <div className="formtemplate">
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full">
                <h5>
                  Chief Complaint(s)<sup>*</sup>
                </h5>
                <input
                  type="text"
                  name="chief_complaints"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.chief_complaints}
                  placeholder="Example : Headache with morning rise Nausea & Vomiting"
                  required
                />
                {formik.touched.chief_complaints &&
                formik.errors.chief_complaints ? (
                  <div className="error text-red-500">
                    {formik.errors.chief_complaints}
                  </div>
                ) : null}
              </div>
              <div className="inputformdivrow">
                <div className="inputformdivhalf">
                  <h5>
                    Duration<sup>*</sup>
                  </h5>
                  <input
                    type="number"
                    name="duration_days"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duration_days}
                    placeholder="Time"
                    required
                  />
                  {formik.touched.duration_days &&
                  formik.errors.duration_days ? (
                    <div className="error text-red-500">
                      {formik.errors.duration_days}
                    </div>
                  ) : null}
                </div>
                <div className="inputformdivhalf ">
                  <h5>Time Period</h5>
                  <div className="inputdivselect flex flex-col gap-3">
                    <select
                      name="duration_time"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.duration_time}
                    >
                      <option>Day</option>
                      <option>Week</option>
                      <option>Month</option>
                      <option>Year</option>
                    </select>
                  </div>
                  {formik.touched.duration_time &&
                  formik.errors.duration_time ? (
                    <div className="error text-red-500">
                      {formik.errors.duration_time}
                    </div>
                  ) : null}
                </div>
              </div>
              <h5>Associated Symptoms & Additional History</h5>
              <textarea
                name="symptoms_and_history"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.symptoms_and_history}
                placeholder="Example : 2 Attacks of Tonic Clonic Jerky Involuntary Movements with Post Attack Unconsciousness & Dizziness."
              />
              {formik.touched.symptoms_and_history &&
              formik.errors.symptoms_and_history ? (
                <div className="error text-red-500">
                  {formik.errors.symptoms_and_history}
                </div>
              ) : null}
              <div className="contentright">
                <button type="submit" className="elementselectorfilled">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export const CaseElement = ({
  prevState,
  nextState,
  savechanges,
  currentstate,
}) => {
  const genderOptions = ["Male", "Female", "Other"];
  const validationSchema = Yup.object({
    age: Yup.number()
      .required("Age is required")
      .positive("Age must be positive")
      .integer("Age must be a whole number"),
    sex: Yup.string().required("Gender is required"),
    PMHx: Yup.string(),
    Meds: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      age: "",
      sex: "",
      PMHx: "",
      Meds: "",
    },
    validationSchema,
    onSubmit: (values) => {
      savechanges(values);
      nextState(); // Proceed to the next state with form submission
    },
  });
  useEffect(() => {
    formik.setValues({
      age: currentstate?.age,
      sex: currentstate?.sex,
      PMHx: currentstate?.PMHx,
      Meds: currentstate?.Meds,
    });
  }, [currentstate]);
  return (
    <>
      <div className="diagnosebodyelement">
        <div className="diagnosebodyelementheader">
          <h4>Simulated Case Information</h4>
        </div>
        <hr />
        <div className="diagnosebodycontent">
          <div className="formtemplate">
            <form onSubmit={formik.handleSubmit}>
              <div className="inputformdivrow">
                <div className="inputformdivhalf">
                  <h5>
                    Age<sup>*</sup>
                  </h5>
                  <input
                    type="number"
                    name="age"
                    placeholder="Years"
                    value={formik.values.age}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.age && formik.errors.age ? (
                    <div className="error text-red-500">
                      {formik.errors.age}
                    </div>
                  ) : null}
                </div>
                <div className="inputformdivhalf">
                  <h5>
                    Sex<sup>*</sup>
                  </h5>
                  <div className="inputdivselect flex flex-col gap-">
                    <select
                      id="sex"
                      name="sex"
                      value={formik.values.sex}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {genderOptions.map((gender, index) => (
                        <option key={index} value={gender}>
                          {gender}
                        </option>
                      ))}
                    </select>
                    {formik.touched.sex && formik.errors.sex ? (
                      <div className="error  text-red-500 w-full items-start justify-start">
                        {formik.errors.sex}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <h5>PMHx</h5>
              <input
                type="text"
                name="PMHx"
                placeholder="Past Medical History, Example : Lung Ca, HTN, Tb, CAD, Asthma...."
                value={formik.values.PMHx}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.PMHx && formik.errors.PMHx ? (
                <div className="error  text-red-500">{formik.errors.PMHx}</div>
              ) : null}

              <h5>Meds</h5>
              <input
                type="text"
                name="Meds"
                placeholder="Example : Telmisartan 40"
                value={formik.values.Meds}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.Meds && formik.errors.Meds ? (
                <div className="error  text-red-500">{formik.errors.Meds}</div>
              ) : null}

              <div className="contentright">
                <button
                  type="button"
                  className="elementselector"
                  onClick={prevState}
                >
                  Back
                </button>
                <button type="submit" className="elementselectorfilled">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export const ExaminationElement = ({
  prevState,
  nextState,
  savechanges,
  currentstate,
  fileName,
  setFileName,
  file,
  setFile,
}) => {
  // Formik configuration
  const formik = useFormik({
    initialValues: {
      vitals: "",
      examFindings: "",
      specificInfo: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      vitals: Yup.string().required("Vitals are required"),
      examFindings: Yup.string(),
      specificInfo: Yup.string(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("Virals", values.vitals);
      formData.append("findings", values.examFindings);
      formData.append("other_information", values.specificInfo);
      if (file) formData.append("file_uploads", file);

      savechanges(formData);
      nextState();
    },
  });
  useEffect(() => {
    formik.setValues({
      vitals: currentstate?.vitals,
      examFindings: currentstate?.examFindings,
      specificInfo: currentstate?.specificInfo,
    });
  }, [currentstate]);

  return (
    <>
      <div className="diagnosebodyelement">
        <div className="diagnosebodyelementheader">
          <h4>Objective</h4>
        </div>
        <hr />
        <div className="diagnosebodycontent">
          <div className="formtemplate">
            <form onSubmit={formik.handleSubmit}>
              <div className="inputformdivrow">
                <div className="inputformdivhalf">
                  <h5>
                    Vitals<sup>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="vitals"
                    placeholder="Temp : 99.7, RR : 20, HR : 98, BP : 140/80"
                    value={formik.values.vitals}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.vitals && formik.errors.vitals
                        ? "input-error"
                        : ""
                    }
                    required
                  />
                  {formik.touched.vitals && formik.errors.vitals ? (
                    <div className="error text-red-500">
                      {formik.errors.vitals}
                    </div>
                  ) : null}
                </div>
              </div>
              <h5>Notable Exam Findings</h5>
              <textarea
                name="examFindings"
                placeholder="Enter the System-Wise - Inspectory, Palpatory, Percussion & Auscultatory Findings."
                value={formik.values.examFindings}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.examFindings && formik.errors.examFindings
                    ? "textarea-error"
                    : ""
                }
              />
              {formik.touched.examFindings && formik.errors.examFindings ? (
                <div className="error text-red-500">
                  {formik.errors.examFindings}
                </div>
              ) : null}
              <h5>Any Other Specific Information</h5>
              <textarea
                name="specificInfo"
                placeholder="Enter Here"
                value={formik.values.specificInfo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.specificInfo && formik.errors.specificInfo
                    ? "textarea-error"
                    : ""
                }
              />
              {formik.touched.specificInfo && formik.errors.specificInfo ? (
                <div className="error text-red-500">
                  {formik.errors.specificInfo}
                </div>
              ) : null}
              <h5>Add Lab & Imaging File</h5>
              <input
                type="file"
                id="file"
                accept=".pdf, image/*"
                onChange={(e) => {
                  console.log(e.target.files);
                  // setFile(prev => [...prev, e.target.files[0]]);
                  const oldFile = e.target.files[0];

                  setFile(oldFile);
                  setFileName(oldFile.name);
                }}
                style={{ display: "none" }}
              />
              <p>Image Upload/ PDF Upload</p>
              <div className="fileupload">
                {/*  */}

                <label htmlFor="file" style={{ cursor: "pointer" }}>
                  <span>{fileName}</span>
                  {!file && <img src={UploadIcon} alt="Upload" />}
                </label>
                <div className="relative w-fit">
                  {file && (
                    <FaXmark
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                      size={30}
                      className="absolute top-0 right-0"
                      onClick={() => {
                        setFile();
                        setFileName("Upload Here");
                      }}
                    />
                  )}
                  {file && (
                    <img
                      style={{
                        height: "10rem",
                      }}
                      className="rounded-[1rem]"
                      src={URL.createObjectURL(file)}
                      alt="Upload"
                    />
                  )}
                </div>
              </div>
              <div className="contentright">
                <button
                  type="button"
                  className="elementselector"
                  onClick={() => prevState(formik.values)}
                >
                  Back
                </button>
                <button type="submit" className="elementselectorfilled">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export const PreviewElement = ({ prevState, htmltorender }) => {
  const elementRef = useRef();
  const [bookmark, setBookmark] = useState(false);

  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  // const handleDownload = () => {
  //   const element = elementRef.current;

  //   html2canvas(element).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");

  //     // Define padding for the PDF
  //     const padding = 10; // 10mm padding around the content
  //     const pdfWidth = 210; // A4 width in mm
  //     const pdfHeight = 297; // A4 height in mm
  //     const imgWidth = pdfWidth - 2 * padding; // Adjust image width for padding
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height with respect to the new width
  //     let heightLeft = imgHeight;
  //     let position = padding; // Start position considering the padding

  //     // Add the image to the PDF with the defined padding
  //     pdf.addImage(imgData, "PNG", padding, position, imgWidth, imgHeight);
  //     heightLeft -= pdfHeight - 2 * padding; // Adjust for padding on both top and bottom

  //     // Add pages if content exceeds one page
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight + padding;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", padding, position, imgWidth, imgHeight);
  //       heightLeft -= pdfHeight - 2 * padding;
  //     }

  //     pdf.save("download.pdf");
  //   });
  // };

  useEffect(() => {
    if (bookmarkError) {
      toast.error("Error adding to bookmark");
    } else if (bo) {
      toast.info("Added to bookmark");
    }
  }, [bookmarkError, bo]);

  const [isTreatmentVisible, setIsTreatmentVisible] = useState(false);

  return (
    <>
      <div className="contentright">
        <p className="backbuttonrightcontent" onClick={prevState}>
          Back To Generator
        </p>
      </div>
      <div className="diagnosebodyelement">
        <div className="contentright">
          <div
            className="elementselector bookmarkselector"
            onClick={async () => {
              setBookmark(!bookmark);
              await addBookmark({
                page: "bookmarkdiagnosis",
                id: htmltorender?.uuid,
              });
            }}
          >
            {bookmark ? (
              <img src={bookmarkIcon} alt="Bookmark" />
            ) : (
              <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
            )}
          </div>
          <button
            onClick={() => handleDownload(elementRef)}
            className="elementselector"
          >
            Download
          </button>
        </div>
        <div className="diagnosebodycontent">
          {htmltorender?.files?.map((rec) => (
            <img
              style={{ maxHeight: "10rem", borderRadius: "1rem" }}
              src={`${config.getEndpoint()}${rec?.file}`}
            />
          ))}
          <div
            ref={elementRef}
            dangerouslySetInnerHTML={{
              __html: htmltorender?.generated_response,
            }}
            className="diagnosebodycontentrow"
          ></div>
        </div>
        <div className="dashboardresponsefeedback">
          <p>Do you like the Response?</p>
          <img src={ThumbsUp} alt="Thumbs Up" />
          <img src={ThumbsDown} alt="Thumbs Down" />
        </div>
      </div>
      <p className="usagewarning">
        **This is intended for educational purpose only!
      </p>
      {isTreatmentVisible && (
        <div className="diagnosebodyelement">
          <div className="diagnosebodycard">
            <div className="diagnosebodycardheader">
              <h4 className="diagnosetreatment">Treatment Plan</h4>
              <hr />
              <div className="diagnosebodycardbody">
                <p className="diagnosewarning">
                  Here are the possible diagnoses for the case
                </p>
                <h5>Viral Upper Respiratory Infection (URI) </h5>

                <strong>Rationale</strong>
                <p>
                  A common cause of fever in young children, especially given
                  the short duration of symptoms. Usually associated with other
                  symptoms such as a runny nose, cough, and sore throat.
                </p>
                <strong>Additional Diagnostics</strong>
                <p>
                  Clinical evaluation; supportive care with symptomatic
                  treatment; consider a respiratory viral panel if symptoms
                  persist or worsen.
                </p>
              </div>
            </div>
          </div>
          <div className="contentright">
            <button className="elementselector">Expert Insights</button>
            <button className="elementselector">Share it with a Friend</button>
            <button className="elementselector">Download</button>
          </div>
        </div>
      )}
    </>
  );
};

function objectToFormData(obj, formData = new FormData(), parentKey = "") {
  for (const [key, value] of Object.entries(obj)) {
    const fullKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof Date) {
      formData.append(fullKey, value.toISOString());
    } else if (value instanceof File) {
      formData.append(fullKey, value);
    } else if (typeof value === "object" && value !== null) {
      objectToFormData(value, formData, fullKey);
    } else {
      formData.append(fullKey, value);
    }
  }

  return formData;
}
function mergeFormData(formData1, formData2) {
  const mergedFormData = new FormData();

  // Append all entries from the first FormData
  for (const [key, value] of formData1.entries()) {
    mergedFormData.append(key, value);
  }

  // Append all entries from the second FormData
  for (const [key, value] of formData2.entries()) {
    mergedFormData.append(key, value);
  }

  return mergedFormData;
}
const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};

export default function Diagnose() {
  const [diagnoseState, setDiagnoseState] = useState("History");
  const [historystate, sethistorystate] = useState({ type: "Form" });
  // const [formdata, setformdata] = useState();
  const [diagnosis, diagnosisstate] = useGetDiagnosisMutation();
  const [response, setresponse] = useState();
  const [doneStage, setDoneStage] = useState(0);
  const params = new URLSearchParams(window.location.search);
  const myParam = params.get("id");
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);
  const { data: diagnosisdata, isLoading: diagnosisLoading } =
    useGetGeneratedDiagnosisQuery(myParam, { skip: !myParam });
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [historyData, setHistoryData] = useState({
    Complaints: "",
    Duration: "",
    Days: "",
    Symptoms: "",
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );

  const stagetwo = () => {
    // e.preventDefault();
    setDiagnoseState("Case");
    setDoneStage(1);
  };
  const stagethree = () => {
    setDiagnoseState("Examination");
    setDoneStage(2);
  };
  const stageone = () => {
    setDiagnoseState("History");
    setDoneStage(0);
  };
  const stagefour = () => {
    setDiagnoseState("Preview");
    setDoneStage(3);
  };

  const location = useLocation();

  const diagnosisResult = location.state?.diagnosisResult;

  const finalstage = async (value) => {
    setDiagnoseState("Preview");
    // const myform = objectToFormData({ quick_query: value, type: "Quick" });
    const resp = await diagnosis(value);
    if (resp?.error) {
      const utcDate = resp?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(`${resp?.error?.data?.error} please try after ${istDate}!`);
    } else {
      setresponse(resp.data.data);
      setDoneStage(3);
    }
  };
  useEffect(() => {
    if (myParam) {
      setDiagnoseState("Preview");
      setDoneStage(3);

      setresponse(diagnosisdata?.data);
    }
  }, [diagnosisdata]);
  useEffect(() => {
    if (diagnosisResult) {
      setDiagnoseState("Preview");
      setDoneStage(3);
      setresponse(diagnosisResult?.data);
    }
  }, [diagnosisResult]);

  return (
    <div className="homemain">
      <SideBar
        selected="Diagnose"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        header="Diagnose"
        isSidebarCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="homemainleftouter">
            <h3>Guiding You to the Right Diagnosis</h3>
          </div>
        </div>
        <div className="diagnosestatus desktoponly">
          <div
            className={
              diagnoseState === "Case" || doneStage === 1 || doneStage === 2
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 1 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : (
              <img src={step1selected} alt="Step 1" />
            )}
            <h5>History of Present Illness</h5>
          </div>
          <hr
            className={
              diagnoseState === "Case" || doneStage >= 1
                ? "diagnoseactivehr"
                : ""
            }
          />
          <div
            className={
              diagnoseState === "Examination" || doneStage === 2
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 2 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 1 ? (
              <img src={step2selected} alt="Step 2" />
            ) : (
              <img src={step2} alt="Step 2" />
            )}
            <h5>Simulated Case Information</h5>
          </div>
          <hr
            className={
              diagnoseState === "Examination" || doneStage >= 2
                ? "diagnoseactivehr"
                : ""
            }
          />

          <div
            className={
              diagnoseState === "Confirmation" || doneStage === 3
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 3 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 2 ? (
              <img src={step3selected} alt="Step 3" />
            ) : (
              <img src={step3} alt="Step 3" />
            )}
            <h5>Examination & Investigatory Findings</h5>
          </div>
          <hr
            className={
              diagnoseState === "Preview" || doneStage >= 4
                ? "diagnoseactivehr"
                : ""
            }
          />
          <div
            className={
              diagnoseState === "Preview" || doneStage === 4
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage > 4 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 3 ? (
              <img src={step4selected} alt="Step 4" />
            ) : (
              <img src={step4} alt="Step 4" />
            )}
            <h5>Diagnosis Preview</h5>
          </div>
        </div>
        <div className="diagnosebody w-full">
          <div className="diagnosemobileview mobileonly">
            <div
              className={
                diagnoseState === "Case" || doneStage === 1 || doneStage === 2
                  ? "diagnoseactive"
                  : "diagnosedue"
              }
            >
              {doneStage >= 1 ? (
                <img src={TickIcon} alt="Done" className="tickimg" />
              ) : (
                <img src={step1selected} alt="Step 1" />
              )}
              <h5>History of Present Illness</h5>
            </div>
            <div className="diagnosebodyrow mobileonly">
              <div className="diagnosebodyrowleft">
                <hr
                  className={
                    diagnoseState === "Case" || doneStage >= 1
                      ? "diagnoseactivehr"
                      : ""
                  }
                />
              </div>
              <div className="diagnosebodyrowright">
                {diagnoseState === "History" && (
                  <HistoryElement
                    finalstage={finalstage}
                    nextState={stagetwo}
                    currentstate={historystate}
                    savechanges={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={
                diagnoseState === "Examination" || doneStage === 2
                  ? "diagnoseactive"
                  : "diagnosedue"
              }
            >
              {doneStage >= 2 ? (
                <img src={TickIcon} alt="Done" className="tickimg" />
              ) : doneStage === 1 ? (
                <img src={step2selected} alt="Step 2" />
              ) : (
                <img src={step2} alt="Step 2" />
              )}
              <h5>Simulated Case Information</h5>
            </div>
            <div className="diagnosebodyrow mobileonly">
              <div className="diagnosebodyrowleft">
                <hr
                  className={
                    diagnoseState === "Examination" || doneStage >= 2
                      ? "diagnoseactivehr"
                      : ""
                  }
                />
              </div>
              <div className="diagnosebodyrowright">
                {diagnoseState === "Case" && (
                  <CaseElement
                    nextState={stagethree}
                    prevState={stageone}
                    currentstate={historystate}
                    savechanges={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={
                diagnoseState === "Confirmation" || doneStage >= 3
                  ? "diagnoseactive"
                  : "diagnosedue"
              }
            >
              {doneStage >= 3 ? (
                <img src={TickIcon} alt="Done" className="tickimg" />
              ) : doneStage === 2 ? (
                <img src={step3selected} alt="Step 3" />
              ) : (
                <img src={step3} alt="Step 3" />
              )}
              <h5>Examination & Investigatory Findings</h5>
            </div>

            <div className="diagnosebodyrow mobileonly">
              <div className="diagnosebodyrowleft">
                <hr
                  className={
                    diagnoseState === "Preview" || doneStage >= 3
                      ? "diagnoseactivehr"
                      : ""
                  }
                />
              </div>
              <div className="diagnosebodyrowright">
                {diagnoseState === "Examination" && (
                  <ExaminationElement
                    nextState={stagefour}
                    fileName={fileName}
                    setFileName={setFileName}
                    file={file}
                    setFile={setFile}
                    currentstate={historystate}
                    prevState={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                      stagetwo();
                    }}
                    savechanges={async (values) => {
                      const restform = objectToFormData(historystate);
                      const mergedFormData = mergeFormData(restform, values);
                      const resp = await diagnosis(mergedFormData);
                      setresponse(resp.data.data);
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={
                diagnoseState === "Preview" || doneStage === 4
                  ? "diagnoseactive"
                  : "diagnosedue"
              }
            >
              {doneStage > 4 ? (
                <img src={TickIcon} alt="Done" className="tickimg" />
              ) : doneStage === 3 ? (
                <img src={step4selected} alt="Step 4" />
              ) : (
                <img src={step4} alt="Step 4" />
              )}
              <h5>Dianosis Preview</h5>
            </div>

            <div className="diagnosebodyrow mobileonly">
              <div className="diagnosebodyrowleft">
                <hr
                  className={
                    diagnoseState === "Diagnosis" || doneStage === 4
                      ? "diagnoseactivehr"
                      : ""
                  }
                />
              </div>
              <div className="diagnosebodyrowright">
                {diagnoseState === "Preview" &&
                  (diagnosisstate?.isLoading ? (
                    <LoadingScreen />
                  ) : (
                    <PreviewElement
                      prevState={stageone}
                      htmltorender={response}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="desktoponly">
            {
              {
                History: (
                  <HistoryElement
                    finalstage={finalstage}
                    nextState={stagetwo}
                    currentstate={historystate}
                    savechanges={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                    }}
                  />
                ),
                Case: (
                  <CaseElement
                    nextState={stagethree}
                    prevState={stageone}
                    currentstate={historystate}
                    savechanges={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                    }}
                  />
                ),
                Examination: (
                  <ExaminationElement
                    fileName={fileName}
                    setFileName={setFileName}
                    file={file}
                    setFile={setFile}
                    nextState={stagefour}
                    currentstate={historystate}
                    prevState={(values) => {
                      sethistorystate((prev) => {
                        return { ...prev, ...values };
                      });
                      stagetwo();
                    }}
                    savechanges={async (values) => {
                      const restform = objectToFormData(historystate);
                      const mergedFormData = mergeFormData(restform, values);
                      const resp = await diagnosis(mergedFormData);
                      setresponse(resp.data.data);
                    }}
                  />
                ),
                Preview: diagnosisstate?.isLoading ? (
                  // <Skeleton />
                  <div className="fullscreen">
                    <LoadingScreen />
                  </div>
                ) : (
                  <PreviewElement
                    prevState={stageone}
                    htmltorender={response}
                  />
                ),
              }[diagnoseState]
            }
          </div>
        </div>
      </div>
    </div>
  );
}
