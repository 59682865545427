import React, { useState, useEffect, useRef } from "react";
import "../styles/qbank.css";
import SideBar from "../elements/sidebar";
import { useNavigate } from "react-router-dom";
import { useGetsubjectQuery } from "../store/QuestionBamk/QBApiSlice";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { useGetQbankMutation } from "../store/QuestionBamk/QBApiSlice";
import { toast } from "react-toastify";
import LoadingScreen from "../elements/loading";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";

const TickIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/stepdone.svg";
const step1selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step1selected.svg";
const step2selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2selected.svg";
const step3selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3selected.svg";
const step4selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4selected.svg";
const step2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2.svg";
const step3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3.svg";
const step4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4.svg";

const SearchIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/search.svg";

const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const BiochemistryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/biochemistry.svg";
const PathologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pathology.svg";
const PharmacologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pharmacology.svg";
const PhysiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/physiology.svg";
const MicrobiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/microbiology.svg";
const ImmunologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/immunology.svg";
const PublicIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/public.svg";

const GeneralMedicineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/generalmedicine.svg";

const SurgeryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/surgery.svg";

const CardioIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/cardio.svg";
const EndocrineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/endocrine.svg";
const GasIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gas.svg";
const BloodIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/blood.svg";
const OncologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/oncology.svg";
const PaediatricsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/paedtrics.svg";

const GynacIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gynac.svg";

const MuscleIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/muscle.svg";

const NeurologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neurology.svg";

const ReproductionIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/reproduction.svg";

const RespiratoryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/respiratory.svg";

const RenalIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/renal.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

const aiimsicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/aiims.svg";
const usmleicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/usmle.svg";
const plabicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/plab.svg";
const neeticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neet.svg";
const mcqicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/mcq.svg";
const detailedicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/detailed.svg";

export const SubjectComponent = ({
  nextStage,
  subjectsapi,
  setselected,
  selected,
}) => {
  const subjects = [
    { name: "Anatomy", icon: AnatomyIcon },
    { name: "Physiology", icon: PhysiologyIcon },
    { name: "GeneralMedicine", icon: GeneralMedicineIcon },
    { name: "Biochemistry", icon: BiochemistryIcon },
    { name: "Oncology", icon: OncologyIcon },
    { name: "Pathology", icon: PathologyIcon },
    { name: "Pharmacology", icon: PharmacologyIcon },
    { name: "Microbiology", icon: MicrobiologyIcon },
    { name: "Immunology", icon: ImmunologyIcon },
    { name: "Public Health", icon: PublicIcon },
    { name: "Paediatrics", icon: PaediatricsIcon },
    { name: "Obstetrician Gynecologist", icon: GynacIcon },
  ];
  const [subj, setsubj] = useState([]);
  useEffect(() => {
    const mysubjects = subjectsapi
      ?.map((rec) => ({
        ...rec,
        icon: subjects?.filter((my) => my.name == rec?.name)[0].icon,
      }))
      .filter((rec) => rec != null);
    setsubj(mysubjects);
  }, [subjectsapi]);
  return (
    <div className="clinicalbodyelements">
      <div className="homemainrightheader mb-5 mobileonly">
        <h4>Select Subject</h4>
        <div className="inputdiv">
          <img src={SearchIcon} alt="Search" />
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <div className="subjectqbankrow">
        {subj?.map((subject) => (
          <div
            key={subject.name}
            className={
              selected?.subjects.filter((item) => item?.name == subject.name)
                .length > 0
                ? "subjectqbankselected subjectqbank"
                : "subjectqbankunselected subjectqbank"
            }
            onClick={() => {
              setselected((prev) => {
                return prev?.subjects?.filter(
                  (item) => item?.name == subject.name
                ).length > 0
                  ? {
                      ...prev,
                      subjects: prev?.subjects?.filter(
                        (item) => item?.name !== subject.name
                      ),
                    }
                  : { ...prev, subjects: [...prev?.subjects, subject] };
              });
            }}
          >
            <div className="qbanksubjectleft">
              <div className="qbankcircle"></div>
            </div>
            <img src={subject.icon} alt={subject.name} />
            <div className="qbanksubjectright">
              <div className="qbanksubjectrighttop">
                <h5>{subject.name}</h5>
              </div>
              <div className="qbankprogress">
                <div className="qbankprogressinner"></div>
              </div>
              <p>20 Modules</p>
            </div>
          </div>
        ))}
        <button
          className="clinicalbodyelementbutton fixedbutton"
          onClick={() => {
            if (selected?.subjects?.length == 0) {
              toast.warn("Atlease select one subject!");
              return;
            }
            nextStage();
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
export const SubCategory = ({
  nextStage,
  selected,
  setselected,
  subjectsapi,
}) => {
  const [selectOption, setSelectOption] = useState("All");
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const options = [
    "Histology",
    "NeuroAnatomy",
    "Head and Neck",
    "Upper Limb",
    "Back",
    "Embryology",
    "Joints & Epiphysis",
    "Lower Limb",
  ];
  const [generating, setgenerating] = useState();
  useEffect(() => {
    if (selected?.subjects?.length > 1) setSelectOption("All");
  }, [selected]);
  return (
    <div className="subcategoryrow">
      <div className="subcategoryrowleft">
        <SubjectComponent
          selected={selected}
          setselected={setselected}
          subjectsapi={subjectsapi}
        />
      </div>
      <div className="subcategoryrowright">
        <div className="subcategoryrowrightheader">
          <h3>Select Sub Category</h3>
        </div>
        <hr />
        <div className="subcategoryrowrightinner">
          <div className="subcategorybuttons">
            <button
              className={
                selectOption === "All"
                  ? "subcategorybuttonselected"
                  : "subcategorybutton"
              }
              onClick={() => {
                setSelectOption("All");
                {
                  selected?.subjects?.map((option) =>
                    option?.topics?.map((rec) =>
                      setselected((prev) => ({
                        ...prev,
                        topics: [...prev.topics, rec?.id],
                      }))
                    )
                  );
                }
              }}
            >
              All
            </button>
            <button
              className={
                selectOption === "Choose"
                  ? "subcategorybuttonselected"
                  : "subcategorybutton"
              }
              onClick={() => setSelectOption("Choose")}
              disabled={selected?.subjects?.length > 1}
            >
              Choose
            </button>
          </div>
          <div className="subcategoryrowrightinnerbottom">
            {selected?.subjects?.map((option) =>
              option?.topics?.map((rec) => (
                <div
                  key={rec?.id}
                  className={
                    selected?.topics?.includes(rec?.id) ||
                    selectOption === "All"
                      ? "subcategoryoptionselected"
                      : "subcategoryoption"
                  }
                  onClick={() => {
                    setselected((prev) => ({
                      ...prev,
                      topics: prev?.topics?.includes(rec?.id)
                        ? prev?.topics?.filter((myrec) => rec?.id != myrec)
                        : [...prev.topics, rec?.id],
                    }));
                  }}
                >
                  <h5>{rec?.name}</h5>
                </div>
              ))
            )}
          </div>
        </div>
        <button
          className="clinicalbodyelementbutton"
          onClick={() => {
            if (selected?.subjects?.length == 0) {
              toast.warn("Atlease select one subject!");
              return;
            }

            nextStage();
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export const SubQuestions = ({
  nextStage,
  selected,
  setselected,
  subjectsapi,
}) => {
  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState("");
  const [getqbank, getqbankstate] = useGetQbankMutation();
  const [generating, setgenerating] = useState();
  const [payload, setpayload] = useState();
  const [selectedMode, setSelectedMode] = useState("");
  const [questionSelected, setQuestionSelected] = useState(false);
  const [formfilled, setformfilled] = useState(false);
  const [selectedExam, setSelectedExam] = useState("");

  const options = [
    "5",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];
  useEffect(() => {
    if (selectedMode != "" && selectOption != "") {
      setformfilled(true);
    } else {
      setformfilled(false);
    }
  }, [selectOption, selectedMode]);
  useEffect(() => {
    // Define the function to fetch data
    let intervalId = null;

    const fetchData = async () => {
      try {
        const response = await getqbank(payload);
        if (response?.data) {
          navigate(
            `/qbank-question?id=${response?.data?.questions}&&source=QBANK`
          );
          clearInterval(intervalId);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Initial fetch
    if (generating) {
      fetchData();

      // Set up an interval to fetch data every 2 seconds
      intervalId = setInterval(fetchData, 10000);
    }
    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [generating]);
  return generating ? (
    <div className="fullscreen">
      <LoadingScreen />
    </div>
  ) : (
    <>
      <div className="subcategoryrow">
        <div className="subcategoryrowleft">
          <SubjectComponent
            selected={selected}
            setselected={setselected}
            subjectsapi={subjectsapi}
          />
        </div>
        <div className="subcategoryrowright">
          <div className="subcategoryrowrightheader">
            <h3>Select Exam Type</h3>
          </div>
          <hr />
          <div className="subcategoryrowrightinner">
            <div className="clinicalrow clinicalrowexams">
              <div
                className={
                  selectedExam === "AIIMS/ INI-CET"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("AIIMS/ INI-CET")}
              >
                <img src={aiimsicon} alt="AIIMS" />
                <p>AIIMS/ INI-CET</p>
              </div>
              <div
                className={
                  selectedExam === "USMLE"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("USMLE")}
              >
                <img src={usmleicon} alt="USMLE" />
                <p>USMLE</p>
              </div>

              <div
                className={
                  selectedExam === "PLAB"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("PLAB")}
              >
                <img src={plabicon} alt="PLAB" />
                <p>PLAB</p>
              </div>
              <div
                className={
                  selectedExam === "NEET-PG"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("NEET-PG")}
              >
                <img src={neeticon} alt="NEET" />
                <p>NEET-PG</p>
              </div>
            </div>
          </div>
          <br />
          <div className={"questiontypeselectorqbank"}>
            <div className="subcategoryrowrightheader">
              <h3>Select Question Type</h3>
            </div>
            <hr />
            <div className="subcategoryrowrightinner">
              <div className="subcategorybuttons">
                <button
                  className={
                    selected?.mode === "QBANK"
                      ? "subcategorybuttonselected"
                      : "subcategorybutton"
                  }
                  onClick={() => {
                    setselected((prev) => ({ ...prev, mode: "QBANK" }));
                    setSelectedMode("QBANK");
                    setSelectOption("");
                  }}
                >
                  QBank Mode
                </button>
                <button
                  className={
                    selected?.mode === "EXAM"
                      ? "subcategorybuttonselected"
                      : "subcategorybutton"
                  }
                  onClick={() => {
                    setselected((prev) => ({
                      ...prev,
                      mode: "EXAM",
                      no_of_questions: 5,
                    }));

                    setSelectedMode("EXAM");
                    setSelectOption(5);
                  }}
                >
                  Exam Mode
                </button>
              </div>
            </div>
          </div>
          {selectedMode === "QBANK" && (
            <>
              <hr />
              <div className="subcategoryrowrightheader">
                <h3>Select Questions</h3>
              </div>
              <hr />
              <div className="subcategoryrowrightinner">
                <div className="questionselect">
                  {options.map((option) => (
                    <div
                      key={option}
                      className={
                        selectOption === option
                          ? "subcategoryoptionselected"
                          : "subcategoryoption"
                      }
                      onClick={() => {
                        setQuestionSelected(true);
                        setSelectOption(option);
                        setselected((prev) => ({
                          ...prev,
                          no_of_questions: option,
                        }));
                      }}
                    >
                      <h5>{option}</h5>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <button
            className={
              formfilled
                ? "clinicalbodyelementbutton"
                : "graymode clinicalbodyelementbutton"
            }
            onClick={() => {
              if (!selected?.no_of_questions) {
                toast.warn("please select no of questions");
              }
              let temp = {
                no_of_questions: parseInt(selected?.no_of_questions),
                mode: selected?.mode,
                board: selectedExam,
              };
              if (selected?.subjects?.length > 1) {
                temp.subjects = selected?.subjects?.map((rec) => ({
                  subject: rec?.id,
                  topics: rec?.topics?.map((top) => top.id),
                }));
              } else {
                temp.subjects = selected?.subjects?.map((rec) => ({
                  subject: rec?.id,
                  topics:
                    selected?.topics?.length > 0
                      ? selected?.topics
                      : rec?.topics?.map((top) => top.id),
                }));
              }
              setpayload(temp);
              setgenerating(true);
              // navigate("/qbank-question");
            }}
          >
            Generate
          </button>
        </div>
      </div>
    </>
  );
};

export const SubTags = ({ nextStage, selected, setselected, subjectsapi }) => {
  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState("Choose");
  const [selectedSubjects, setSelectedSubjects] = useState();
  const [generating, setgenerating] = useState();
  const options = [
    "Clinical",
    "Next Step of Management",
    "Recent Updates",
    "Diagnosis Based",
    "Investigation of Choice",
    "Drug of Choice",
    "Common Topics",
    "Guideline Based",
    "Fact Based",
    "Theory Based",
    "Case Based",
    "Criteria Based",
    "Very Difficult",
  ];
  return (
    <div className="subcategoryrow">
      <div className="subcategoryrowleft">
        <SubjectComponent
          selected={selected}
          setselected={setselected}
          subjectsapi={subjectsapi}
        />
      </div>
      <div className="subcategoryrowright">
        <div className="subcategoryrowrightheader">
          <h3>Select Sub Category</h3>
        </div>
        <hr />
        <div className="subcategoryrowrightinner">
          <div className="subcategorybuttons">
            <button
              className={
                selectOption === "All"
                  ? "subcategorybuttonselected"
                  : "subcategorybutton"
              }
              onClick={() => {
                setSelectOption("All");
                setselected((prev) => ({
                  ...prev,
                  tags: options,
                }));
              }}
            >
              All
            </button>
            <button
              className={
                selectOption === "Choose"
                  ? "subcategorybuttonselected"
                  : "subcategorybutton"
              }
              onClick={() => setSelectOption("Choose")}
            >
              Choose
            </button>
          </div>
          <div className="subcategoryrowrightinnerbottom">
            {options.map((option) => (
              <div
                key={option}
                className={
                  selected?.tags?.includes(option)
                    ? "subcategoryoptionselected"
                    : "subcategoryoption"
                }
                onClick={() =>
                  setselected((prev) => ({
                    ...prev,
                    tags: prev?.tags?.includes(option)
                      ? prev?.tags?.filter((rec) => rec != option)
                      : [...prev?.tags, option],
                  }))
                }
              >
                <h5>#{option}</h5>
              </div>
            ))}
          </div>
        </div>
        <button
          className="clinicalbodyelementbutton"
          onClick={() => {
            let payload = {};
            if (selected?.subjects?.length > 1) {
              payload.subjects = selected?.subjects?.map((rec) => ({
                subject: rec?.id,
                topics: rec?.topics?.map((top) => top.id),
              }));
            } else {
              payload.subjects = selected?.subjects?.map((rec) => ({
                subject: rec?.id,
                topics: selected?.topics,
              }));
            }
            // navigate("/qbank-question");
          }}
        >
          Generate Module
        </button>
      </div>
    </div>
  );
};

export default function QBankSelection() {
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const { data: subjects, isLoading: subjectLoading } = useGetsubjectQuery();
  const [qbankState, setQbankState] = useState("Case");
  const [doneStage, setDoneStage] = useState(0);
  const [selected, setselected] = useState({
    subjects: [],
    tags: [],
    topics: [],
    mode: null,
    no_of_questions: null,
  });
  const stageTwo = () => {
    setDoneStage(1);
    setQbankState("Examination");
  };
  const stageThree = () => {
    setDoneStage(2);
    setQbankState("Confirmation");
  };
  const stageFour = () => {
    setDoneStage(3);
    setQbankState("Preview");
  };

  return (
    <div className="qbankbackground homemain">
      <SideBar
        selected="QBank"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="QBank"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
              <div
                className="closesidebarbuttonmobileheader"
                onClick={toggleSidebar}
              >
                <img src={GripLine} alt="Arrow" />
              </div>
            </div>
            <div className="homemainleftouter">
              <h5
                onClick={() => {
                  navigate("/qbank");
                }}
              >
                Back to QBank
              </h5>
              <h3>Smart QBank</h3>
            </div>
          </div>
          <div className="homemainrightheader">
            <div className="inputdiv">
              <img src={SearchIcon} alt="Search" />
              <input type="text" placeholder="Search" />
            </div>
          </div>
        </div>
        <div className="diagnosestatus desktoponly">
          <div
            className={
              qbankState === "Case" || doneStage === 1 || doneStage === 2
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 1 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : (
              <img src={step1selected} alt="Step 1" />
            )}
            <h5>Select Subjects</h5>
          </div>
          <hr
            className={
              qbankState === "Case" || doneStage === 1 || doneStage === 2
                ? "diagnoseactivehr"
                : ""
            }
          />
          <div
            className={
              qbankState === "Examination" || doneStage === 2
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 2 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 1 ? (
              <img src={step2selected} alt="Step 2" />
            ) : (
              <img src={step2} alt="Step 2" />
            )}
            <h5>Select Sub Category</h5>
          </div>
          <hr
            className={
              qbankState === "Examination" || doneStage === 2
                ? "diagnoseactivehr"
                : ""
            }
          />

          <div
            className={
              qbankState === "Confirmation" || doneStage === 3
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage >= 3 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 2 ? (
              <img src={step3selected} alt="Step 3" />
            ) : (
              <img src={step3} alt="Step 3" />
            )}
            <h5>Select Question and Mode</h5>
          </div>
          <hr
            className={
              qbankState === "Preview" || doneStage === 4
                ? "diagnoseactivehr"
                : ""
            }
          />
          <div
            className={
              qbankState === "Preview" || doneStage === 4
                ? "diagnoseactive"
                : "diagnosedue"
            }
          >
            {doneStage > 4 ? (
              <img src={TickIcon} alt="Done" className="tickimg" />
            ) : doneStage === 3 ? (
              <img src={step4selected} alt="Step 4" />
            ) : (
              <img src={step4} alt="Step 4" />
            )}
            <h5>Add Tags</h5>
          </div>
        </div>
        {qbankState === "Case" ? (
          <SubjectComponent
            nextStage={stageTwo}
            subjectsapi={subjects}
            setselected={setselected}
            selected={selected}
          />
        ) : qbankState === "Examination" ? (
          <div>
            <SubCategory
              nextStage={stageThree}
              subjectsapi={subjects}
              setselected={setselected}
              selected={selected}
            />
          </div>
        ) : (
          <div>
            <SubQuestions
              nextStage={stageFour}
              subjectsapi={subjects}
              setselected={setselected}
              selected={selected}
            />
            {/* Select Question and Mode */}
          </div>
        )}
      </div>
    </div>
  );
}
