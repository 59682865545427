import React from "react";
import { useNavigate } from "react-router-dom";
export default function CompletedCases({ header, body, uuid }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/qbank-question?id=${uuid}&source=case`)}
      className="completedcases max-h-[20rem] overflow-hidden cursor-pointer"
    >
      <h5>{header}</h5>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </div>
  );
}
