import React from "react";

import { useNavigate } from "react-router-dom";
const congratsimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/cograts.svg";

export default function ClinicalCongratsQBank({
  isVisible,
  onClose,
  total,
  correct,
  incorrect,
  skipped,
}) {
  const navigate = useNavigate();
  const handleNextCase = () => {
    onClose();
    navigate("/qbank-result");
  };
  const handleReattempt = () => {
    onClose();
    navigate("/qbank-question");
  };

  const handleHome = () => {
    onClose();
    navigate("/home");
  };

  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content-congrats"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={congratsimage} alt="Congrats" className="congratsimage" />
        <h1>Congratulations</h1>
        <p>You have successfully completed this case</p>
        <div className="answerevaluation">
          <div className="evaluationboxscore evaluationboxscoreblue">
            <p>Total Questions</p>
            <h1>{total}</h1>
          </div>
          <div className="evaluationboxscore evaluationboxscoregreen">
            <p>Correct</p>
            <h1>{correct}</h1>
          </div>
          <div className="evaluationboxscore evaluationboxscorered">
            <p>Incorrect</p>
            <h1>{incorrect}</h1>
          </div>
          <div className="evaluationboxscore evaluationboxscoregray">
            <p>Unattempted</p>
            <h1>{skipped}</h1>
          </div>
        </div>
        <div className="contentcenter threeelementselector">
          <button className="elementselector" onClick={handleReattempt}>
            Reattempt
          </button>
          <button className="elementselector" onClick={onClose}>
            View Solutions
          </button>
          <button className="elementselector" onClick={handleHome}>
            Home
          </button>
        </div>
      </div>
    </div>
  );
}
