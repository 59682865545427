import React from "react";
import loadingAnimation from "../loading/loadingscreen.json";
import Lottie from "react-lottie";

export default function LoadingScreen({ loaderLine }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="text-center">
      <div className="loadercontainer">
        <Lottie options={defaultOptions} className="max centeralign" />
      </div>
      <p>{loaderLine ? loaderLine : "Analysing the Latest Literature"}</p>
    </div>
  );
}
