import React from "react";
import { motion } from "framer-motion";

const upgradeicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/upgradedialog.svg";
export default function UpgradeDialog() {
  return (
    <div className="upgradedialog desktoponly">
      <div className="dialogleft overflow-hidden">
        <motion.div
          className="dialogleftimage"
          initial={{ y: 0 }}
          animate={{ y: 0 }}
          whileTap={{ y: -200 }}
          onAnimationComplete={() => {
            setTimeout(() => {
              if (document.querySelector(".dialogleftimage")) {
                document.querySelector(".dialogleftimage").style.transform =
                  "translateY(0)";
              }
            }, 500);
          }}
        >
          <img src={upgradeicon} alt="Upgrade Icon" />
        </motion.div>
        <div className="dialogleftcontent">
          <h5>Ready to level up?</h5>
          <p>
            You’re currently on the free trial for 7 days. Unlock additional
            features and benefits on a paid plan.
          </p>
        </div>
      </div>
      <div className="dialogright">
        <div className="elementselector">Upgrade Now</div>
      </div>
    </div>
  );
}
