import React, { useEffect, useState } from "react";
import "../styles/clinicalmcq.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import { BsFillPatchCheckFill } from "react-icons/bs";

import CorrectAnswer from "../components/correctanswer";
import WrongAnswer from "../components/wronganswer";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetQuestionsQuery,
  useGetRunningTestQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import { useSubmitQuestionMutation } from "../store/caseSimulation/CaseStudyApiSlice";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import SkippedAnser from "../components/skippedanswer";
import HomemainHeaderMobile from "../components/homemainheadermobile";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const TimeIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/time.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
export default function QBankResult() {
  const [bookmark, setBookmark] = useState(false);
  const [received, setreceived] = useState(false);
  const navigate = useNavigate();
  const [managepatient, setmanagepatient] = useState();
  const [showeval, setshoweval] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionVisible, setQuestionVisible] = useState(false);
  const handleOptionChange = (event) => {
    if (!isSubmitted) {
      setSelectedOption(event.target.value);
    }
  };
  const handleSumbit = () => {
    setIsSubmitted(true);
  };
  const numbers = [];
  for (let i = 1; i < 51; i++) {
    numbers.push(i);
  }
  return (
    <div className="homemain">
      <SideBar
        selected="QBank"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="QBank"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clincalbody">
          <div className="clinicalprogress">
            <h5>Case Progress</h5>
            <div className="clinicalprogressestimate">
              <h4>75% to Complete</h4>

              <div className="clinicalprogressestimatetime">
                <img src={TimeIcon} alt="Time" />
                <p>20 seconds</p>
              </div>
            </div>
            <div className="clinicalprogresspercentage">
              <div
                style={{
                  width: "75%",
                }}
                className="clinicalprogressbar"
              ></div>
            </div>
          </div>

          <div className="qbankbodyelements">
            <div className="clinicalbodyqbankleft">
              <div className="clinicalbodyoptions">
                <div className="questionclinicalheader">
                  <h1>Question 1</h1>

                  <div className="aisearchbodyright">
                    <div
                      className="elementselector bookmarkselector"
                      onClick={() => setBookmark(!bookmark)}
                    >
                      {bookmark ? (
                        <img src={bookmarkIcon} alt="Bookmark" />
                      ) : (
                        <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="clinicalelementbodyheader headerbodybottom">
                  <strong>
                    Which of the following is the most appropriate immediate
                    management step?
                  </strong>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option1"
                    value="1"
                    checked={selectedOption === "1"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                    required
                  />
                  <label htmlFor="option1">Start thrombolytic therapy</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option2"
                    value="2"
                    checked={selectedOption === "2"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option2">Urgent surgical consultation</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option3"
                    value="3"
                    checked={selectedOption === "3"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option3">
                    Administer sublingual nitroglycerin
                  </label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option4"
                    value="4"
                    checked={selectedOption === "4"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option4">
                    Initiate oral antihypertensive therapy
                  </label>
                </div>
              </div>

              {selectedOption === "2" ? <CorrectAnswer /> : <WrongAnswer />}
              <br />
              <div className="clinicalbodyoptions">
                <div className="questionclinicalheader">
                  <h1>Question 2</h1>

                  <div className="aisearchbodyright">
                    <div
                      className="elementselector bookmarkselector"
                      onClick={() => setBookmark(!bookmark)}
                    >
                      {bookmark ? (
                        <img src={bookmarkIcon} alt="Bookmark" />
                      ) : (
                        <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="clinicalelementbodyheader headerbodybottom">
                  <strong>
                    Which of the following is the most appropriate immediate
                    management step?
                  </strong>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option1"
                    value="1"
                    checked={selectedOption === "1"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                    required
                  />
                  <label htmlFor="option1">Start thrombolytic therapy</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option2"
                    value="2"
                    checked={selectedOption === "2"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option2">Urgent surgical consultation</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option3"
                    value="3"
                    checked={selectedOption === "3"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option3">
                    Administer sublingual nitroglycerin
                  </label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option4"
                    value="4"
                    checked={selectedOption === "4"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option4">
                    Initiate oral antihypertensive therapy
                  </label>
                </div>
              </div>
              {selectedOption === "2" ? <CorrectAnswer /> : <WrongAnswer />}
              <br />

              <div className="clinicalbodyoptions">
                <div className="questionclinicalheader">
                  <h1>Question 3</h1>

                  <div className="aisearchbodyright">
                    <div
                      className="elementselector bookmarkselector"
                      onClick={() => setBookmark(!bookmark)}
                    >
                      {bookmark ? (
                        <img src={bookmarkIcon} alt="Bookmark" />
                      ) : (
                        <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="clinicalelementbodyheader headerbodybottom">
                  <strong>
                    Which of the following is the most appropriate immediate
                    management step?
                  </strong>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option1"
                    value="1"
                    checked={selectedOption === "1"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                    required
                  />
                  <label htmlFor="option1">Start thrombolytic therapy</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option2"
                    value="2"
                    checked={selectedOption === "2"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option2">Urgent surgical consultation</label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option3"
                    value="3"
                    checked={selectedOption === "3"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option3">
                    Administer sublingual nitroglycerin
                  </label>
                </div>
                <div className="clinicalbodyoption">
                  <input
                    type="radio"
                    name="clinicalOption"
                    id="option4"
                    value="4"
                    checked={selectedOption === "4"}
                    onChange={handleOptionChange}
                    disabled={isSubmitted}
                  />
                  <label htmlFor="option4">
                    Initiate oral antihypertensive therapy
                  </label>
                </div>
              </div>

              {selectedOption === "2" ? <CorrectAnswer /> : <WrongAnswer />}
            </div>
            <div className="clinicalbodyqbankright">
              <div className="qbankquestionsnumbering">
                {numbers.map((number) => (
                  <div
                    className={
                      number === 41
                        ? "qbankquestionnumber qbankquestionnumberunseen"
                        : number === 12
                        ? "qbankquestionnumber qbankquestionnumberwrong"
                        : number === 11
                        ? "qbankquestionnumber qbankquestionnumberyellow"
                        : "qbankquestionnumber qbankquestionnumbercompleted"
                    }
                  >
                    {number}
                  </div>
                ))}
              </div>
              <div className="answerevaluation">
                <div className="evaluationboxscore evaluationboxscoreblue">
                  <p>Total Questions</p>
                  <h1>50</h1>
                </div>
                <div className="evaluationboxscore evaluationboxscoregreen">
                  <p>Correct</p>
                  <h1>45</h1>
                </div>
                <div className="evaluationboxscore evaluationboxscorered">
                  <p>Incorrect</p>
                  <h1>4</h1>
                </div>
                <div className="evaluationboxscore evaluationboxscoregray">
                  <p>Unattempted</p>
                  <h1>1</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
