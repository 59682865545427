import React from "react";

import { useNavigate } from "react-router-dom";
const congratsimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/cograts.svg";

export default function ClinicalCongrats({
  isVisible,
  onClose,
  qid,
  treatment,
}) {
  const navigate = useNavigate();
  const handleNextCase = () => {
    onClose();
    navigate("/clinicalcase/mcq");
  };
  const handleHome = () => {
    onClose();
    navigate("/home");
  };

  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content-congrats  max-h-[95%] overflow-y-scroll no-scrollbar"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={congratsimage} alt="Congrats" className="congratsimage" />
        <h1>Congratulations</h1>
        <p>You have successfully completed this case</p>
        <div className="flex flex-col">
          <div className="w-full flex flex-row items-center justify-between">
            <h2 className="">Treatment Plan</h2>
          </div>
          <div
            className="quickddxbody"
            dangerouslySetInnerHTML={{ __html: treatment }}
          ></div>
        </div>
        <div className="contentcenter mt-10">
          <button className="elementselector" onClick={handleHome}>
            Home
          </button>
          <button className="elementselectorfilled" onClick={handleNextCase}>
            Next Case
          </button>
          <button
            className="elementselectorfilled"
            onClick={() => navigate(`/qbank-question?id=${qid}&source=case`)}
          >
            Report
          </button>
        </div>
      </div>
    </div>
  );
}
