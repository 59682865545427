import React, { useEffect, useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/dashboard.css";
import { useNavigate } from "react-router-dom";
import {
  useGetDiagnosisMutation,
  useSolveMyQueryMutation,
} from "../store/General/generalSlice";
import { BiLoaderCircle } from "react-icons/bi";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { GoRelFilePath } from "react-icons/go";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { getAchievementSrc } from "../components/helpers";
import UpgradeDialog from "../elements/upgradedialog";
import { useGetProfileQuery } from "../store/profile/UserApiSlice";
import SymptomChecker from "../elements/symptomchecker";
import { motion } from "framer-motion";
import config from "../config/APIEndpoints";
import CongratsDialog from "../elements/congratsdialog";
import ScoreBoardBanner from "../components/scoreboardbanner";
import FreeTrial from "../elements/freetrial";
import SubjectsPopup from "../elements/subjectspopup";
import LoadingScreen from "../elements/loading";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/inputcamera.svg";
const UserIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profilevectro.svg";
const MicIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/mic.svg";
const Diagnose =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/diagnose.svg";
const ArrowRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowright.svg";
const ArrowupRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowupright.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const Caseimg =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/case.png";

const SearchIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/search.svg";

const BetaIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/beta.svg";
const homebanner =
  "https://neuro-gen.s3.amazonaws.com/images/icons/homebanner.svg";

const homecollab = "https://neuro-gen.s3.amazonaws.com/images/homecollab.svg";
const questionimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/question.svg";
const worldiamge = "https://neuro-gen.s3.amazonaws.com/images/world.svg";
const ImageDXicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/imagedx.svg";

const generateStars =
  "https://neuro-gen.s3.amazonaws.com/images/icons/generatestars.svg";

const subjectsimage = "https://neuro-gen.s3.amazonaws.com/images/subjects.svg";

const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";

const micIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/inputmic.svg";
const imgs = [
  ["personButton.png", "heartButton.png"],
  ["bodybutton.png", "chemicalButton.png"],
  ["injectionButton.png", "chemical2.png"],
  ["personHealth.png", "pills.png"],
  ["germs.png", "testbutton.png"],
  ["personHealth.png", "pills.png"],
];

const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
function isSubscriptionActive(subscription) {
  if (
    subscription?.subscription_plan?.plan_name == "Free" ||
    !subscription?.active
  ) {
    return false;
  }
  const { price_time, price_time_unit } = subscription?.subscription_plan;

  // Parse the purchased date to a Date object
  const purchasedDate = new Date(subscription?.purchased_date);

  // Determine the time unit multiplier
  let timeUnitMultiplier;
  switch (price_time_unit.toUpperCase()) {
    case "DAY":
      timeUnitMultiplier = price_time * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      break;
    case "MONTH":
      purchasedDate?.setMonth(purchasedDate?.getMonth() + price_time);
      break;
    case "YEAR":
      purchasedDate?.setFullYear(purchasedDate?.getFullYear() + price_time);
      break;
    default:
      // Default to adding the time as days
      timeUnitMultiplier = price_time * 24 * 60 * 60 * 1000;
      break;
  }

  if (timeUnitMultiplier) {
    purchasedDate?.setTime(purchasedDate?.getTime() + timeUnitMultiplier);
  }

  // Get the current date
  const currentDate = new Date();
  console.log(purchasedDate, currentDate);
  // Check if the current date is before or on the expiration date
  return currentDate <= purchasedDate;
}
export default function Dashboard() {
  const [addMore, setAddMore] = useState(false);
  const navigate = useNavigate();
  const [query, setquery] = useState();

  const handleSearch = async () => {
    if (!query) {
      toast.warning("please enter Query!");
      return;
    }
    const formData = new FormData();
    formData.append("query", query);
    if (file) {
      formData.append("files", file); //
    }
    const resp = await getquery(formData);
    if (resp?.error) {
      const utcDate = resp?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(`${resp?.error?.data?.error} please try after ${istDate}!`);
    } else {
      navigate(`/aisearch/${resp.data.uuid}`);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isFreeTrialOpen, setIsFreeTrialOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFreeTrialOpen(true);
    }, 5000);
  }, []);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [getquery, getquerystate] = useSolveMyQueryMutation();
  const { data: profileData } = useGetProfileQuery();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [showup, setshowup] = useState(false);
  const { data, isLoading } = useGetUserScoreQuery();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };
  useEffect(() => {
    if (profileData?.sub)
      setshowup(isSubscriptionActive(profileData?.sub?.[0]));
  }, [profileData]);
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);

  const [activeCard, setActiveCard] = useState(1);

  const handleSwipeLeft = () => {
    setActiveCard((prev) => (prev === 2 ? 0 : prev + 1));
  };

  const handleSwipeRight = () => {
    setActiveCard((prev) => (prev === 0 ? 2 : prev - 1));
  };

  const [getDiagnosis, { data: diagnosisData, isLoading: diagnosisLoading }] =
    useGetDiagnosisMutation();
  const [isSymptomChecker, setIsSymptomChecker] = useState(false);
  const toggleSymptomChecker = () => {
    setIsSymptomChecker(!isSymptomChecker);
  };
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const toggleScoreboard = () => {
    setIsScoreboardVisible(!isScoreboardVisible);
  };
  const [isSubjectPopupOpen, setIsSubjectPopupOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const toggleSubjectPopup = (subject) => {
    setIsSubjectPopupOpen(!isSubjectPopupOpen);
    setSelectedSubject(subject);
  };

  return (
    <div className="homemain">
      <FreeTrial
        isVisible={isFreeTrialOpen}
        onClose={() => setIsFreeTrialOpen(false)}
      />
      <SideBar
        selected="Dashboard"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <SymptomChecker
        isVisible={isSymptomChecker}
        onClose={toggleSymptomChecker}
      />
      <ScoreBoardBanner
        isVisible={isScoreboardVisible}
        onClose={toggleScoreboard}
      />
      <SubjectsPopup
        isVisible={isSubjectPopupOpen}
        onClose={toggleSubjectPopup}
        subject={selectedSubject}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader mobilevisibleonly dashboardheadermobile">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
              <div
                className="closesidebarbuttonmobileheader"
                onClick={toggleSidebar}
              >
                <img src={GripLine} alt="Arrow" />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Hello Dr. {profileData?.profile?.first_name},</h3>
              <p>Ready to Elevate your Medical Journey?</p>
            </div>
          </div>
          <div className="homemainright" onMouseEnter={toggleScoreboard}>
            {data?.score > 0 ? (
              <button>
                <div className="homemainrightbuttoncontent">
                  <img
                    src={getAchievementSrc(data?.score || 0)}
                    alt="Header Badge"
                  />
                  <span>
                    <b>'Novice'</b> Badge
                  </span>
                </div>
                <div className="headerstreaks">
                  <img src={fireicon} alt="Fire" />
                  <p>{data?.score || 0}</p>
                </div>
              </button>
            ) : (
              <button>
                <h6>Let’s get started and earn badges</h6>
              </button>
            )}
            <button
              onClick={() => {
                navigate("/profile?page=history");
              }}
            >
              <img src={reloadicon} alt="Reload" />
            </button>
            <div className="usericonscore">
              <img
                src={
                  profileData?.profile?.profile_pic
                    ? `${config.getEndpoint()}${
                        profileData?.profile?.profile_pic
                      }`
                    : UserIcon
                }
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                alt="User"
                className="dashboardheadericon mobileonly"
                onClick={() => handleNavigation("/profile")}
              />
              <div className="userscoremobile">
                <p>{data?.score || 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboardbody">
          <div className="dashboardheader">
            <h2>What's on your mind?</h2>
            <p>
              Example: How do Proteosome Inhibitors function in the treatment of
              Multiple Myeloma?
            </p>
            <div
              className={`dashboardinput ${
                query ? "border-blue-500 shadow-blue" : "border-gray-300"
              }`}
              style={{
                borderWidth: "2px",
                borderRadius: "8px",
                padding: "10px",
                boxShadow: query
                  ? "0px 4px 10px 4px rgba(0, 144, 255, 0.53);"
                  : "none",
              }}
            >
              <img src={SearchIcon} alt="Search" onClick={handleSearch} />
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => setquery(e.target.value)}
                type="text"
                placeholder="Enter your question here"
              />
              {getquerystate?.isLoading ? (
                <BiLoaderCircle className="animate-spin" />
              ) : (
                <div className="flex flex-row gap-4 inputimagesrow">
                  <div className="tooltip-container">
                    <input
                      type="file"
                      id="file"
                      accept=".pdf, image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      {file ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Uploaded File"
                          width="50"
                        />
                      ) : (
                        <img src={UploadIcon} alt="Upload" />
                      )}
                    </label>
                    <div className="tooltip-image">
                      <div className="bottomtriangle"></div>
                      Upload File
                    </div>
                  </div>

                  {/* Mic Tooltip */}
                  <div className="tooltip-container">
                    <img src={micIcon} alt="Mic" />
                    <div className="tooltip-image">
                      <div className="bottomtriangle"></div>
                      Use Voice
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {diagnosisLoading || getquerystate?.isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {" "}
              {!showup && <UpgradeDialog />}
              <div
                className="dashboardgrid"
                onTouchEnd={(e) => {
                  if (e.changedTouches[0].clientX < window.innerWidth / 2) {
                    handleSwipeLeft();
                  } else {
                    handleSwipeRight();
                  }
                }}
              >
                <div
                  className={`dashboardgridelementone `}
                  onClick={() => handleNavigation("/research-literature")}
                >
                  <h4>
                    Become a <strong>Great Doctor,</strong> Master{" "}
                    <strong>Clinical Excellence.</strong>
                  </h4>
                  <p>
                    Step Up<strong> Your Game</strong> with Clinical Case
                    Simulator!
                  </p>
                  <img
                    src={homebanner}
                    alt="Research"
                    className="homebannerimage"
                  />
                </div>
                <div className={`dashboardgridelementtwo `}>
                  <h4>
                    Don't Guess - <strong>Get Accurate Diagnoses </strong>
                  </h4>
                  <p>
                    Your<strong> Smart Symptom Checker</strong> Guide!
                  </p>
                  <div className="symptomchecker">
                    <div className="researchelementleft">
                      <Formik
                        initialValues={{
                          query1: null,
                          query2: null,

                          query3: null,

                          query4: null,
                        }}
                        onSubmit={async (values, action) => {
                          try {
                            const formData = new FormData();
                            formData.append(
                              "quick_query",
                              `${values?.query1},${values?.query2},${values?.query3},${values?.query4}`
                            );
                            formData.append("type", "SYMPTOMCHECKER");
                            const response = await getDiagnosis(
                              formData
                            ).unwrap();

                            navigate("/diagnose", {
                              state: { diagnosisResult: response },
                            });
                          } catch (error) {
                            console.error(error);
                          } finally {
                            action.setSubmitting(false);
                          }
                        }}
                      >
                        {({ handleSubmit, setValues, isSubmitting }) => (
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              onChange={(e) => {
                                setValues((prev) => ({
                                  ...prev,
                                  query1: e.target.value,
                                }));
                              }}
                              placeholder="Enter Here"
                            />
                            <input
                              type="text"
                              onChange={(e) => {
                                setValues((prev) => ({
                                  ...prev,
                                  query2: e.target.value,
                                }));
                              }}
                              placeholder="ex: Lactic Acidosis"
                            />
                            <input
                              type="text"
                              onChange={(e) => {
                                setValues((prev) => ({
                                  ...prev,
                                  query3: e.target.value,
                                }));
                              }}
                              placeholder="ex: Aphasia"
                            />

                            <button
                              className="elementselectorlightblue addmorebutton"
                              onClick={toggleSymptomChecker}
                            >
                              Add More
                            </button>

                            <button type="submit" className="generatebutton">
                              {isSubmitting ? "Generating..." : "Generate"}
                            </button>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
                <div
                  className={`dashboardgridelementthree`}
                  onClick={() => handleNavigation("/clinical")}
                >
                  <div className="dashboardelementthreetop">
                    <h4>
                      Become a <strong>Great Doctor,</strong> Master{" "}
                      <strong>Clinical Excellence.</strong>
                    </h4>
                    <p>
                      Step Up<strong> Your Game</strong> with Clinical Case
                      Simulator!
                    </p>
                  </div>
                  <img src={worldiamge} alt="World" className="worldimage" />
                  <div className="dashboardgridelementthreecenter">
                    <img
                      src={homecollab}
                      alt="Research"
                      className="homecollabimage"
                    />
                  </div>
                </div>
                {/* <div className="dashboardgridelementfour">
              <h4>Recent Update</h4>
              <div className="dashboardgridfourcenter">
                <img src={questionimage} alt="Template" />
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ullamcorper suscipit
                  quis amet morbi molestie quam nulla purus odio.
                </p>
                <button className="elementselector w-full">Button</button>
              </div>
            </div> */}
              </div>
              <div className="dashboardgridtwo">
                <div className="dashboardgridtwoleft">
                  <div className="dashboardgridheader">
                    <div className="dashboardgridheaderleft">
                      <div className="dashboardheaderrow">
                        <h4>
                          <strong>Spot it Early, Act Fast</strong>
                        </h4>
                      </div>
                      <p>
                        <strong>
                          Explore the Strength of Image & Investigatory Based
                          Dx!
                        </strong>
                      </p>
                    </div>
                    <div className="dashboardgridheaderright">
                      {/* <img src={BetaIcon} alt="Beta" /> */}
                    </div>
                  </div>

                  <div className="dashboardleftbody">
                    <input
                      type="text"
                      placeholder="Description"
                      className="dxinput"
                    />
                    <Formik
                      initialValues={{
                        type: "SPOTIT",
                        file: null,
                      }}
                      onSubmit={async (values, action) => {
                        try {
                          const formData = new FormData();
                          formData.append("type", "SPOTIT");
                          formData.append("file_uploads", values.file);
                          formData.append("quick_query", "");

                          const response = await getDiagnosis(
                            formData
                          ).unwrap();

                          navigate("/diagnose", {
                            state: { diagnosisResult: response },
                          });
                        } catch (error) {
                          console.error(error);
                        } finally {
                          action.setSubmitting(false);
                        }
                      }}
                    >
                      {({ handleSubmit, setValues, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit} className="ddxupload">
                          <div className="profilepictureupload">
                            <label htmlFor="upload-photo">
                              <div className="profilechild">
                                <img src={UploadIcon} alt="Upload" />
                                <h2>
                                  {values?.file
                                    ? values?.file?.name
                                    : "Upload Image"}
                                </h2>
                              </div>
                            </label>
                            <input
                              type="file"
                              id="upload-photo"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                setValues({ file: event.target.files[0] });
                              }}
                            />
                          </div>
                          <button type="submit" className="generatebutton">
                            <img src={generateStars} alt="" />
                            {isSubmitting ? "Generating..." : "Generate"}
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="dashboardgridtworight">
                  <div className="dashboardelementthreetop">
                    <h4>
                      Select <strong>Your Favourite Subject</strong>
                    </h4>
                    <p>
                      Personalize <strong>Your Learning Experience</strong> and{" "}
                      <strong>Unlock Tailored Resources!</strong>
                    </p>
                  </div>

                  <div className="subjectsimage-container">
                    {imgs.map((img, index) => (
                      <div
                        key={index}
                        className="subjectimage"
                        style={{ marginTop: index % 2 === 0 ? "20px" : "0" }}
                      >
                        {img.map((src, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={`/${src}`}
                            alt={`/${src}`}
                            onClick={() => toggleSubjectPopup(src)}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
