import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./index.css";

import ClinicalHome from "./pages/clinicalhome";
import ClinicalMCQs from "./pages/clinicalmcq";
import ClinicalMobile from "./pages/clinicalmobile";
import ClinicalDetailed from "./pages/clinicaldetailed";
import Dashboard from "./pages/dashboard";
import AiSearch from "./pages/aisearch";
import ResearchLiterature from "./pages/researchliterature";
import ResearchArticle from "./pages/researcharticle";
import ResearchReport from "./pages/researchreport";
import Login from "./pages/login";
import RegistrationPage from "./pages/registration";
import ExpertAdvice from "./pages/expertadvice";
import Profile from "./pages/profile";
import Diagnose from "./pages/diagnose";
import ProtectedRoutes from "./components/ProtectedRoute";
import Lounge from "./pages/lounge";
import CaseListing from "./pages/cases";
import ImageDx from "./pages/imagedx";
import Investigation from "./pages/investigation";
import Symptom from "./pages/symptom";
import TandC from "./pages/termsandcondition";
import PrivacyPolicy from "./pages/privacypolicy";
import CancellationPolicy from "./pages/cancellation";
import ContactUs from "./pages/contact-us";
import QBank_Selection from "./pages/qbank-selection";
import QBank from "./pages/qbank";
import QBankQuestion from "./pages/qbank-question";
import QBankResult from "./pages/qbank-result";
import PopupTesting from "./pages/popup-testing";
import ResetPassword from "./pages/resetpassword";
import ForgotPassword from "./pages/ForgotPassowrd";
import Blog from "./pages/blog";
import Home from "./portfolio/home";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register/:step" element={<RegistrationPage />} />
          <Route
            path="/resetpassword/:uid/:token"
            element={<ResetPassword />}
          />
          <Route path="/forgotpassowrd" element={<ForgotPassword />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/aisearch/:uid" element={<AiSearch />} />
            <Route path="/clinical" element={<ClinicalHome />} />
            <Route path="/clinicalcase/mcq" element={<ClinicalMCQs />} />
            <Route
              path="/clinicalcase/questions/:uid"
              element={<ClinicalMCQs />}
            />
            <Route
              path="/clinicalcase/detailed"
              element={<ClinicalDetailed />}
            />
            <Route path="/clinicalcase/mobile" element={<ClinicalMobile />} />
            <Route path="QBank" element={<QBank />} />
            <Route
              path="/qbank-selection/:subject"
              element={<QBank_Selection />}
            />
            <Route
              path="/research-literature"
              element={<ResearchLiterature />}
            />
            <Route path="/research-article" element={<ResearchArticle />} />
            <Route path="/research-report" element={<ResearchReport />} />
            <Route path="/expert" element={<ExpertAdvice />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/diagnose" element={<Diagnose />} />
            <Route path="/lounge" element={<Lounge />} />
            <Route path="/blog/:slug" element={<Blog />} />
            <Route path="/cases" element={<CaseListing />} />
            <Route path="/imagedx" element={<ImageDx />} />
            <Route path="/investigatory" element={<Investigation />} />
            <Route path="/symptom" element={<Symptom />} />
            <Route path="/terms" element={<TandC />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/cancellation-policy"
              element={<CancellationPolicy />}
            />

            <Route path="*" element={<Dashboard />} />
            <Route path="/qbank-question" element={<QBankQuestion />} />
            <Route path="/qbank-result" element={<QBankResult />} />
            <Route path="/popup-testing" element={<PopupTesting />} />
            <Route path="/portfolio" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
