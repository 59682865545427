import { apiSlice } from "../apiSlice";

export const researchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    research: builder.mutation({
      query: (data) => ({
        url: "api/v1/research/",
        method: "POST",
        body: data,
      }),
    }),
    getresearch: builder.query({
      query: (uid) => ({
        url: `api/v1/get-research/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Research"],
    }),
  }),
});

export const { useResearchMutation, useGetresearchQuery } = researchSlice;
