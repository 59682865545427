import React, { useState } from "react";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { getAchievementSrc, GetBadgeName } from "./helpers";
import { useGetProfileQuery } from "../store/profile/UserApiSlice";
import ScoreBoardBanner from "./scoreboardbanner";
import config from "../config/APIEndpoints";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
const ProfileIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profile.svg";
export default function HomemainHeader({ onToggle, isSidebarCollapsed }) {
  const { data, isLoading } = useGetUserScoreQuery();
  const { data: profileData } = useGetProfileQuery();
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const toggleScoreboard = () => {
    setIsScoreboardVisible(!isScoreboardVisible);
  };
  return (
    <div className="homemainheader">
      <ScoreBoardBanner
        isVisible={isScoreboardVisible}
        onClose={toggleScoreboard}
      />
      <div className="homemainleft">
        <div className="homemainleftinner">
          <div className="closesidebarbutton" onClick={onToggle}>
            <img src={isSidebarCollapsed ? OpenIcon : CloseIcon} alt="Arrow" />
          </div>
          <div className="closesidebarbuttonmobileheader" onClick={onToggle}>
            <img src={GripLine} alt="Arrow" />
          </div>
        </div>
        <div className="homemainleftouter">
          <h3>Hello Dr.{data?.name.split(" ")[0]},</h3>
          <p>Your patient needs you, Let's rush to the rescue</p>
        </div>
      </div>
      <div className="homemainright">
        <button>
          <div className="homemainrightbuttoncontent">
            <img src={getAchievementSrc(data?.score || 0)} alt="Header Badge" />
            <b>{GetBadgeName(data?.score)}</b> Badge
          </div>
          <div className="headerstreaks">
            <img src={fireicon} alt="Fire" />
            <p>{data?.score || 0}</p>
          </div>
        </button>
        <button>
          <img
            src={
              profileData?.profile?.profile_pic
                ? `${config.getEndpoint()}${profileData?.profile?.profile_pic}`
                : ProfileIcon
            }
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
              border: "2px solid #ccc",
            }}
            alt="Reload"
          />
        </button>
      </div>
    </div>
  );
}
