/* eslint-disable no-undef */
const config = {
    environment: process.env.REACT_APP_ENV || "local",
    endpoints: {
        prod: "",
        dev: "https://api.dev.neurogenn.com",
        local: "http://localhost:8000",
    },
    getEndpoint() {
        switch (this.environment) {
            case "local":
                return this.endpoints.local;
            case "development":
                return this.endpoints.dev;
            case "production":
                return this.endpoints.prod;
            default:
                return "https://api.dev.neurogenn.com";
        }
    },
};

export default config;