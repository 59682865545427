import { apiSlice } from "../apiSlice";

export const historySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHistory: builder.query({
            query: () => ({
                url: "api/v1/usegethistory/",
            }),
            providesTags: ["User"],
        }),
        getBookmark: builder.query({
            query: () => ({
                url: "api/v1/getbookmarks/",
            }),
            providesTags: ["User"],
        }),
    }),
});

export const {
    useGetHistoryQuery,
    useGetBookmarkQuery,

} = historySlice;