import React, { useEffect, useRef, useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/research.css";
import { useResearchMutation } from "../store/resarch/researchApiSlice";
import { FaXmark } from "react-icons/fa6";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { useAddbookmarkMutation } from "../store/General/generalSlice";
import { useGetresearchQuery } from "../store/resarch/researchApiSlice";
import LoadingScreen from "../elements/loading";
import { toast } from "react-toastify";
import handleDownload from "../components/helpers";
const reportimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/report.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";
const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
export default function ResearchReport() {
  const [result, setResult] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const uid = params.get("id");
  const { data, isLoading } = useGetresearchQuery(uid, { skip: !uid });
  useEffect(() => {
    if (data?.generated_html) {
      setResult(true);
    }
  }, [data]);
  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  useEffect(() => {
    if (bookmarkError) {
      toast.error("Error adding to bookmark");
    } else if (bo) {
      toast.info("Added to bookmark");
    }
  }, [bookmarkError, bo]);
  const [form, setForm] = useState({
    title: "",
    urls: "",
    research_type: "casereport",
    describe: "",
    file_uploads: [],
  });

  const [
    research,
    {
      data: researchData,
      error: researchError,
      isLoading: researchLoading,
      isSuccess: researchSuccess,
    },
  ] = useResearchMutation();

  const handleSumbmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("urls", form.urls);
    formData.append("research_type", form.research_type);
    formData.append("describe", form.describe);
    form.file_uploads.forEach((file) => {
      formData.append("file_uploads", file);
    });

    const created = await research(formData);
    if (created?.error) {
      const utcDate = created?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(
        `${created?.error?.data?.error} please try after ${istDate}!`
      );
    }
  };

  useEffect(() => {
    if (researchSuccess) {
      setResult(true);
    }
  }, [researchError, researchSuccess, researchData]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const resultRef = useRef();
  return (
    <div className="homemain">
      <SideBar
        selected="Report"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
        research={true}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Research Report"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="homemainleftouter">
            <p>Let us assist you in</p>
            <h3>Creating A Case Report</h3>
          </div>
        </div>
        {researchLoading ? (
          <LoadingScreen size={"w-[40rem] absolute "} />
        ) : (
          <div className="researchbody">
            <div className="researchelement">
              <div className="researchelementleft">
                <form onSubmit={handleSumbmit}>
                  <h5>Specify the Title of Your Case Report</h5>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    required
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    value={form.title}
                  />
                  <h5>Describe Your Research Project</h5>
                  <textarea
                    placeholder="Enter Here"
                    onChange={(e) =>
                      setForm({ ...form, describe: e.target.value })
                    }
                    value={form.describe}
                  ></textarea>
                  <h5>
                    Add URL/s of the Specific Articles you want us to go through
                  </h5>
                  <input
                    onChange={(e) => setForm({ ...form, urls: e.target.value })}
                    value={form.urls}
                    type="text"
                    placeholder="Enter Here"
                  />
                  <h5>
                    Provide essential data, including Investigatory/ Imaging
                    files of Your Case
                  </h5>

                  <p>Image Upload/ PDF Upload</p>
                  <div className="fileupload">
                    <input
                      type="file"
                      id="file"
                      accept=".pdf, image/*"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          file_uploads: [
                            ...form.file_uploads,
                            e.target.files[0],
                          ],
                        });
                      }}
                      style={{ display: "none" }}
                    />

                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      upload here {<img src={UploadIcon} alt="Upload" />}
                    </label>

                    {form.file_uploads.length
                      ? form.file_uploads.map((file, index) => (
                          <label>
                            <img
                              style={{ width: "40px", height: "40px" }}
                              src={
                                file ? URL.createObjectURL(file) : UploadIcon
                              }
                              alt="Upload"
                            />{" "}
                            {file && <p>{file.name}</p>}
                            {!file && (
                              <img src={UploadIcon} alt="Upload" />
                            )}{" "}
                            <FaXmark
                              style={{
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const files = form.file_uploads.filter(
                                  (_, i) => i !== index
                                );
                                setForm((prev) => ({
                                  ...prev,
                                  file_uploads: files,
                                }));
                              }}
                            />
                          </label>
                        ))
                      : null}
                  </div>
                  {result ? (
                    ""
                  ) : (
                    <button
                      disabled={researchLoading}
                      type="submit"
                      className="generatebutton"
                    >
                      {researchLoading ? "Generating..." : "Generate"}
                    </button>
                  )}
                </form>
                {result ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="elementselector bookmarkselector"
                        onClick={async () => {
                          setBookmark(!bookmark);
                          await addBookmark({
                            page: "bookmarkresearch",
                            id: researchData?.data?.uuid || data?.uuid,
                          });
                        }}
                      >
                        {bookmark ? (
                          <img src={bookmarkIcon} alt="Bookmark" />
                        ) : (
                          <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                        )}
                      </div>
                      <div
                        className="elementselector"
                        onClick={async () => {
                          handleDownload(resultRef);
                        }}
                      >
                        Download
                      </div>
                    </div>
                    <div
                      ref={resultRef}
                      dangerouslySetInnerHTML={{
                        __html:
                          researchData?.data?.generated_html ||
                          data?.generated_html,
                      }}
                    />
                    <div className="dashboardresponsefeedback">
                      <p>Do you like the Response?</p>
                      <img src={ThumbsUp} alt="Thumbs Up" />
                      <img src={ThumbsDown} alt="Thumbs Down" />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {result ? (
                ""
              ) : (
                <div className="researchelementright">
                  <img src={reportimage} alt="Article" />
                </div>
              )}
            </div>
          </div>
        )}
        {result ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div className="dashboardresponsefeedback">
              <p>Do you like the Response?</p>
              <img src={ThumbsUp} alt="Thumbs Up" />
              <img src={ThumbsDown} alt="Thumbs Down" />
            </div>
            <div
              className="elementselector bookmarkselector"
              onClick={async () => {
                setBookmark(!bookmark);
                await addBookmark({
                  page: "bookmarkresearch",
                  id: researchData?.data?.uuid,
                });
              }}
            >
              {bookmark ? (
                <img src={bookmarkIcon} alt="Bookmark" />
              ) : (
                <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
