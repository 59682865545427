import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../elements/sidebar";
import {
  useCheckRunningTestQuery,
  useCreateCaseStudyMutation,
  useGetAllCaseStudyQuery,
  useGetUserScoreQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import AchievementsRow from "../elements/achievementsrow";

const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";
const TickIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/stepdone.svg";
const step1selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step1selected.svg";
const step2selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2selected.svg";
const step3selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3selected.svg";
const step4selected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4selected.svg";
const step2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step2.svg";
const step3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step3.svg";
const step4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/step4.svg";

const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const ExamIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/exam.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
export default function QBank() {
  const [selectedValue, setSelectedValue] = useState("");
  const { data: score } = useGetUserScoreQuery();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [qbankState, setQbankState] = useState("Case");
  const [doneStage, setDoneStage] = useState(0);
  const navigate = useNavigate();
  const navigateTo = () => {
    if (selectedValue === 1) {
      navigate("/qbank-selection/subjectwise");
    } else if (selectedValue === 2) {
      navigate("/qbank-selection/systemwise");
    }
  };

  return (
    <div className="qbankbackground homemain">
      <SideBar
        selected="QBank"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="QBank"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
            <div
              className="closesidebarbuttonmobileheader"
              onClick={toggleSidebar}
            >
              <img src={GripLine} alt="Arrow" />
            </div>
          </div>
          <div className="homemainleftouter">
            <h3>Smart QBank</h3>
          </div>
        </div>
        <div className="clinicalbodyelements clinicalintro">
          <div className="flexrow">
            <h4>Welcome to The World’s First Smartest Med-QBank</h4>
          </div>
          <hr />
          <p>
            <strong>Don’t Follow The Crowd, Lead It.</strong>
          </p>
          <p>
            Why Fit In When You Can Customize Your Path to Success? <br />
            NeuroGEN’s Smart QBank Adapts to Your Scores—Subject, System, or
            Board-Specific. <br />
            The Smartest Doctors Don’t Just Study, They Strategize.
          </p>
        </div>
        {console.log({ score })}
        <div className="clinicalbodyelements achievementsrowmobile">
          <div className="clinicalelementheader">
            <h4>Achievements</h4>
          </div>
          <hr />
          <AchievementsRow UserScore={score?.score} />
        </div>
        {/* <div className="clinicalbodyelements">
          <div className="clinicalelementheader">
            <h4>Stats</h4>
          </div>
          <hr />
        </div> */}
        <div className="clinicalbodyelements">
          <div className="clinicalelementheader">
            <h4>Choose Your Approach</h4>
          </div>
          <hr />
          <div className="clinicalrowwhole">
            <div className="clinicalrow clinicalrowtype clinicalrowexams">
              <div
                className={
                  selectedValue === 1
                    ? "clinicalsubjectselected qbankbuttonssubject"
                    : "clinicalsubject clinicalsubjectbuttons qbankbuttonssubject"
                }
                onClick={() => setSelectedValue(1)}
              >
                <img src={ExamIcon} alt="Subject Wise" />
                <p>Subject Wise</p>
              </div>
              <div
                className={
                  selectedValue === 2
                    ? "clinicalsubjectselected qbankbuttonssubject"
                    : "clinicalsubject clinicalsubjectbuttons qbankbuttonssubject"
                }
                onClick={() => setSelectedValue(2)}
              >
                <img src={AnatomyIcon} alt="System Wise" />
                <p>System Wise</p>
              </div>
            </div>
          </div>
          <button className="clinicalbodyelementbutton" onClick={navigateTo}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
