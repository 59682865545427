import React from "react";

import { useNavigate } from "react-router-dom";
const tryagain = "https://neuro-gen.s3.amazonaws.com/images/icons/tryagain.svg";

export default function ClinicalTryAgain({ isVisible, onClose }) {
  const navigate = useNavigate();
  const handleTryAgain = () => {
    onClose();
    navigate("/clinicalhome");
  };
  const handleHome = () => {
    onClose();
    navigate("/dashboard");
  };

  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content-congrats"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={tryagain} alt="Congrats" className="congratsimage" />
        <h1>Try Again</h1>
        <p>
          You have not scored minimum point to proceed to your next case. Please
          try again
        </p>

        <div className="contentcenter">
          <button className="elementselector" onClick={handleHome}>
            Home
          </button>
          <button className="elementselectorfilled" onClick={handleTryAgain}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}
