import React, { useState } from "react";

const instagram =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/instagram.svg";
const facebook =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/facebook.svg";
const telegram =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/telegram.svg";
const chatGpt =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/chatgpt.svg";
const whatsapp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/whatsapp.svg";
const linkedin =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/linkedin.svg";
const twitter =
  "https://neuro-gen.s3.amazonaws.com/images/icons/share/twitter.svg";
const copybutton =
  "https://neuro-gen.s3.amazonaws.com/images/icons/copybutton.svg";

export default function SharePopup({ isVisible, onClose, inputvalue }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(inputvalue).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const shareToPlatform = (platform) => {
    let url = "";

    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          inputvalue
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          inputvalue
        )}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          inputvalue
        )}`;
        break;
      case "telegram":
        url = `https://t.me/share/url?url=${encodeURIComponent(inputvalue)}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          inputvalue
        )}`;
        break;

      default:
        return;
    }

    window.open(url, "_blank");
  };
  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="shareoverlay" onClick={(e) => e.stopPropagation()}>
        <h4>Share with your friends</h4>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's.
        </p>
        <div className="socialsimageshare">
          <img
            src={instagram}
            alt="Instagram"
            onClick={() => shareToPlatform("instagram")}
          />
          <img
            src={telegram}
            alt="Telegram"
            onClick={() => shareToPlatform("telegram")}
          />
          <img
            src={chatGpt}
            alt="ChatGPT"
            onClick={() => shareToPlatform("chatgpt")}
          />
          <img
            src={whatsapp}
            alt="WhatsApp"
            onClick={() => shareToPlatform("whatsapp")}
          />
          <img
            src={linkedin}
            alt="LinkedIn"
            onClick={() => shareToPlatform("linkedin")}
          />
          <img
            src={twitter}
            alt="Twitter"
            onClick={() => shareToPlatform("twitter")}
          />
          <img
            src={facebook}
            alt="Facebook"
            onClick={() => shareToPlatform("facebook")}
          />
        </div>
        <div className="linkshare">
          <input type="text" value={inputvalue} readOnly />
          <button onClick={handleCopyClick}>
            {isCopied ? (
              <p>Copied</p>
            ) : (
              <>
                <img src={copybutton} alt="Copy" />
                <p>Copy</p>
              </>
            )}
          </button>
        </div>
        <div className="contentright">
          <button className="elementselector" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
