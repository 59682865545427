import axios from "axios";
import config from "./APIEndpoints";
import Cookies from "js-cookie";

console.log(config.getEndpoint());
export const app_api = axios.create({
    baseURL: config.getEndpoint(),
});

app_api.interceptors.request.use((config) => {
    if (!!Cookies.get("token")) {
        const token = Cookies.get("token");
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

app_api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default app_api;