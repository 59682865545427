import React, { useEffect, useState } from "react";
import "../styles/clinicalmcq.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import { BsFillPatchCheckFill } from "react-icons/bs";
import ClinicalCongratsQBank from "../elements/clinicalcongratsqbank";
import CorrectAnswer from "../components/correctanswer";
import WrongAnswer from "../components/wronganswer";
import { useParams, useNavigate } from "react-router-dom";
import SkippedAnser from "../components/skippedanswer";

import {
  useGetQuestionsQuery,
  useGetSolutionQuery,
  useGetRunningTestQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import {
  useGetGeneratedQBankQuery,
  useSubmitQBankMutation,
} from "../store/QuestionBamk/QBApiSlice";
import { useSubmitQuestionMutation } from "../store/caseSimulation/CaseStudyApiSlice";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import { useAddbookmarkMutation } from "../store/General/generalSlice";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const TimeIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/time.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";

function Question({
  question,
  type,
  qbmode,
  submitquestion,
  submitquestionState,
  expired,
}) {
  const [answer, setanswer] = useState();
  const [explain, setexplain] = useState();
  const [submit, setsubmit] = useState();
  const [tempanswer, settempanser] = useState(null);
  useEffect(() => {
    setsubmit(false);
    if (question?.status == "ANSWERED") {
      setsubmit(true);
      setanswer(question?.answer_option);
    } else {
      setanswer();
      setexplain("");
    }
  }, [question]);

  const bookmarkNotSelectedIcon =
    "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
  const bookmarkIcon =
    "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
  const [bookmark, setbookmark] = useState(false);

  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  useEffect(() => {
    if (bookmarkError) {
      toast.error("Error adding to bookmark");
    } else if (bo) {
      toast.info("Added to bookmark");
    }
  }, [bookmarkError, bo]);

  return (
    <div className=" w-full flex flex-col">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label className="clinicalquestionvaluetext-[14px]">
          {question?.question?.question}
        </label>
        <div
          className="elementselector bookmarkselector"
          onClick={async () => {
            await addBookmark({
              page: "qbankquestion",
              id: question?.uuid,
            });
            setbookmark(!bookmark);
          }}
        >
          {bookmark ? (
            <img src={bookmarkIcon} alt="Bookmark" />
          ) : (
            <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
          )}
        </div>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await submitquestion({
            uid: question?.uuid,
            data: {
              answer_option: answer,
              status: "ANSWERED",
            },
          });
          if (answer == question?.question?.correct_option) {
            toast.success("🔥Correct! +4");
          } else {
            toast.error("👎InCorrect! -1");
          }

          setsubmit(true);
        }}
      >
        <div className="clinicalbodyoptions">
          {question?.question?.options?.map((rec, index) => (
            <div className="clinicalbodyoption mt-[17px]">
              <input
                type="radio"
                name="clinicalOption"
                id={index}
                value={rec}
                checked={
                  (expired && question?.status == "PENDING") ||
                  qbmode == "QBANK"
                    ? tempanswer == index
                    : answer === index
                }
                onChange={() =>
                  qbmode == "QBANK" ? settempanser(index) : setanswer(index)
                }
                disabled={question?.status == "ANSWERED" ? true : false}
                required
              />
              <label htmlFor={index}>{rec}</label>

              {((submit && index == question?.question?.correct_option) ||
                (question?.status == "SKIPPED" &&
                  index == question?.question?.correct_option) ||
                (expired && index == question?.question?.correct_option) ||
                (qbmode == "QBANK" &&
                  tempanswer >= 0 &&
                  index == question?.question?.correct_option)) && (
                <FaCheck className="text-green-500" />
              )}
            </div>
          ))}
        </div>

        {!submit &&
          question?.status != "SKIPPED" &&
          !expired &&
          qbmode != "QBANK" && (
            <div className="flex flex-row gap-5">
              <button type="submit" className="clinicalmcqsubmit mt-[16px]">
                {submitquestionState?.isLoaidng ? "Submitting..." : "Submit"}
              </button>
              <button
                onClick={async () => {
                  setsubmit(true);

                  await submitquestion({
                    uid: question?.uuid,
                    data: {
                      status: "SKIPPED",
                    },
                  });
                }}
                className="clinicalmcqsubmit mt-[16px]"
              >
                {submitquestionState?.isLoaidng ? "Skip..." : "Skip"}
              </button>
            </div>
          )}
        {submit && answer == question?.question?.correct_option && (
          <CorrectAnswer explain={question?.question?.explain} />
        )}
        {submit && answer != question?.question?.correct_option && (
          <WrongAnswer explain={question?.question?.explain} />
        )}

        {((expired && question?.status == "PENDING" && qbmode == "EXAM") ||
          (qbmode == "QBANK" && tempanswer >= 0)) && (
          <SkippedAnser explain={question?.question?.explain} />
        )}
        {question?.status == "SKIPPED" && qbmode == "EXAM" && (
          <SkippedAnser explain={question?.question?.explain} />
        )}
      </form>
    </div>
  );
}
export default function QBankQuestion() {
  const [bookmark, setBookmark] = useState(false);
  const navigate = useNavigate();
  const [submitquestion, submitquestionstate] = useSubmitQBankMutation();
  const [qbmode, setqbmode] = useState();
  const [timeRemaining, setTimeRemaining] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const [stats, setstats] = useState({});
  const record_id = params.get("id");
  const mode = params.get("source");
  const {
    data: questiondata,
    isLoading: questionloading,
    isError: questioniserror,
    error: questionerror,
  } = useGetSolutionQuery(record_id, {
    skip: mode != "case",
  });
  const {
    data: qbdata,
    isLoading: qbloading,
    isError: qbiserror,
    error: qberror,
  } = useGetGeneratedQBankQuery(record_id, {
    skip: mode != "QBANK",
  });
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionVisible, setQuestionVisible] = useState(false);
  const handleOptionChange = (event) => {
    if (!isSubmitted) {
      setSelectedOption(event.target.value);
    }
  };
  const handleSumbit = () => {
    setIsSubmitted(true);
  };
  const numbers = [];
  for (let i = 1; i < 51; i++) {
    numbers.push(i);
  }
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible("close");
  };
  useEffect(() => {
    if (qbdata?.questions) {
      const createdTime = new Date(qbdata?.questions?.created_at).getTime();
      const durationInMillis =
        qbdata?.questions?.allocated_questions?.length * 60 * 1000;
      const endTime = createdTime + durationInMillis;

      const checkTime = () => {
        const currentTime = Date.now();

        const remaining = endTime - currentTime;
        if (Math.floor(remaining / 1000) < 0 && !isPopupVisible) {
          setIsPopupVisible("open");
        }
        setTimeRemaining(Math.floor(remaining / 1000));
      };

      const interval = setInterval(checkTime, 1000);

      checkTime();
      if (
        qbdata &&
        qbdata?.questions?.allocated_questions?.filter(
          (rec) => rec.status === "ANSWERED" || rec.status === "SKIPPED"
        ).length == qbdata?.questions?.allocated_questions?.length
      ) {
        setIsPopupVisible("open");
      }
      return () => clearInterval(interval);
    }
  }, [qbdata]);
  // useEffect(() => {
  //   qbdata?.questuins?.allocated_questions?.forEac
  // }, [qbdata]);
  console.log(qbdata);
  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  useEffect(() => {
    if (bookmarkError) {
      toast.error("Error adding to bookmark");
    } else if (bo) {
      toast.info("Added to bookmark");
    }
  }, [bookmarkError, bo]);
  useEffect(() => {
    setqbmode(qbdata?.questions?.mode);
  }, [qbdata]);
  return (
    <div className="homemain">
      {/* <ClinicalCongratsQBank isVisible={isPopupVisible} onClose={togglePopup} /> */}
      <ClinicalCongratsQBank
        isVisible={qbdata && isPopupVisible == "open"}
        onClose={togglePopup}
        total={qbdata?.questions?.allocated_questions?.length}
        correct={
          qbdata?.questions?.allocated_questions?.filter(
            (data, number) =>
              data?.question?.correct_option == data?.answer_option
          ).length
        }
        incorrect={
          qbdata?.questions?.allocated_questions?.filter(
            (data, number) =>
              data?.question?.correct_option != data?.answer_option &&
              data?.status == "ANSWERED"
          ).length
        }
        skipped={
          qbdata?.questions?.allocated_questions?.filter(
            (data, number) =>
              data?.status == "SKIPPED" || data?.status == "PENDING"
          ).length
        }
      />

      <SideBar
        selected="QBank"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="QBank"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clincalbody">
          {mode != "case" && (
            <div className="clinicalprogress">
              <h5>Case Progress</h5>
              <div className="clinicalprogressestimate">
                <h4>
                  {" "}
                  {qbdata?.questions?.allocated_questions?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <h4>
                        {(
                          (qbdata?.questions?.allocated_questions?.filter(
                            (rec) =>
                              rec.status === "ANSWERED" ||
                              rec.status === "SKIPPED"
                          ).length /
                            qbdata?.questions?.allocated_questions?.length) *
                          100
                        ).toFixed(2)}
                        % to Complete
                      </h4>
                      <div
                        className="elementselector bookmarkselector"
                        onClick={async () => {
                          await addBookmark({
                            page: "qbankbookmark",
                            id: qbdata?.questions?.uuid,
                          });
                          setBookmark(!bookmark);
                        }}
                      >
                        {bookmark ? (
                          <img src={bookmarkIcon} alt="Bookmark" />
                        ) : (
                          <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                        )}
                      </div>
                    </div>
                  )}
                </h4>

                <div className="clinicalprogressestimatetime">
                  <img src={TimeIcon} alt="Time" />
                  <p>
                    {timeRemaining < 0 ? `Expired` : `${timeRemaining} seconds`}
                  </p>
                </div>
              </div>
              <div className="clinicalprogresspercentage">
                <div
                  style={{
                    width: `${
                      (qbdata?.questions?.allocated_questions?.filter(
                        (rec) =>
                          rec.status === "ANSWERED" || rec.status === "SKIPPED"
                      ).length /
                        qbdata?.questions?.allocated_questions?.length) *
                      100
                    }%`,
                  }}
                  className="clinicalprogressbar"
                ></div>
              </div>
            </div>
          )}

          <div className="qbankbodyelements ">
            <div className="clinicalbodyqbankleft flex flex-col gap-10">
              {mode == "case" &&
                questiondata &&
                questiondata?.map((rec, index) => (
                  <>
                    <form className="flex flex-col">
                      <div className="clinicalbodyoptions">
                        <div className="questionclinicalheader">
                          <h1>Question {index + 1}</h1>

                          <div className="aisearchbodyright">
                            <div
                              className="elementselector bookmarkselector"
                              onClick={async () => {
                                await addBookmark({
                                  page:
                                    mode === "case"
                                      ? "casestudyquestion"
                                      : "qbankquestion",
                                  id: rec?.uuid,
                                });
                              }}
                            >
                              {rec?.bookmark ? (
                                <img src={bookmarkIcon} alt="Bookmark" />
                              ) : (
                                <img
                                  src={bookmarkNotSelectedIcon}
                                  alt="Bookmark"
                                />
                              )}
                            </div>
                            {/* <div className="elementselector">Previous</div>
                        <div className="elementselector" onClick={togglePopup}>
                          Next
                        </div> */}
                          </div>
                          {/* )} */}
                        </div>
                        <hr />
                        <div className="clinicalelementbodyheader headerbodybottom">
                          <strong>{rec?.question}</strong>
                        </div>
                        {rec?.options?.map((opt, optindex) => (
                          <div className="clinicalbodyoption">
                            <input
                              type="radio"
                              name="clinicalOption"
                              id={`option${optindex}`}
                              value="1"
                              checked={optindex === rec?.answer_option}
                              disabled={true}
                              required
                            />
                            <label htmlFor={`option${optindex}`}>{opt}</label>
                            {optindex == rec?.correct_option && (
                              <FaCheck className="text-green-500" />
                            )}
                          </div>
                        ))}
                      </div>

                      {rec?.answer_option === rec?.correct_option ? (
                        <CorrectAnswer explain={rec?.reason} />
                      ) : rec?.status == "PENDING" ||
                        rec?.status == "SKIPPED" ? (
                        <SkippedAnser explain={rec?.reason} />
                      ) : (
                        <WrongAnswer explain={rec?.reason} />
                      )}
                      {isSubmitted && mode != "case" && (
                        <div className="powernotesall">
                          <div className="clinicalelementheader powernotes">
                            <div className="clinicalimageheader">
                              <h4>PowerNotes</h4>
                            </div>
                          </div>
                          <hr />
                          <div className="clinicalelementbodyevaluation">
                            <div className="textareabox">
                              <strong>Overall Score: 36/40</strong>
                              <ul>
                                <li>
                                  <strong>Leadership: 8/10 : </strong>
                                  The student demonstrates a proactive approach
                                  with a clear, structured plan. Slightly more
                                  emphasis on team coordination could enhance
                                  leadership.
                                </li>
                                <li>
                                  <strong>Clinical Knowledge: 9:10 : </strong>
                                  Strong understanding of aortic dissection,
                                  diagnostic strategies, and management.
                                  Mentioning differential diagnoses could show
                                  even broader knowledge.
                                </li>
                                <li>
                                  <strong>
                                    Emotional Intelligence: 8/10 :{" "}
                                  </strong>
                                  The response shows concern for patient comfort
                                  with pain management. Acknowledging patient
                                  and family communication could further elevate
                                  emotional intelligence.
                                </li>
                                <li>
                                  <strong>Decision Making: 9/10 : </strong>
                                  Logical, evidence-based decisions are made,
                                  prioritizing the patient’s immediate needs.
                                  Briefly considering potential complications
                                  would strengthen this further.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </>
                ))}
              {mode != "case" &&
                qbdata &&
                qbdata?.questions?.allocated_questions?.map(
                  (question, index) => (
                    <Question
                      qbmode={qbmode}
                      expired={timeRemaining < 0}
                      question={question}
                      submitquestion={submitquestion}
                      submitquestionstate={submitquestionstate}
                    />
                  )
                )}
              <div className="feedbackdiv">
                <div className="dashboardresponsefeedback">
                  <p>Do you like the Response?</p>
                  <img src={ThumbsUp} alt="Thumbs Up" />
                  <img src={ThumbsDown} alt="Thumbs Down" />
                </div>
              </div>
            </div>

            {mode == "case" && (
              <div className="clinicalbodyqbankright">
                <div className="qbankquestionsnumbering">
                  {questiondata?.map((data, number) => (
                    <div
                      className={`qbankquestionnumber 
                      ${
                        data?.correct_option == data?.answer_option
                          ? "qbankquestionnumbercurrent"
                          : data?.status == "SKIPPED" ||
                            data?.status == "PENDING"
                          ? "bg-yellow-500 text-white"
                          : "bg-red-500 text-white"
                      }`}
                    >
                      {number + 1}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {mode != "case" && qbmode != "QBANK" && (
              <div className="clinicalbodyqbankright">
                <div className="qbankquestionsnumbering">
                  {qbdata?.questions?.allocated_questions?.map(
                    (data, number) => (
                      <div
                        className={`qbankquestionnumber 
                      ${
                        data?.question.correct_option == data?.answer_option
                          ? "qbankquestionnumbercurrent"
                          : data?.status == "PENDING" ||
                            data?.status == "SKIPPED"
                          ? "bg-yellow-500 text-white"
                          : "bg-red-500 text-white"
                      }`}
                      >
                        {number + 1}
                      </div>
                    )
                  )}
                </div>
                <div className="qbankquestionsnumbering mt-10 flex flex-col">
                  <h2 className="bg-breen-100 border p-3 text-green-700 border-green-500 rounded-lg text-[1.5rem] text-start flex flex-col">
                    <label className="text text-[1rem]">Correct Answer</label>

                    {
                      qbdata?.questions?.allocated_questions?.filter(
                        (data, number) =>
                          data?.question?.correct_option == data?.answer_option
                      ).length
                    }
                  </h2>
                  <h2 className="bg-yellow-100 border text-yellow-700 p-3 border-yellow-500 rounded-lg text-[1.5rem] text-start flex flex-col">
                    <label className="text text-[1rem]">Skipped Answer</label>
                    {
                      qbdata?.questions?.allocated_questions?.filter(
                        (data, number) =>
                          data?.status == "SKIPPED" || data?.status == "PENDING"
                      ).length
                    }
                  </h2>
                  <h2 className="bg-red-100 border text-red-700 p-3 border-red-500 rounded-lg text-[1.5rem] text-start flex flex-col">
                    <label className="text text-[1rem]">Wrong Answer</label>
                    {
                      qbdata?.questions?.allocated_questions?.filter(
                        (data, number) =>
                          data?.question?.correct_option !=
                            data?.answer_option && data?.status == "ANSWERED"
                      ).length
                    }
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="usagewarning">
          **This is intended for educational purpose only!
        </p>
      </div>
    </div>
  );
}
