import React, { useEffect, useState } from "react";
import "../styles/clinicalhome.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import CompletedCases from "../components/completedcases";
import { useNavigate } from "react-router-dom";
import { GiComputerFan } from "react-icons/gi";
import {
  useCheckRunningTestQuery,
  useCreateCaseStudyMutation,
  useGetAllCaseStudyQuery,
  useGetUserScoreQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import HomemainHeaderMobile from "../components/homemainheadermobile";

const MedicalRecordicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/medicalrecord.svg";
const RandomIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/random.svg";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const BiochemistryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/biochemistry.svg";
const PathologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pathology.svg";
const PharmacologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pharmacology.svg";
const PhysiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/physiology.svg";
const MicrobiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/microbiology.svg";
const ImmunologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/immunology.svg";
const PublicIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/public.svg";

const GeneralMedicineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/generalmedicine.svg";

const SurgeryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/surgery.svg";

const CardioIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/cardio.svg";
const EndocrineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/endocrine.svg";
const GasIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gas.svg";
const BloodIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/blood.svg";
const OncologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/oncology.svg";
const PaediatricsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/paedtrics.svg";

const GynacIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gynac.svg";

const MuscleIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/muscle.svg";

const NeurologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neurology.svg";

const ReproductionIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/reproduction.svg";

const RespiratoryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/respiratory.svg";

const RenalIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/renal.svg";
const SearchIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/search.svg";

const Achivement1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/1.svg";
const Achivement2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/2.svg";
const Achivement3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/3.svg";
const Achivement4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/4.svg";
const Achivement5 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/5.svg";
const Achivement6 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/6.svg";
const Achivement7 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/7.svg";
const Achivement8 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/8.svg";
const Achivement9 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/9.svg";
const Achivement10 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/10.svg";
const GapLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/line.svg";

const aiimsicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/aiims.svg";
const usmleicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/usmle.svg";
const plabicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/plab.svg";
const neeticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neet.svg";
const mcqicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/mcq.svg";
const detailedicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/detailed.svg";
const TickIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/stepdone.svg";

export const ClinicalSubjects = ({ nextStage }) => {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const subjects = [
    { name: "Random", icon: RandomIcon },
    { name: "Anatomy", icon: AnatomyIcon },
    { name: "Physiology", icon: PhysiologyIcon },
    { name: "Biochemistry", icon: BiochemistryIcon },
    { name: "Pharmacology", icon: PharmacologyIcon },
    { name: "Pathology", icon: PathologyIcon },
    { name: "Microbiology", icon: MicrobiologyIcon },
    { name: "Immunology", icon: ImmunologyIcon },
    { name: "Public", icon: PublicIcon, displayName: "Public Health Sciences" },
    {
      name: "General",
      icon: GeneralMedicineIcon,
      displayName: "General Medicine",
    },
    { name: "Surgery", icon: SurgeryIcon },
    { name: "Cardiology", icon: CardioIcon },
    { name: "Endocrinology", icon: EndocrineIcon },
    { name: "Gastroenterology", icon: GasIcon },
    { name: "Blood", icon: BloodIcon },
    { name: "Oncology", icon: OncologyIcon },
    { name: "Paediatrics", icon: PaediatricsIcon },
    { name: "Gynaecology", icon: GynacIcon },
    { name: "Muscle", icon: MuscleIcon },
    { name: "Neurology", icon: NeurologyIcon },
    { name: "Reproduction", icon: ReproductionIcon },
    { name: "Respiratory", icon: RespiratoryIcon },
    { name: "Renal", icon: RenalIcon },
  ];

  const filteredSubjects = subjects.filter(
    (subject) =>
      subject.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      subject.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="clinicalsubjectsmobile">
        <div className="homemainrightheader mb-5 mobileonly">
          <h4>Select Subject</h4>
          <div className="inputdiv">
            <img src={SearchIcon} alt="Search" />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {filteredSubjects.map((subject) => (
          <div
            key={subject.name}
            className={
              selectedSubject === subject.name
                ? "clinicalsubjectselected"
                : "clinicalsubject"
            }
            onClick={() => setSelectedSubject(subject.name)}
          >
            <img src={subject.icon} alt={subject.name} />
            <p>{subject.displayName || subject.name}</p>
          </div>
        ))}
      </div>

      {selectedSubject !== "" && (
        <button
          className="elementselector w-full mt-2 fixedbutton"
          onClick={nextStage}
        >
          Next
        </button>
      )}
    </>
  );
};

export const ClinicalExam = ({ nextStage }) => {
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedOption, setSelectOption] = useState(0);
  const [selectedValue, setSelectedValue] = useState(0);
  const options = [];
  var i = 0;
  for (i = 1; i <= 20; i++) {
    options.push(i);
  }

  return (
    <>
      <div className="homemainrightheader mb-10 mobileonly">
        <h4>Select Question Type</h4>
        <div className="clinicalquestontypes">
          <div
            className={
              selectedValue === 1
                ? "clinicalquestionsselected"
                : "clinicalquestion "
            }
            onClick={() => setSelectedValue(1)}
          >
            <img src={mcqicon} alt="MCQ" />
            <p>MCQ</p>
          </div>
          <div
            className={
              selectedValue === 2
                ? "clinicalquestionsselected"
                : "clinicalquestion "
            }
            onClick={() => {
              setSelectedValue(2);
              setSelectOption(5);
            }}
          >
            <img src={detailedicon} alt="Elaborative Type" />
            <p>Elaborative Type</p>
          </div>
        </div>
      </div>
      {selectedValue == 1 && (
        <div className="homemainrightheader mb-5">
          <h4>Select Number Of Question</h4>
          <div className="subcategoryrowrightinner mt-2">
            <div className="questionselect">
              {options.map((option) => (
                <div
                  key={option}
                  className={
                    selectedOption === option
                      ? "subcategoryoptionselected"
                      : "subcategoryoption"
                  }
                  onClick={() => setSelectOption(option)}
                >
                  <h5>{option}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedOption != 0 && selectedValue != "" && (
        <button className="elementselector w-full mt-2" onClick={nextStage}>
          Next
        </button>
      )}
    </>
  );
};
export default function ClinicalMobile() {
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const { data: score, isLoading: scoreLoading } = useGetUserScoreQuery();
  const [stateDone, setStateDone] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        navigate("/clinical");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);
  const navigateTo = () => {
    if (selectedValue === 1) {
      navigate(`/clinicalcase/mcq`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (selectedValue === 2) {
      navigate(`/clinicalcase/detailed`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      alert("Please select a question type");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [shouldFetch, setShouldFetch] = useState(false);

  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Clinical Case"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        {stateDone === 0 && (
          <ClinicalSubjects nextStage={() => setStateDone(1)} />
        )}
        {stateDone === 1 && (
          <ClinicalExam
            nextStage={() => {
              navigate(`/clinicalcase/mcq`);
            }}
          />
        )}
      </div>
    </div>
  );
}
