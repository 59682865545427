import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const crossicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crossicon.svg";

const notesicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/popups/notes.svg";

const qbankicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/popups/qbank.svg";
const videoicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/popups/videos.svg";
const caseicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/popups/case.svg";
export default function SubjectsPopup({ isVisible, onClose, subject }) {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("SubjectsPopup rendered", subject);
  });
  if (!isVisible) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="subjectspopup" onClick={(e) => e.stopPropagation()}>
        <div className="clinicalbodyelements clinicalintro">
          <div className="flexrow justify-between">
            <h4>Choose Your Approach</h4>
            <img src={crossicon} alt="Close" onClick={onClose} />
          </div>
          <hr />
          <div className="subjectapproach">
            <div
              class="clinicalsubject clinicalsubjectbuttons qbankbuttonssubject"
              onClick={() => navigate("/clinical")}
            >
              <img src={caseicon} alt="Case" />
              <p>Case Simulator</p>
            </div>
            <div
              class="clinicalsubject clinicalsubjectbuttons qbankbuttonssubject"
              onClick={() => navigate("/qbank")}
            >
              <img src={qbankicon} alt="QBank" />
              <p>Q Bank</p>
            </div>
            <div class="clinicalsubject clinicalsubjectbuttons qbankbuttonssubject">
              <img src={notesicon} alt="Notes" />
              <p>Power Notes (Coming Soon)</p>
            </div>
            <div class="clinicalsubject clinicalsubjectbuttons qbankbuttonssubject">
              <img src={videoicon} alt="Videos" />
              <p>Videos (Coming Soon)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
