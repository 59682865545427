import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-number-input/style.css";
import Cookies from "js-cookie";
import { useCheckProfileCompleteQuery } from "../store/profile/UserApiSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerUser, setCredentials } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateUserMutation,
  useRegisterMutation,
} from "../store/profile/UserApiSlice";
import config from "../config/APIEndpoints";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { logOut } from "../store/authSlice";
import axios from "axios";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const ViewIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpassword.svg";
const TickIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/tick.svg";
const GoogleLogo = "https://neuro-gen.s3.amazonaws.com/images/icons/google.svg";
const AppleLogo = "https://neuro-gen.s3.amazonaws.com/images/icons/apple.svg";

const ViewOpen =
  "https://neuro-gen.s3.amazonaws.com/images/icons/viewpasswordopen.svg";
const roles = [
  "Medical Aspirant",
  "Medical Student",
  "Intern",
  "Junior Resident",
  "Senior Resident",
  "Medical Researcher",
  "Consultant",
  "Clinical Specialist",
  "Fellowship",
  "Gene Scientist",
  "Clinical Psychologist",
  "Nutritionist",
  "Medical Educator",
  "Healthcare IT Professional",
  "Paramedic",
  "Public Health Professional",
  "Healthcare administrator",
  "Others",
];

export const RegisterMain = ({ nextStage, doneStage }) => {
  const navigate = useNavigate();
  const [registercall, registerstate] = useRegisterMutation();
  const NavigateToHome = () => {
    nextStage();
    doneStage();
  };
  const dispatch = useDispatch();
  const [viewState, setViewState] = useState("password");
  const [viewState2, setViewState2] = useState("password");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      await registercall({
        email: values.email,
        password: values.password,
      }).then((resp) => {
        if (resp?.error?.data) {
          for (const key in resp?.error?.data) {
            if (resp?.error?.data.hasOwnProperty(key)) {
              resp?.error?.data[key].forEach((message) => {
                toast.error(`${key}: ${message}`); // Display each message with the key
              });
            }
          }
        } else if (resp?.data) {
          toast.success("email sent successfully");
        }
      });

      // nextStage();
      // doneStage();
    },
  });
  const handleView = () => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
      setViewState("text");
    } else {
      password.type = "password";
      setViewState("password");
    }
  };
  const handleView2 = () => {
    const password = document.getElementById("confirmpassword");
    if (password.type === "password") {
      password.type = "text";
      setViewState2("text");
    } else {
      password.type = "password";
      setViewState2("password");
    }
  };
  const navigateToLogin = () => {
    navigate("/");
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .post(`${config.getEndpoint()}/api/v1/auth/google/`, {
          access_token: codeResponse.access_token,
          backend: "google-oauth2",
          grant_type: "convert_token",
          client_id: process.env.CLIENT,
          client_secret: process.env.SECRET,
        })
        .then((res) => {
          dispatch(
            setCredentials({
              refresh: res?.data?.refresh_token,
              token: res?.data?.access_token,
            })
          );
          navigate("/dashboard");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <>
      <div className="loginheader">
        <h2>Welcome</h2>
        <p>Sign Up to NeuroLAB AI to Enter the Dashboard</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="loginform">
          <div className="flex flex-col">
            <label htmlFor="email">Email</label>
            <div className="flex flex-col w-full">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full p-2 border border-gray-300 outline-none focus:ring-0"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error text-red-500 w-full items-start">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <br />
          <div className="flex flex-col">
            <label htmlFor="password">Password</label>
            <div className="inputdiv flex">
              <input
                type="password"
                id="password"
                name="password"
                className="w-full p-2 border border-gray-300 outline-none focus:ring-0"
                placeholder="Enter your Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
              />
              {viewState === "text" ? (
                <img src={ViewOpen} alt="View" onClick={handleView} />
              ) : (
                <img src={ViewIcon} alt="View" onClick={handleView} />
              )}
            </div>{" "}
            {formik.touched.password && formik.errors.password ? (
              <div className="error text-red-500  w-full items-start">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <br />
          <div className="flex flex-col">
            <label htmlFor="confirmPassword"> Confirm Password</label>
            <div className="inputdiv flex">
              <input
                type="password"
                id="confirmpassword"
                name="confirmPassword"
                className="w-full p-2 border border-gray-300 outline-none focus:ring-0"
                placeholder="Confirm your Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                required
              />
              {viewState2 === "text" ? (
                <img src={ViewOpen} alt="View" onClick={handleView2} />
              ) : (
                <img src={ViewIcon} alt="View" onClick={handleView2} />
              )}
            </div>{" "}
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="error text-red-500 w-full items-start">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>

          <button type="submit">
            {" "}
            {registerstate?.isLoading ? "submitting..." : "Continue"}
          </button>
        </div>
      </form>
      <div className="logindivider">
        <hr />
        <p>Or</p>
        <hr />
      </div>
      <div className="loginwith">
        <button onClick={login}>
          <img src={GoogleLogo} alt="Google Logo" />
          <p>Continue with Google</p>
        </button>
        <button>
          <img src={AppleLogo} alt="Apple Logo" />
          <p>Continue with Apple</p>
        </button>
      </div>
      <div className="loginfooter">
        <p>Already have an account?</p>
        <p className="signupbutton" onClick={navigateToLogin}>
          Login
        </p>
      </div>
    </>
  );
};
export const RegisterAbout = ({ nextStage, doneStage }) => {
  const [phone, setPhone] = useState();

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone number is required"),
    role: Yup.string().required("Role is required"),
    specialization: Yup.string().required("Specialisation is required"),
  });
  const dispatch = useDispatch();
  // Formik setup
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      role: "",
      specialization: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(registerUser(values));
      nextStage();
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="loginform flex flex-col gap-0">
          <div>
            <label htmlFor="firstname">First Name</label>
            <div className="inputdiv flex flex-col">
              <input
                type="text"
                id="first_name"
                name="first_name"
                placeholder="Enter your Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="error  w-full items-start text-red-500 text-[0.8rem]">
                  {formik.errors.first_name}
                </div>
              ) : null}
            </div>
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="error mt-3 w-full items-start text-red-500 text-[0.8rem] mb-1">
                {formik.errors.first_name}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="lastname">Last Name</label>
            <div className="inputdiv flex flex-col">
              <input
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Enter your Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="error  w-full items-start text-red-500 text-[0.8rem]">
                  {formik.errors.last_name}
                </div>
              ) : null}
            </div>
            {formik.touched.last_name && formik.errors.last_name ? (
              <div className="error mt-3 w-full items-start text-red-500 text-[0.8rem] mb-1">
                {formik.errors.last_name}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="phone">Phone</label>
            <div className=" flex flex-col">
              <div className="inputdiv">
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                    formik.setFieldValue("phone", value);
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error mt-1 w-full items-start text-red-500 text-[0.8rem] mb-1">
                {formik.errors.phone}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="role mt-5">Role</label>
            <div className="inputdiv flex flex-col">
              <select
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>
                  Select your role
                </option>
                {roles.map((role, index) => (
                  <option key={index} value={role}>
                    {role}
                  </option>
                ))}
              </select>
              {formik.touched.role && formik.errors.role ? (
                <div className="error  w-full items-start text-red-500 text-[0.8rem]">
                  {formik.errors.role}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <label htmlFor="role ">Specialization</label>
            <div className="inputdiv flex flex-col">
              <input
                type="text"
                id="specialization"
                name="specialization"
                placeholder="Enter Specialization"
                value={formik.values.specialization}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.specialization && formik.errors.specialization ? (
                <div className="error w-full items-start text-red-500 text-[0.8rem] mt-2">
                  {formik.errors.specialization}
                </div>
              ) : null}
            </div>
          </div>

          <button style={{ marginTop: "30px" }} type="submit">
            Continue
          </button>
        </div>
      </form>
    </>
  );
};
export const RegisterFinish = ({ nextStage }) => {
  const NavigateToLogin = (e) => {
    e.preventDefault();
    nextStage();
  };
  const navigate = useNavigate();

  return (
    <form onSubmit={NavigateToLogin}>
      <div className="loginform">
        <div className="termsandconditions">
          <input
            type="checkbox"
            name="confirmation"
            id="confirmation"
            required
          />
          <label htmlFor="confirmation">
            By checking this box you agree to the{" "}
            <a
              onClick={() => {
                navigate("/terms");
              }}
            >
              Terms of Use{" "}
            </a>
            and{" "}
            <a
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </a>
          </label>
        </div>
        <button type="submit">Continue</button>
      </div>
    </form>
  );
};

export default function RegistrationPage() {
  const dispatch = useDispatch();
  const [registerState, setRegisterState] = useState("Register");
  const registerstate = useSelector((state) => state.auth);
  const paramstep = useParams("step");
  const token = Cookies.get("token");
  const navigte = useNavigate();
  const [doneStage, setDoneStage] = useState(0);
  const { data, isLoading } = useCheckProfileCompleteQuery();
  const [updateuser, updateuserstate] = useUpdateUserMutation();
  if (data) {
    navigte("/dashboard");
  }
  if (paramstep.step == "email" && token) {
    navigte("/register/about");
  }
  if (paramstep.step != "email" && !token) {
    navigte("/register/email");
  }

  const handleConfirmationStage = () => {
    setRegisterState("Confirmation");
  };
  return (
    <div className="loginmain relative">
      <div className="registrationstatus">
        <div className="registrationbody ">
          <div
            className={
              paramstep.step == "about" || paramstep.step === "finish"
                ? "registrationactive"
                : "registrationdue"
            }
          >
            {paramstep.step === "about" || paramstep.step === "finish" ? (
              <img src={TickIcon} alt="Done" />
            ) : (
              <p>1</p>
            )}

            <h5>Register</h5>
          </div>
          <hr />
          <div
            className={
              paramstep.step === "finish"
                ? "registrationactive"
                : "registrationdue"
            }
          >
            {paramstep.step === "finish" ? (
              <img src={TickIcon} alt="Done" />
            ) : (
              <p>2</p>
            )}
            <h5>About</h5>
          </div>
          <hr />
          <div
            className={
              registerState === "Confirmation" || doneStage === 3
                ? "registrationactive"
                : "registrationdue"
            }
          >
            <p>3</p>
            <h5>Finish</h5>
          </div>
          {token && (
            <button
              onClick={() => {
                dispatch(logOut());
                navigte("/");
              }}
              className="logoutprofile absolute top-5 right-10 pl-5 pr-5 p-1 rounded-lg"
            >
              Logout
            </button>
          )}
        </div>
      </div>
      <div className="loginbox">
        {paramstep.step == "email" && !token ? (
          <RegisterMain
            nextStage={() => {
              navigte("/finish");
            }}
            doneStage={() => {}}
          />
        ) : (
          ""
        )}
        {paramstep.step == "about" ? (
          <RegisterAbout
            nextStage={() => {
              navigte("/register/finish");
            }}
            doneStage={() => {}}
          />
        ) : (
          ""
        )}
        {paramstep.step == "finish" ? (
          <RegisterFinish
            nextStage={() => {
              updateuser({
                ...registerstate?.user,
                phone_number: registerstate?.user.phone,
              });
              navigte("/register/finish");
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
