import React, { useEffect, useState } from "react";
import "../styles/clinicalmcq.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import { BsFillPatchCheckFill } from "react-icons/bs";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import CorrectAnswer from "../components/correctanswer";
import WrongAnswer from "../components/wronganswer";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetQuestionsQuery,
  useGetRunningTestQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import ClinicalCongrats from "../elements/clinicalcongrats";
import ClinicalTryAgain from "../elements/clinicaltryagain";
import { useSubmitQuestionMutation } from "../store/caseSimulation/CaseStudyApiSlice";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import SkippedAnser from "../components/skippedanswer";
import { useAddbookmarkMutation } from "../store/General/generalSlice";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const TimeIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/time.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
function CaseStudySkeletton() {
  return (
    <div class="space-y-4 p-4">
      <div class="animate-pulse space-y-2">
        <div class="h-8 bg-gray-200 rounded w-3/4"></div>

        <div class="h-6 bg-gray-200 rounded w-1/4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-1/6"></li>
          <li class="h-4 bg-gray-200 rounded w-1/4"></li>
          <li class="h-4 bg-gray-200 rounded w-1/3"></li>
          <li class="h-4 bg-gray-200 rounded w-1/2"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <div class="space-y-2">
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-5/6"></div>
          <div class="h-4 bg-gray-200 rounded w-4/6"></div>
        </div>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-3/4"></li>
          <li class="h-4 bg-gray-200 rounded w-2/3"></li>
          <li class="h-4 bg-gray-200 rounded w-1/2"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-5/6"></li>
          <li class="h-4 bg-gray-200 rounded w-3/4"></li>
          <li class="h-4 bg-gray-200 rounded w-2/3"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-4/5"></li>
          <li class="h-4 bg-gray-200 rounded w-3/5"></li>
          <li class="h-4 bg-gray-200 rounded w-2/5"></li>
        </ul>
      </div>
    </div>
  );
}

function QuestSkeleton() {
  return (
    <div class="space-y-4 p-4">
      <div class="animate-pulse">
        <div class="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
      </div>
      <hr class="border-gray-200" />

      <div class="animate-pulse space-y-2">
        <div class="h-5 bg-gray-200 rounded w-3/4"></div>

        <div class="space-y-4 mt-4">
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/5"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-4/5"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/6"></div>
          </div>
        </div>

        <div class="h-8 bg-gray-200 rounded w-1/4 mt-4"></div>
      </div>
    </div>
  );
}
function Question({ question, type, setcurrentindex }) {
  const [submitquestion, submitquestionState] = useSubmitQuestionMutation();

  const [answer, setanswer] = useState();
  const [explain, setexplain] = useState();
  const [submit, setsubmit] = useState();
  useEffect(() => {
    setsubmit(false);
    if (question?.status == "ANSWERED" && type == "MCQ") {
      setsubmit(true);
      setanswer(question?.answer_option);
    } else if (question?.status == "ANSWERED" && type == "EXPLAIN") {
      setsubmit(true);
      setexplain(question?.answer_explain);
    } else {
      setanswer();
      setexplain("");
    }
  }, [question]);

  return (
    <div className=" w-full flex flex-col">
      <label className="text-[14px]">{question?.question}</label>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (type == "MCQ") {
            await submitquestion({
              uid: question?.uuid,
              updateobject: {
                answer_option: answer,
                status: "ANSWERED",
              },
            });
            if (answer == question.correct_option) {
              toast.success("🔥Correct! +4");
            } else {
              toast.error("👎InCorrect! -1");
            }
          } else {
            await submitquestion({
              uid: question?.uuid,
              updateobject: {
                answer_explain: explain,
                status: "ANSWERED",
              },
            });
          }
          setcurrentindex();
          setsubmit(true);
        }}
      >
        <div className="clinicalbodyoptions">
          {type == "MCQ" &&
            question?.options?.map((rec, index) => (
              <div className="clinicalbodyoption mt-[17px]">
                <input
                  type="radio"
                  name="clinicalOption"
                  id={index}
                  value={rec}
                  checked={answer === index}
                  onChange={() => setanswer(index)}
                  disabled={question?.status == "ANSWERED" ? true : false}
                  required
                />
                <label htmlFor={index}>{rec}</label>
                {submit && index == question?.correct_option && (
                  <FaCheck className="text-green-500" />
                )}
              </div>
            ))}
        </div>
        <div className="clinicalelementbody">
          <div className="clinicalelementbodyheader headerbodybottom">
            {type == "EXPLAIN" && (
              <textarea
                required
                placeholder="Enter Your Answer..."
                value={explain}
                disabled={submit}
                onChange={(e) => setexplain(e.target.value)}
              ></textarea>
            )}{" "}
          </div>{" "}
        </div>
        {!submit && question?.status != "SKIPPED" && (
          <button type="submit" className="clinicalmcqsubmit mt-[16px]">
            {submitquestionState?.isLoading ? "Submitting..." : "Submit"}
          </button>
        )}
        {submit && type == "MCQ" && answer == question?.correct_option && (
          <CorrectAnswer explain={question?.reason} />
        )}
        {submit && type == "MCQ" && answer != question?.correct_option && (
          <WrongAnswer explain={question?.reason} />
        )}
        {type == "MCQ" && question?.status == "SKIPPED" && (
          <SkippedAnser explain={question?.reason} />
        )}
        {submit && type == "EXPLAIN" && "Answered! Please move Forward"}
      </form>
    </div>
  );
}

function Questions({ questions, type }) {
  const bookmarkNotSelectedIcon =
    "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
  const bookmarkIcon =
    "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
  console.log(questions);
  const [submitquestion, submitquestionState] = useSubmitQuestionMutation();

  const [currentindex, setcurrentindex] = useState(0);
  const [currentquestion, setcurrentquestion] = useState(
    questions?.length > 0 ? questions[currentindex] : null
  );

  const [bookmark, setbookmark] = useState(false);
  useEffect(() => {
    setcurrentquestion(questions?.length > 0 ? questions[currentindex] : null);
  }, [currentindex, questions]);

  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  console.log(currentquestion);
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-between">
        <h1 className="text-[20px]">Question {currentindex + 1}</h1>
        <div className="flex flex-row gap-3">
          <div
            className="elementselector bookmarkselector"
            onClick={async () => {
              await addBookmark({
                page: "casestudyquestion",
                id: currentquestion?.uuid,
              });
              setbookmark(!bookmark);
            }}
          >
            {bookmark ? (
              <img src={bookmarkIcon} alt="Bookmark" />
            ) : (
              <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
            )}
          </div>

          {currentindex > 0 && (
            <button
              onClick={() => setcurrentindex((prev) => prev - 1)}
              className="elementselector"
            >
              Pervious
            </button>
          )}

          {currentindex != questions.length - 1 && (
            <button
              onClick={() => setcurrentindex((prev) => prev + 1)}
              className="elementselector"
            >
              Next
            </button>
          )}
          {}
          {currentquestion?.status == "PENDING" && (
            <button
              onClick={() => {
                submitquestion({
                  uid: currentquestion?.uuid,
                  updateobject: {
                    status: "SKIPPED",
                  },
                });
              }}
              className="elementselector"
            >
              Skip
            </button>
          )}
        </div>
      </div>
      <hr />
      <Question
        question={currentquestion}
        setcurrentindex={() => setcurrentindex((prev) => prev + 1)}
        type={type}
      />
    </div>
  );
}
function QuickDDX({ isVisible, onClose, data, treatmentplan }) {
  const [quickquery, setquickquery] = useState();
  const navigate = useNavigate();
  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content max-h-[80vh]  overflow-y-scroll"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full flex items-center justify-center flex-col  ">
          <BsFillPatchCheckFill size={80} className="text-green-700" />
          <h1 className="font-semibold text-[30px]">Congratulations</h1>
          <h2>
            You have successfully completed this case. Here is your case
            evaluation report
          </h2>
        </div>
        <div className="flex flex-col gap-5">
          {data
            ?.filter((rec) => rec?.evaluation)
            .map((rec, index) => (
              <div className="flex flex-col">
                <div className="w-full flex flex-row items-center justify-between">
                  <h2 className="">
                    Case Evaluation Report for Question {index + 1}
                  </h2>
                  <div className="clinicalheaderrow">
                    <div className="elementselector">
                      Total Score: {rec?.score}/10
                    </div>
                  </div>
                </div>
                <div className="quickddxbody">
                  {rec?.evaluation?.length > 0 &&
                    rec?.evaluation?.map((evalrec) => (
                      <div className="flex flex-wrap gap-3 items-center">
                        <div className="text-[1em] font-semibold">
                          {evalrec.factor} {evalrec?.score}/2.5:
                        </div>{" "}
                        <p>{evalrec.evaluation}</p>
                      </div>
                    ))}
                </div>
              </div>
            ))}

          <div className="flex flex-col">
            <div className="w-full flex flex-row items-center justify-between">
              <h2 className="">Treatment Plan</h2>
            </div>
            <div
              className="quickddxbody"
              dangerouslySetInnerHTML={{ __html: treatmentplan }}
            ></div>
          </div>
        </div>
        <div className="contentright">
          <button
            className="elementselectorfilled"
            onClick={() => (window.location = "/clinical")}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default function ClinicalMCQs() {
  const [bookmark, setbookmark] = useState(false);
  const [received, setreceived] = useState(false);
  const navigate = useNavigate();
  const [managepatient, setmanagepatient] = useState();
  const [showeval, setshoweval] = useState(false);
  const [showtimeup, setshowtimeup] = useState(false);

  const { uid } = useParams();
  const {
    data: testdata,
    isLoading: testloading,
    isError: tesiserror,
    error: testerror,
  } = useGetRunningTestQuery(uid);
  const {
    data: apidata,
    isLoading: questionloading,
    isError: questioniserror,
    error: questionerror,
  } = useGetQuestionsQuery(uid, {
    pollingInterval: received ? 0 : 5000,
  });

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [questiondata, setquestiondata] = useState([]);
  useEffect(() => {
    const createdTime = new Date(testdata?.data?.created_at).getTime();
    const durationInMillis = testdata?.data?.duration * 60 * 1000;
    const endTime = createdTime + durationInMillis;

    const checkTime = () => {
      const currentTime = Date.now();

      const remaining = endTime - currentTime;
      if (remaining <= 0) {
        setshowtimeup(true);
      } else {
        setTimeRemaining(Math.floor(remaining / 1000));
      }
    };

    const interval = setInterval(checkTime, 1000);

    checkTime();

    return () => clearInterval(interval);
  }, [testdata]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  useEffect(() => {
    if (testerror) {
      navigate("/clinical");
    }
  }, [tesiserror]);

  useEffect(() => {
    if (apidata && apidata["questions"]?.length > 0) {
      setreceived(true);
      setquestiondata(apidata["questions"]);
    }
    if (
      received &&
      apidata &&
      apidata["questions"]?.filter(
        (rec) => rec.status === "ANSWERED" || rec.status === "SKIPPED"
      ).length == apidata["questions"]?.length
    ) {
      setshoweval(true);
      setquestiondata(apidata["questions"]);
    }
  }, [apidata]);

  const [
    addBookmark,
    { isLoading: bookmarkLoading, error: bookmarkError, isSuccess: bo },
  ] = useAddbookmarkMutation();

  useEffect(() => {
    if (bookmarkError) {
      toast.error("Error adding to bookmark");
    } else if (bo) {
      toast.info("Added to bookmark");
    }
  }, [bookmarkError, bo]);

  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      {testdata?.data?.question_type != "MCQ" && (
        <QuickDDX
          isVisible={showeval}
          onClose={() => setshoweval(false)}
          data={questiondata}
          treatmentplan={apidata?.treatmentplan}
        />
      )}

      {testdata?.data?.question_type == "MCQ" && (
        <ClinicalCongrats
          qid={uid}
          isVisible={showeval}
          treatment={apidata?.treatmentplan}
          onClose={() => setshoweval(false)}
        />
      )}
      {testdata?.data?.question_type == "MCQ" && (
        <ClinicalTryAgain
          qid={uid}
          isVisible={showtimeup}
          treatment={apidata?.treatmentplan}
          onClose={() => setshoweval(false)}
        />
      )}
      <HomemainHeaderMobile
        onToggle={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        header="Clinical Case"
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clincalbody">
          <div className="clinicalprogress">
            <h5>Case Progress</h5>
            <div className="clinicalprogressestimate">
              {questiondata?.length > 0 && (
                <h4>
                  {(
                    (questiondata?.filter(
                      (rec) =>
                        rec.status === "ANSWERED" || rec.status === "SKIPPED"
                    ).length /
                      questiondata?.length) *
                    100
                  ).toFixed(2)}
                  % to Complete
                </h4>
              )}
              {timeRemaining && (
                <div className="clinicalprogressestimatetime">
                  <img src={TimeIcon} alt="Time" />
                  <p>{timeRemaining} seconds</p>
                </div>
              )}
            </div>
            <div className="clinicalprogresspercentage">
              <div
                style={{
                  width: `${
                    (questiondata?.filter(
                      (rec) =>
                        rec.status === "ANSWERED" || rec.status === "SKIPPED"
                    ).length /
                      questiondata?.length) *
                    100
                  }%`,
                }}
                className="clinicalprogressbar"
              ></div>
            </div>
          </div>

          <div className="clinicalbodyelements">
            <div className="clinicalelementheader">
              <div className="clinicalimageheader">
                <h4>Case:{testdata?.count + 1}</h4>
              </div>
              <div className="clinicalheaderrow">
                <div
                  className="elementselector bookmarkselector"
                  onClick={async () => {
                    await addBookmark({
                      page: "bookmarkcasestudy",
                      id: testdata?.data?.case_studies?.[0]?.uuid,
                    });
                    setbookmark(!bookmark);
                  }}
                >
                  {bookmark ? (
                    <img src={bookmarkIcon} alt="Bookmark" />
                  ) : (
                    <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                  )}
                </div>
              </div>
            </div>
            <hr />
            {testloading ? (
              <CaseStudySkeletton />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: testdata?.data?.case_studies[0]?.content,
                }}
              />
            )}
            {!managepatient && questiondata?.length > 0 && (
              <button
                onClick={() => setmanagepatient(true)}
                className={`w-full ${
                  !managepatient ? "bg-[#0090FF]" : "bg-gray-300 animate-pulse"
                } text-white text-center p-[6px] rounded-lg mt-4`}
              >
                Manage the Patient
              </button>
            )}
          </div>
          {managepatient && (
            <div className="clinicalbodyelements">
              {questiondata?.length > 0 ? (
                <Questions
                  questions={questiondata}
                  type={testdata?.data?.question_type}
                />
              ) : (
                <QuestSkeleton />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
