import React from "react";
import "../styles/portfolio.css";
import PortfolioNavbar from "./elements/portfolionavbar";
const Logo = "https://neuro-gen.s3.amazonaws.com/images/icons/logo.svg";
const HomeScreen =
  "https://neuro-gen.s3.amazonaws.com/images/icons/homescreen.svg";
const explore1image =
  "https://neuro-gen.s3.amazonaws.com/images/portfolio/home/img.svg";
const explore2image =
  "https://neuro-gen.s3.amazonaws.com/images/portfolio/home/explore2.svg";
const explore3image =
  "https://neuro-gen.s3.amazonaws.com/images/portfolio/home/explore3.svg";

const explore4image =
  "https://neuro-gen.s3.amazonaws.com/images/portfolio/home/explore4.svg";

export default function Home() {
  return (
    <div className="portfolio">
      <div className="homenotice">
        <p>Want to create history,</p>
        <p>
          <u>Join Neuroverse Healthcare</u>
        </p>
      </div>
      <div className="portfoliohome">
        <PortfolioNavbar colortheme="blue" />
        <div className="portfoliohomebody">
          <h4>Welcome to</h4>
          <h2>The Future of Healthcare</h2>
          <img src={Logo} alt="Logo" />
          <h1>
            YOUR ULTIMATE <strong>MED</strong>-GUIDE
          </h1>
          <p>
            NeuroGEN isn’t just a tool—it’s the future of medicine. A future
            where doctors and AI work in perfect harmony to deliver
            unprecedented levels of care, accuracy, and innovation. Are you
            ready to join the revolution?
          </p>
          <button>Start Now</button>

          <img src={HomeScreen} alt="Home Screen" className="homescreenview" />
        </div>
      </div>

      <div className="portfolioexplore">
        <h2>Explore the Core Features of NeuroGEN</h2>
        <p>
          Collaborate with a seamless blend of AI and medical expertise. Whether
          you’re diagnosing, researching, or learning, NeuroLAB AI empowers you
          to reach new heights with speed and accuracy.
        </p>
        <div className="explorerow">
          <div className="exploreimage opacity-60">
            <img src={explore1image} alt="Explore 1" />
            <div className="mb-20"></div>
          </div>
          <div className="exploreimage opacity-60">
            <img src={explore2image} alt="Explore 2" />
          </div>
          <div className="exploreimage ">
            <img src={explore3image} alt="Explore 3" />
            <p>Query System</p>
          </div>
          <div className="exploreimage opacity-60">
            <img src={explore4image} alt="Explore 4" />
          </div>
          <div className="exploreimage opacity-60">
            <img src={explore1image} alt="Explore 5" />
            <div className="mb-20"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
