import React, { useState } from "react";
import "../styles/quickddx.css";
const crossicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crossicon.svg";

export default function SymptomChecker({ isVisible, onClose, finalstage }) {
  const initialFields = 4; // Number of initial fields
  const [inputFields, setInputFields] = useState(Array(initialFields).fill(""));

  const addInputField = (e) => {
    e.preventDefault();
    if (inputFields.length < 8) {
      setInputFields([...inputFields, ""]);
    }
  };

  const handleInputChange = (index, value) => {
    const newFields = [...inputFields];
    newFields[index] = value;
    setInputFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = inputFields.filter((_, i) => i !== index);
    setInputFields(newFields);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="symptomchecker-popup"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="symptomchecker">
          <div className="researchelementleft">
            <form>
              {inputFields.map((field, index) => (
                <div key={index} className="input-with-cross">
                  <input
                    type="text"
                    placeholder="ex.: Lactic Acidosis"
                    value={field}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />

                  {index >= initialFields && (
                    <img
                      src={crossicon}
                      alt="Remove"
                      className="cross-icon"
                      onClick={() => handleRemoveField(index)}
                    />
                  )}
                </div>
              ))}

              {inputFields.length < 8 && (
                <button
                  className="elementselectorlightblue addmorebutton w-full"
                  onClick={addInputField}
                >
                  Add More
                </button>
              )}

              <button type="submit" className="generatebutton">
                Search Diseases
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
