import React, { useEffect, useState, useRef } from "react";
import SideBar from "../elements/sidebar";
import "../styles/aisearch.css";
import { useGetsolveMyQueryQuery } from "../store/General/generalSlice";
import { getAchievementSrc, GetBadgeName } from "../components/helpers";
import handleDownload from "../components/helpers";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import {
  useSolveMyQueryMutation,
  useBookmarkQueryMutation,
} from "../store/General/generalSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiLoaderCircle } from "react-icons/bi";
import config from "../config/APIEndpoints";
import HomemainHeaderMobile from "../components/homemainheadermobile";
import LoadingScreen from "../elements/loading";
import { useGetProfileQuery } from "../store/profile/UserApiSlice";
import SharePopup from "../elements/share";
import ScoreBoardBanner from "../components/scoreboardbanner";
const UserIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profilevectro.svg";
const GripLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/griplinessidebar.svg";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const DotsIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/dots.svg";
const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const bookmarkNotSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmarknotselected.svg";
const bookmarkIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/bookmark.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/inputcamera.svg";
const micIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/inputmic.svg";
const ProfileIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profile.svg";
const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
export default function AiSearch() {
  const { data: profileData } = useGetProfileQuery();
  const [isShareOpen, setisShareOpen] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const { uid } = useParams();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };
  const { data, isLoading } = useGetsolveMyQueryQuery(uid);
  const { data: score, isLoading: scoreloading } = useGetUserScoreQuery(uid);
  const [query, setquery] = useState();
  const [getquery, getquerystate] = useSolveMyQueryMutation();
  const [bookmarkresult, bookmarkresultstate] = useBookmarkQueryMutation();
  const downref = useRef();
  const handleSearch = async () => {
    if (!query) {
      toast.warning("please enter Query!");
      return;
    }
    const formData = new FormData();
    formData.append("query", query);
    if (file) formData.append("file_uploads", file);

    const resp = await getquery(formData);
    if (resp?.error) {
      const utcDate = resp?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(`${resp?.error?.data?.error} please try after ${istDate}!`);
    } else {
      navigate(`/aisearch/${resp.data.uuid}`);
    }
  };
  useEffect(() => {
    setBookmark(data?.data?.bookmark);
    setquery(data?.data?.query);
  }, [data]);
  const toggleShare = () => {
    setisShareOpen(!isShareOpen);
  };
  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const toggleScoreboard = () => {
    setIsScoreboardVisible(!isScoreboardVisible);
  };
  return (
    <div className="homemain">
      <SideBar
        selected="Dashboard"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      {isShareOpen ? (
        <SharePopup
          onClose={toggleShare}
          isVisible={isShareOpen}
          inputvalue={window.location.href}
        />
      ) : null}

      <ScoreBoardBanner
        isVisible={isScoreboardVisible}
        onClose={toggleScoreboard}
      />

      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader mobilevisibleonly dashboardheadermobile">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
              <div
                className="closesidebarbuttonmobileheader"
                onClick={toggleSidebar}
              >
                <img src={GripLine} alt="Arrow" />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Hello Dr.{score?.name.split(" ")[0]},</h3>
              <p>Your patient needs you, Let's rush to the rescue</p>
            </div>
          </div>
          <div className="homemainright" onMouseEnter={toggleScoreboard}>
            {data?.score > 0 ? (
              <button>
                <div className="homemainrightbuttoncontent">
                  <img
                    src={
                      profileData?.profile?.profile_pic
                        ? `${config.getEndpoint()}${data?.profile?.profile_pic}`
                        : ProfileIcon
                    }
                    alt="User"
                    className="dashboardheadericon mobileonly"
                    onClick={() => navigate("/profile")}
                  />
                  <span>
                    <b>{GetBadgeName(data?.score)}</b> Badge
                  </span>
                </div>
                <div className="headerstreaks">
                  <img src={fireicon} alt="Fire" />
                  <p>{data?.score || 0}</p>
                </div>
              </button>
            ) : (
              <button>
                <h6>Let’s get started and earn badges</h6>
              </button>
            )}
            <button>
              <img src={reloadicon} alt="Reload" />
            </button>
            <div className="usericonscore">
              <img
                src={
                  profileData?.profile?.profile_pic
                    ? `${config.getEndpoint()}${data?.profile?.profile_pic}`
                    : ProfileIcon
                }
                alt="User"
                className="dashboardheadericon mobileonly"
                onClick={() => navigate("/profile")}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
              />
              <div className="userscoremobile">
                <p>{data?.score || 0}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboardbody">
          <div className="dashboardheader aisearchheader">
            <h2>What's on your mind?</h2>
            <p>
              Example: How do Proteosome Inhibitors function in the treatment of
              Multiple Myeloma?
            </p>
            <div
              className={`dashboardinput ${
                query ? "border-blue-500 shadow-blue" : "border-gray-300"
              }`}
              style={{
                borderWidth: "2px",
                borderRadius: "8px",
                padding: "10px",
                boxShadow: query
                  ? "0px 4px 10px 4px rgba(0, 144, 255, 0.13);"
                  : "none",
              }}
            >
              <input
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                disabled={getquerystate?.isLoading}
                onChange={(e) => setquery(e.target.value)}
                defaultValue={data?.data?.query}
                placeholder="Enter your question here"
              />
              {getquerystate?.isLoading ? (
                <BiLoaderCircle className="animate-spin" />
              ) : (
                <div className="flex flex-row gap-4 inputimagesrow">
                  <input
                    type="file"
                    id="file"
                    accept=".pdf, image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    {file ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Uploaded File"
                        width="50"
                      />
                    ) : (
                      <img src={UploadIcon} alt="Upload" />
                    )}
                  </label>
                  <img src={micIcon} alt="Mic" />
                </div>
              )}
            </div>
          </div>
          <div className="dashboardbodyelements">
            <div className="flex flex-wrap w-full"></div>
            <div className="aisearchheaderbody">
              <div className="aisearchheaderbodyleft">
                <h2>Results</h2>
              </div>

              <div className="aisearchbodyright">
                <div
                  className="elementselector bookmarkselector"
                  onClick={() => {
                    setBookmark(!bookmark);
                    bookmarkresult(uid);
                  }}
                >
                  {bookmark ? (
                    <img src={bookmarkIcon} alt="Bookmark" />
                  ) : (
                    <img src={bookmarkNotSelectedIcon} alt="Bookmark" />
                  )}
                </div>
                <button
                  onClick={() => handleDownload(downref)}
                  className="elementselector"
                >
                  Download
                </button>
                <div className="elementselector" onClick={toggleShare}>
                  Share
                </div>
              </div>
            </div>
            <hr />
            {data?.data?.files?.map((rec) => (
              <img
                style={{ maxHeight: "10rem", borderRadius: "1rem" }}
                src={`${config.getEndpoint()}${rec?.file}`}
              />
            ))}
            <div
              className="dashboardbodycontent"
              ref={downref}
              dangerouslySetInnerHTML={{
                __html: data?.data?.result,
              }}
            ></div>

            <div className="dashboardresponsefeedback">
              <p>Do you like the Response?</p>
              <img src={ThumbsUp} alt="Thumbs Up" />
              <img src={ThumbsDown} alt="Thumbs Down" />
            </div>
          </div>
          <p className="usagewarning">
            **This is intended for educational purpose only!
          </p>
        </div>
        <div className="historyelement bg-white mt-5 ">
          <div className="historyelementheader">
            <div className="historyelementleft">
              <h2>Previous Search</h2>
            </div>
            <div className="historyelementright">
              <button className="elementselectorfilled">View all</button>
            </div>
          </div>
          <hr />

          <div className="searchresulthistory">
            <p>
              Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
              consectetur tortor phasellus mauris justo
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
              consectetur tortor phasellus mauris justo
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
              consectetur tortor phasellus mauris justo
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
